.typewriter h2,
body.active {
    overflow: hidden
}

*,
ul {
    margin: 0;
    padding: 0
}

.bannerTab input:focus,
.bannerTab input:hover,
.contact-popup input:focus,
.contact-popup input:hover,
.contact-popup textarea:focus,
.contact-popup textarea:hover,
.newsletterForm input:focus,
.newsletterForm input:hover,
.slick-slide,
.user-login input:focus,
.user-login input:hover {
    outline: 0
}

.home-banner,
.home-banner figure,
.relative,
body {
    position: relative;
}

/* @media (min-width: 750px) and (max-width:1250px) {
    .home-banner{
        position: relative;
        top: 5rem;
    }
} */

.container-lg,
.relative,
body,
figure img {
    width: 100%
}

body,
html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Lato, sans-serif
}

.about-content .wrapper,
.about-service h3,
.about-service li,
.bannerTab button,
.contact-popup .head,
.home-banner .banner-content,
.home-service li a,
.inner-heading,
footer .btns_member,
footer .copyright p {
    text-align: center
}

@font-face {
    font-family: Effra-Regular;
    font-display: auto;
    font-weight: 400;
    src: url(../assets/fonts/Effra_Std_Rg.ttf) format("TrueType")
}

@font-face {
    font-family: Effra-Light;
    font-display: auto;
    font-weight: 300;
    src: url(../assets/fonts/Effra_Std_Lt.ttf) format("TrueType")
}

@font-face {
    font-family: Effra-Bold;
    font-display: auto;
    font-weight: 700;
    src: url(../assets/fonts/Effra_Std_Bd.ttf) format("TrueType")
}

@font-face {
    font-family: Effra-Medium;
    font-display: auto;
    font-weight: 500;
    src: url(../assets/fonts/Effra-Medium.ttf) format("TrueType")
}

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.luxy-el,
.slick-slider .slick-list,
.slick-slider .slick-track,
.swiper-container,
.swiper-slide,
.swiper-wrapper,
[data-aos],
[data-jarallax-element] {
    will-change: transform
}

html {
    scroll-behavior: smooth;
    overflow-x: hidden
}

li {
    list-style: none
}

a {
    text-decoration: none
}

img {
    max-width: 100%;
    vertical-align: top
}

.color-white * {
    color: #fff
}

.container-lg {
    max-width: 1800px;
    margin: 0 auto
}

.container {
    max-width: 1300px;
    margin: 0 auto
}

.container-sl {
    max-width: 1520px;
    margin: 0 auto
}

.container-sm {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 20px
}

figure,
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0
}

p {
    font-weight: 600
}

body,
h3 {
    font-weight: 700
}

.bg-control {
    background-size: cover;
    background-repeat: no-repeat
}

::-webkit-scrollbar {
    width: 12px
}

::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, .4)
}

::-webkit-scrollbar-thumb {
    background: #fff
}

a,
span {
    display: inline-block
}

body {
    -webkit-text-size-adjust: none;
    font-size: 18px;
    color: #000
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #ff8c00
}

h1 {
    font-size: 41px
}

h2 {
    font-size: 28px
}

h3 {
    font-size: 24px
}

h4 {
    font-size: 21px
}

footer h6,
h5 {
    font-size: 19px
}

h6,
p {
    font-size: 18px
}

p {
    line-height: 1.55em
}

.btn,
a,
li {
    color: #000
}

.shp0 {
    fill: url(#grd1)
}

.btn {
    font-family: Poppins, sans-serif;
    font-weight: 600
}

@media (max-width:1440px) {
    .container {
        max-width: 1180px
    }
}

@media (max-width:1280px) {
    .container {
        max-width: 1000px
    }
}

@media (max-width:1024px) {
    .container {
        max-width: 100%
    }
}

@media (max-width:767px) {
    .container {
        padding: 0 18px
    }
}

@media (max-width:1200px) {
    .container-sm {
        max-width: 100%;
        padding: 0 40px
    }
}

@media (max-width:992px) {
    .container-sm {
        padding: 0 20px
    }
}

@media (max-width:767px) {
    .container-sm {
        padding: 0 15px
    }
}

@media (max-width:1800px) {
    .container-lg {
        max-width: 1530px
    }
}

@media (max-width:1600px) {
    .container-lg {
        max-width: 1340px
    }
}

@media (max-width:1440px) {
    .container-lg {
        max-width: 1200px
    }
}

@media (max-width:1280px) {
    .container-lg {
        max-width: 1100px
    }
}

@media (max-width:1200px) {
    .container-lg {
        max-width: 1000px
    }
}

@media (max-width:1024px) {
    .container-lg {
        max-width: 90%
    }
}

@media (max-width:768px) {
    .container-lg {
        padding: 0 20px;
        max-width: 100%
    }
}

@media (max-width:1600px) {
    .container-sl {
        max-width: 1300px
    }
}

@media (max-width:1440px) {
    .container-sl {
        max-width: 1200px
    }
}

@media (max-width:1366px) {
    .container-sl {
        max-width: 1160px
    }
}

@media (max-width:1280px) {
    .container-sl {
        max-width: 1060px
    }
}

@media (max-width:1140px) {
    .container-sl {
        max-width: 900px
    }
}

@media (max-width:992px) {
    .container-sl {
        max-width: 90%
    }
}

@media (max-width:768px) {
    .container-sl {
        max-width: 100%;
        padding: 0 40px
    }
}

.mb-0 {
    margin-bottom: 0 !important
}

.mt-0 {
    margin-top: 0 !important
}

.pt-0 {
    padding-top: 0 !important
}

.pd72 {
    padding: 0 72px
}

.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.navbar_position {
    position: sticky;
    z-index: 10;
}

.flex.nowrap {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
}

.flex.column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap
}

.flex.space-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.flex.space-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.flex.space-around {
    -ms-flex-pack: distribute;
    justify-content: space-around
}

.flex.space-evenly {
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly
}

.flex.space-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.flex.align-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.flex.align-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important
}

.flex.align-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.bannerTab .form_group:first-of-type {
    width: 75%;
    padding-right: 10px
}

@media (max-width:576px) {
    .bannerTab form {
        flex-wrap: wrap
    }

    .bannerTab .form_group:first-of-type {
        width: 100%;
        padding-right: 0;
        margin-bottom: 10px
    }
}

.bannerTab .form_group:nth-of-type(2) {
    width: 23%
}

@media (max-width:992px) {
    .bannerTab .form_group:nth-of-type(2) {
        width: 25%
    }
}

@media (max-width:576px) {
    .bannerTab .form_group:nth-of-type(2) {
        width: 100%
    }
}

.bannerTab input {
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    line-height: 18px;
    color: #1d1d23;
    font-family: Lato, sans-serif;
    padding: 15px
}

.bannerTab button {
    background-color: #ff8c00;
    color: #fff;
    transition: all .25s ease;
    font-size: 18px;
    line-height: 1.25em;
    border-radius: 5px;
    padding: 12px;
    width: 100%;
    border: 1px solid #ff8c00;
    cursor: pointer;
    font-family: Effra-Medium;
    font-weight: 500
}

.contact-popup button,
.contact-popup input,
.contact-popup label,
.contact-popup textarea,
.newsletterForm .sub-btns {
    font-family: Lato, sans-serif
}

.bannerTab button:hover {
    background-color: #006994;
    border: 1px solid #006994
}

.bannerTab input::placeholder {
    color: #1d1d23;
    opacity: 1
}

.bannerTab input:-ms-input-placeholder {
    color: #1d1d23
}

.bannerTab input::-ms-input-placeholder {
    color: #1d1d23
}

.newsletterForm .form_group:first-of-type {
    width: calc(100% - 160px)
}

@media (max-width:767px) {
    .newsletterForm {
        flex-wrap: wrap
    }

    .newsletterForm .form_group {
        margin-bottom: 10px
    }

    .newsletterForm .form_group:first-of-type {
        width: 100%
    }
}

.newsletterForm .form_group:nth-of-type(2) {
    width: 160px
}

.newsletterForm input {
    width: 100%;
    background-color: #fff;
    border: none;
    border-radius: 5px 0 0 5px;
    font-size: 16px;
    line-height: 1.25em;
    color: #999;
    padding: 20px
}

@media (max-width:767px) {
    .newsletterForm .form_group:nth-of-type(2) {
        width: 100%
    }

    .newsletterForm input {
        border-radius: 5px
    }
}

.newsletterForm .sub-btns {
    background-color: #ff8c00;
    color: #fff;
    transition: all .25s ease;
    font-size: 15px;
    line-height: 1.25em;
    border-radius: 0 5px 5px 0;
    padding: 20px 40px;
    border: 1px solid #ff8c00;
    cursor: pointer;
    font-weight: 500
}

@media (max-width:767px) {
    .newsletterForm .sub-btns {
        width: 100%;
        border-radius: 5px
    }
}

.newsletterForm .sub-btns:hover {
    background-color: #006994;
    border: 1px solid #006994
}

.contact-popup form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between
}

.contact-popup .form_group {
    width: 100%;
    margin-bottom: 20px
}

.contact-popup .form_group.half {
    width: 47.6%
}

.contact-popup .form_group:last-of-type {
    margin-bottom: 0
}

.contact-popup input,
.contact-popup textarea {
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    color: #999;
    padding: 9px 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.7em;
    letter-spacing: .1px;
    border: 1px solid #818a91
}

.contact-popup button,
.user-login button {
    font-size: 16px;
    background-color: #ff8c00;
    transition: all .25s ease;
    cursor: pointer
}

.contact-popup textarea {
    resize: none;
    height: 122px
}

.contact-popup #subscribe {
    width: auto
}

.contact-popup label {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.7em;
    letter-spacing: .1px;
    color: #4d5471
}

.contact-popup button {
    color: #fff;
    line-height: 1.25em;
    border-radius: 4px;
    padding: 13px 30px;
    border: 1px solid #ff8c00;
    font-weight: 500;
    width: 100%;
    margin-top: 10px
}

.user-login .col label,
.user-login input,
.user-login label {
    font-weight: 400;
    font-family: Roboto, sans-serif
}

.contact-popup button:hover {
    background-color: #006994;
    border: 1px solid #006994
}

.contact-popup input::placeholder {
    color: #999;
    opacity: 1
}

.contact-popup input:-ms-input-placeholder {
    color: #999
}

.contact-popup input::-ms-input-placeholder {
    color: #999
}

.contact-popup textarea::placeholder {
    color: #999;
    opacity: 1
}

.contact-popup textarea:-ms-input-placeholder {
    color: #999
}

.contact-popup textarea::-ms-input-placeholder {
    color: #999
}

.user-login {
    width: 100%;
    margin-top: 40px
}

.user-login .form_group {
    width: 100%;
    margin-bottom: 20px
}

.user-login .form_group.flex {
    justify-content: space-between;
    align-items: center
}

.user-login .form_group:last-of-type {
    margin-bottom: 0
}

.user-login sup {
    color: red;
    line-height: 1em
}

.user-login label {
    width: 100%;
    margin-bottom: 6px;
    font-size: 16px;
    line-height: 1.7em;
    letter-spacing: .1px;
    color: #4d5471;
    display: block
}

.user-login input {
    width: 100%;
    border: 1px solid #efefef;
    padding: 12px 15px;
    font-size: 16px;
    line-height: 1.4em;
    color: #666;
    border-radius: 4px
}

.user-login .col {
    display: flex;
    align-items: center
}

.user-login .col label {
    width: auto;
    font-size: 15px;
    line-height: 1.2em;
    letter-spacing: .1px;
    color: #4d5471;
    display: block;
    margin-bottom: 0;
    margin-left: 7px
}

.home-banner .banner-content h1,
.home-banner .tab li,
.user-login button {
    color: #fff;
    font-family: Effra-Bold
}

.user-login .col input {
    width: 16px;
    height: 16px
}

.user-login button {
    width: 100%;
    border-radius: 5px;
    font-weight: 700;
    line-height: 1.4em;
    letter-spacing: 0;
    border: 1px solid transparent;
    padding: 12px 20px
}

.user-login button:hover {
    background-color: #006994
}

.home-banner {
    width: 100%;
    height: 100vh
}

.home-banner .home-slider {
    width: 100%;
    height: 100%
}

.home-banner .slick-list,
.home-banner .slick-slide,
.home-banner .slick-track,
.home-banner figure {
    height: 100%
}

.home-banner figure::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(1, 1, 1, .26)
}

.home-banner img {
    height: 100%;
    object-fit: cover
}

.home-banner .banner-content {
    width: 750px;
    position: absolute;
    left: 50%;
    top: 50%;
    background: 0 0;
    transform: translate(-50%, -50%)
}

@media (max-width:767px) {
    .home-banner .banner-content {
        width: 100%;
        padding: 0 15px
    }
}

.home-banner .banner-content h1 {
    font-weight: 700;
    line-height: 1.2em;
    display: inherit;
    margin: 0 0 1.25em
}

.home-banner .tab {
    display: flex;
    width: 500px;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, .26);
    justify-content: center;
    padding: 10px;
    border-radius: 5px
}

@media (max-width:576px) {
    .home-banner .banner-content h1 {
        font-size: 30px
    }

    .home-banner .tab {
        width: 100%
    }
}

.home-banner .tab li {
    cursor: pointer;
    padding: 12px 30px;
    font-size: 16px;
    line-height: 1.25em;
    font-weight: 600
}

@media (max-width:576px) {
    .home-banner .tab li {
        padding: 10px 18px;
        font-size: 14px
    }
}

.home-banner .tab li.active {
    color: #3a3a3a;
    background-color: #fff;
    border-radius: 5px
}

.home-banner .tab-wrapper {
    border-radius: 7px;
    margin-top: 20px;
    background-color: #fff;
    padding: 20px
}

.tab-content {
    display: block;
}

.home-banner .tab-wrapper .tab-content {
    display: none
}

.home-banner .tab-wrapper h2 {
    line-height: 1.7em;
    font-size: 28px;
    font-weight: 400;
    font-family: Roboto, sans-serif;
    color: #303030
}

.home-banner .downBtn {
    position: absolute;
    left: 50%;
    bottom: 10px;
    transform: translateX(-50%)
}

.home-banner .downBtn a {
    width: 60px;
    height: 60px
}

.innerBanner {
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 512px
}

.loader,
footer .social-icons {
    justify-content: center
}

.loader {
    background-color: #fff;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    z-index: 9999;
    position: fixed
}

.heading-line,
header {
    justify-content: space-between
}

.typewriter {
    width: 24%
}

.typewriter h2 {
    color: #ff8c00;
    font-size: 40px;
    font-family: Effra-Bold;
    font-family: monospace;
    border-right: .15em solid orange;
    white-space: nowrap;
    margin: 0 auto;
    letter-spacing: .15em;
    animation: typing 3.5s steps(30, end), blink-caret .5s step-end infinite
}

@keyframes typing {
    from {
        width: 0
    }

    to {
        width: 100%
    }
}

.heading-line h3::before,
.inner-heading h1:before {
    width: 80px;
    top: 0;
    content: ""
}

@keyframes blink-caret {

    from,
    to {
        border-color: transparent
    }

    50% {
        border-color: orange
    }
}

.heading-line {
    display: flex
}

.heading-line h3,
.inner-heading h1 {
    display: inline-block;
    position: relative
}

@media (max-width:767px) {
    .heading-line {
        flex-direction: column;
        margin-bottom: 20px
    }
}

.heading-line h3 {
    color: #303030;
    font-family: Effra-Regular;
    font-weight: 400;
    line-height: 1.25em;
    margin-bottom: 30px;
    padding-top: 17px
}

/* .heading-line h3::before {
    position: absolute;
    left: 0;
    height: 3px;
    background-color: #ff8c00
} */

.heading-line a {
    font-family: Lato, sans-serif;
    font-size: 15px;
    line-height: 1.5em;
    font-weight: 500;
    color: #fff;
    background-color: #ff8c00;
    transition: all .25s ease;
    border-radius: 3px;
    padding: 12px 24px;
    align-self: flex-start;
    margin-top: 10px
}

@media (max-width:767px) {
    .heading-line h3 {
        margin-bottom: 8px
    }

    .heading-line a {
        margin: 10px 0 10px auto
    }
}

.heading-line a:hover {
    background-color: #006994
}

.inner-heading h1 {
    font-size: 48px;
    font-weight: 600;
    font-family: Effra-Medium;
    color: #fff;
    line-height: 1.2em;
    padding-top: 13px;
    margin-bottom: 18px
}

.inner-heading h1:before {
    position: absolute;
    left: 50%;
    height: 3px;
    /* background-color: #ff8c00; */
    transform: translateX(-50%)
}

.inner-heading h3 {
    color: #fff;
    font-family: Effra-Regular;
    letter-spacing: .1px;
    font-weight: 400;
    line-height: 1.25em
}

header {
    background-color: #fff;
    z-index: 99;
    position: static;
    left: 0;
    top: 0;
    width: 100%;
    padding: 13px 20px 13px 23px;
    align-items: center
}

header.home {
    background-color: transparent;
    position: absolute
}

header.home figure .blue-logo {
    display: none
}

header figure .blue-logo,
header.home figure .white-logo {
    display: block
}

header.home li a {
    color: #fff
}

header figure {
    width: 187px;
    height: 31px
}

@media (max-width:576px) {
    header {
        padding: 20px 15px
    }

    header figure {
        width: 130px;
        height: 28px
    }
}

header figure .white-logo {
    display: none
}

header li {
    margin: 0 15px
}

header li a {
    color: #303030;
    font-family: Lato, sans-serif;
    font-weight: 700;
    font-size: 17px;
    line-height: 1.4em
}


@media (max-width:992px) {
    header {
        justify-content: unset
    }

    header figure {
        order: -1
    }

    header li {
        margin: 0;
        padding: 10px 20px
    }

    header li a {
        color: #494c4f !important
    }
}

header nav {
    position: relative
}

@media (max-width:576px) {


    footer li i,
    footer li:hover a {
        color: #ff8c00
    }

    footer li a,
    footer li:hover i {
        color: #4d5471
    }
}

@media (max-width:992px) {


    header .menus {
        display: none;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, .25);
        min-width: 290px;
        border: 1px solid #ddd;
        position: absolute;
        background-color: #fff;
        z-index: 99;
        right: -15px
    }

    header .menus ul {
        flex-direction: column
    }
}

header .toogle-btn {
    display: none;
    background: #fff !important;
    border: 1px solid #eee;
    margin-left: 28px;
    border-radius: 3px;
    padding: .25em;
    width: 40px;
    height: 38px;
    cursor: pointer;
    position: relative
}

@media (max-width:992px) {
    header .toogle-btn {
        display: block
    }
}

@media (max-width:576px) {
    header .toogle-btn {
        margin-left: 10px;
        height: 34px
    }
}

header .toogle-btn.active span {
    transform: rotate(45deg);
    top: 19px
}

header .toogle-btn.active span:nth-of-type(2) {
    display: none
}

header .toogle-btn.active span:nth-of-type(3) {
    transform: rotate(-45deg);
    top: 20px
}

header .toogle-btn span {
    position: absolute;
    top: 10px;
    width: 75%;
    height: 2px;
    background-color: #4d5471;
    left: 4px
}

@media (max-width:576px) {
    header .toogle-btn span {
        top: 7px
    }
}

header .toogle-btn span:nth-of-type(2) {
    top: 18px
}

@media (max-width:576px) {
    header .toogle-btn span:nth-of-type(2) {
        top: 16px
    }
}

header .toogle-btn span:nth-of-type(3) {
    top: 25px
}

footer {
    background-color: #F9F9F9;
    padding: 50px 0 40px
}

footer .footer-logo {
    margin: 10px 0 40px;
    width: 202px;
    height: 33.33px
}

footer .col {
    width: 25%
}

footer .col:nth-of-type(1) {
    margin-bottom: 30px
}

@media (max-width:767px) {
    footer .col {
        width: 50%
    }

    footer .col:nth-of-type(2) {
        margin-bottom: 30px
    }
}

footer h6 {
    line-height: 1.25em;
    color: #303030;
    padding-bottom: 13px;
    position: relative
}

/* footer h6:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 80px;
    height: 1px;
    background-color: #ff8c00
} */

footer ul {
    margin-top: 17px
}

footer li {
    margin-bottom: 8px
}

footer li i {
    font-size: 14px !important;
    padding-right: 10px;
    transition: all .25s ease
}

footer li a {
    font-size: 14px;
    line-height: 24px;
    font-family: Lato, sans-serif;
    font-weight: 400;
    transition: all .25s ease
}

footer .btns,
footer .btns_member {
    font-size: 15px;
    line-height: 1.25em;
    font-family: Lato, sans-serif;
    transition: all .25s ease
}

footer .btns {
    border-radius: 5px;
    border: 2px solid #ff8c00;
    padding: 12px 24px;
    color: #ff8c00;
    font-weight: 400;
    margin-top: 10px
}

footer .btns:hover,
footer .btns_member {
    color: #fff;
    background-color: #ff8c00
}

footer .btns_member {
    border-radius: 5px;
    border: 2px solid #ff8c00;
    padding: 8px 24px;
    font-weight: 400;
    width: 90%
}

.contact-popup .head h3,
.home-service h3,
footer .copyright p {
    font-family: Effra-Regular;
    font-weight: 400;
    line-height: 1.25em
}

@media (max-width:576px) {
    header .toogle-btn span:nth-of-type(3) {
        top: 24px
    }

    footer .btns_member {
        padding: 8px 20px;
        width: 100%
    }
}

footer .btns_member:hover {
    background-color: #fff;
    color: #ff8c00
}

footer .wrapper {
    padding: 10px 0 50px
}

footer .copyright {
    padding: 10px
}

footer .copyright p {
    margin-bottom: 20px;
    color: #303030;
    font-size: 14px
}

footer .social-icons li {
    margin: 0 7px
}

footer .social-icons a {
    width: 42px;
    height: 42px;
    border: 2px solid #080754;
    border-radius: 10%;
    display: flex;
    align-items: center;
    justify-content: center
}

footer .social-icons a:hover {
    border: 2px solid #ff8c00
}

footer .social-icons a:hover i {
    color: #ff8c00
}

footer .social-icons i {
    color: #080754;
    font-size: 21px !important;
    padding-right: 0
}

footer .bottom-links {
    justify-content: center;
    margin-top: 30px
}

footer .bottom-links li {
    padding: 0 6px;
    position: relative
}

@media (max-width:767px) {
    .who-we-are .col:nth-of-type(2) {
        width: 100%
    }

    .who-we-are .tab {
        display: none
    }
}

.who-we-are .tab li {
    cursor: pointer;
    padding: 20px 25px;
    color: #303030;
    font-size: 14px;
    line-height: 1.25em
}

@media (max-width:1200px) {
    .who-we-are .tab li {
        padding: 20px 19px
    }
}

@media (max-width:1024px) {
    .who-we-are .tab li {
        padding: 20px 12px
    }
}

.who-we-are .tab li.active {
    color: #ff8c00
}

.who-we-are .tab-wrapper {
    padding-top: 20px;
    padding-left: 20px;
    display: block
}

.who-we-are .accordian,
.who-we-are .tab-wrapper .tab-content {
    display: none
}

.who-we-are .tab-wrapper h2 {
    line-height: 1.7em;
    font-size: 28px;
    font-weight: 400;
    font-family: Roboto, sans-serif;
    color: #303030
}

@media (max-width:1024px) {
    .who-we-are .tab-wrapper h2 {
        font-size: 24px
    }
}

@media (max-width:767px) {
    .who-we-are .tab-wrapper {
        display: none
    }

    .who-we-are .accordian {
        display: block
    }
}

.who-we-are .accordian h4 {
    cursor: pointer;
    padding: 10px;
    color: #303030;
    font-size: 14px;
    line-height: 1.25em
}

.who-we-are .accordian h2 {
    line-height: 1.7em;
    font-size: 18px;
    font-weight: 400;
    font-family: Roboto, sans-serif;
    color: #303030;
    padding: 10px
}

.who-we-are .accordian .box.active .head h4 {
    color: #ff8c00
}


.explore-section {
    padding: 100px 0
}

.explore-section li {
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .25);
    width: calc(33.33% - 13.33px);
    margin-right: 20px;
    margin-bottom: 30px
}

@media (max-width:767px) {
    .explore-section {
        padding: 50px 0
    }

    .explore-section li {
        width: 100%;
        margin-right: 0
    }
}

.explore-section li:nth-child(3n) {
    margin-right: 0
}

.explore-section li:nth-of-type(4) {
    width: calc(50% - 10px)
}

.explore-section li:nth-of-type(4) figure {
    height: 378px
}

@media (max-width:992px) {
    .explore-section li:nth-of-type(4) figure {
        height: 340px
    }
}

@media (max-width:767px) {
    .explore-section li:nth-of-type(4) {
        margin-right: 0;
        width: 100%
    }

    .explore-section li:nth-of-type(4) figure {
        height: 324px
    }
}

@media (max-width:576px) {
    .explore-section li:nth-of-type(4) figure {
        height: 210px
    }
}

.explore-section li:nth-of-type(5) {
    width: calc(50% - 10px);
    margin-right: 0
}

.explore-section li:nth-of-type(5) figure {
    height: 378px
}

@media (max-width:992px) {
    .explore-section li:nth-of-type(5) figure {
        height: 340px
    }
}

@media (max-width:767px) {
    .explore-section li:nth-of-type(5) {
        margin-right: 0;
        width: 100%
    }

    .explore-section li:nth-of-type(5) figure {
        height: 324px
    }

    .explore-section .heading-line a {
        width: auto
    }
}

@media (max-width:576px) {
    .explore-section li:nth-of-type(5) figure {
        height: 210px
    }
}

.explore-section a {
    border-radius: 5px;
    height: 100%
}

.explore-section figure {
    width: 100%;
    height: 213px;
    border-radius: 5px;
    position: relative
}

@media (max-width:767px) {
    .explore-section a {
        width: 100%
    }

    .explore-section figure {
        height: 324px
    }
}

@media (max-width:576px) {
    .explore-section figure {
        height: 210px
    }
}

.explore-section figure img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px
}

.explore-section figure:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-image: linear-gradient(300deg, #00699400 0, #000 71%);
    opacity: .2;
    border-radius: 5px
}

.explore-section figure .figcaption {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 2em
}

.explore-section figure .figcaption h4 {
    padding: .5em 0;
    font-size: 20px;
    font-weight: 400;
    font-family: Effra-Medium;
    line-height: 1.7em;
    letter-spacing: .1px;
    color: #fff;
    position: relative
}

.news-section h4,
.news-section h4 a,
.newsletter h3 {
    font-family: Effra-Regular;
    color: #303030;
    font-weight: 400
}

.otherLogin .content .kep-login-facebook {
    text-transform: initial !important;
}

@media (max-width:576px) {
    .explore-section figure .figcaption h4 {
        padding: 0
    }
}

.explore-section figure .figcaption h4:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 3px;
    background-color: #fff;
    transition: all .25s ease
}

.explore-section figure:hover .figcaption h4:after {
    width: 100%
}

.newsletter {
    background-color: #D2E3E6;
    padding: 100px 0
}

.newsletter h3 {
    width: calc(100% - 642px);
    font-size: 24px;
    line-height: 1.25em
}

@media (max-width:992px) {
    .newsletter h3 {
        width: calc(100% - 550px)
    }
}

@media (max-width:767px) {
    .newsletter {
        padding: 50px 0
    }

    .newsletter .container-sm {
        padding: 0
    }

    .newsletter h3 {
        width: 100%;
        margin-bottom: 15px;
        text-align: center
    }
}

.newsletter .newsletterForm {
    width: 642px;
    display: flex
}

@media (max-width:992px) {
    .newsletter .newsletterForm {
        width: 550px;
        padding-left: 30px
    }
}

@media (max-width:767px) {
    .newsletter .newsletterForm {
        width: 100%;
        padding-left: 0
    }
}

.newsletter .wrapper {
    align-items: center
}

.news-section {
    padding: 60px 0
}

.news-section li {
    width: calc(33.33% - 20px);
    margin-right: 30px;
    border-radius: 5px;
    border: 1px solid #e4e4e4;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, .15);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .15);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    transition: all .35s ease
}

.news-section li:hover {
    -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, .15);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .15)
}

.news-section li:last-of-type {
    margin-right: 0
}

.news-section figure {
    width: 100%;
    height: 232px
}

@media (max-width:767px) {
    .news-section li {
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px
    }

    .news-section li:last-of-type {
        margin-bottom: 0
    }

    .news-section figure {
        height: 278px
    }
}

@media (max-width:576px) {
    .news-section figure {
        height: 175px
    }
}

.news-section figure a,
.news-section figure img {
    width: 100%;
    height: 100%
}

.news-section .content {
    padding: 30px
}

.news-section h4 {
    margin-bottom: 25px;
    line-height: 1.25em
}

.news-section h4 a {
    line-height: 1.25em
}

.news-section .read_more,
.news-section p {
    font-family: Roboto, sans-serif
}

.news-section p {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.7em;
    color: #777;
    letter-spacing: .1px
}

.news-section .read_more {
    margin: 25px 0;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    color: #ff8c00
}

.news-section .date {
    font-family: Effra-Medium;
    font-weight: 400;
    line-height: 1.3em;
    font-size: 12px;
    color: #adadad;
    border-top: 1px solid #eaeaea;
    padding: 15px 30px;
    width: 100%;
    margin-top: auto
}

.about-content {
    padding: 85px 0
}

.about-content .container-sm {
    max-width: 833px
}

.about-content p {
    margin-bottom: 1em;
    letter-spacing: .1px;
    line-height: 1.7em;
    font-weight: 400;
    font-size: 16px;
    color: #4d5471;
    font-family: Lato, sans-serif
}

.about-service h3,
.about-service li h3,
.blog-list .tag,
.blog-list h3 {
    font-family: Effra-Regular;
    font-weight: 400;
    line-height: 1.25em
}

.about-content p:last-of-type {
    margin-bottom: 0
}

.about-service h3 {
    color: #303030;
    margin-bottom: 50px;
    margin-top: 10px
}

.about-service ul {
    padding-bottom: 100px
}

.about-service li {
    width: calc(33.33% - 13.33px);
    margin-right: 20px;
    padding: 40px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .19)
}

.about-service li:nth-of-type(3n) {
    margin-right: 0
}

.about-service li h3 {
    color: #303030;
    margin: 1em 0
}

.about-service li figure {
    margin: 0 auto 15px;
    width: 72px;
    height: 72px
}

.blog-list {
    padding: 80px 0;
    background-color: #f4f4f4
}

.blog-list li {
    margin-bottom: 45px
}

.blog-list figure {
    width: 50%;
    height: 375px
}

.blog-list figure img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px
}

.blog-list .content {
    width: 50%;
    padding: 30px;
    margin: 30px 30px 30px -30px;
    background-color: #fff;
    z-index: 1;
    border-radius: 10px;
    box-shadow: 0 1px 25px rgba(0, 0, 0, .5)
}

.blog-list .tag {
    color: #006994;
    padding: 5px 10px;
    border-radius: 2px;
    background-color: #e4e4e4;
    margin-bottom: 10px;
    font-size: 14px
}

.blog-list .tag:hover,
.blog-list h3 {
    color: #303030
}

.blog-list h3 {
    margin: 1em 0 20px;
    padding-bottom: 12px;
    position: relative
}

.blog-list h3:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 25%;
    background-color: #006994
}

.blog-list p {
    margin: 1em 0 20px;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.7em;
    letter-spacing: .1px;
    padding-right: 10px
}

.blog-list .date,
.login-section h3 {
    font-weight: 400;
    font-family: Effra-Regular
}

.blog-list .date {
    line-height: 1.3em;
    font-size: 12px;
    color: #adadad;
    margin: 0
}

.blog-list .date i {
    padding-right: 5px
}

.login-section {
    padding: 70px 0
}

.login-section .container-sm {
    max-width: 600px
}

.login-section .wrapper {
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 20px 25px 40px;
    background-color: #fff
}

.login-section h3 {
    color: #303030;
    margin-bottom: 20px
}

.login-section a,
.login-section p {
    color: #4d5471;
    line-height: 1.7em;
    letter-spacing: .1px;
    font-size: 16px
}

.sidebar_set {
    position: relative;
    top: 6rem;
}


.sidebar_set ul li svg {
    color: #4d5471;
    margin: 7px 7px 0 0;
    width: 28px;
}

.sidebar_set ul li svg:hover {
    color: #ff8c00 !important;
}

.sidebar_set ul li a:hover {
    color: #ff8c00 !important;
}

.login-section a,
.login-section p,
.user-dashboard .left-sidebar a {
    font-weight: 400;
    font-family: Lato, sans-serif
}


.login-section p {
    padding-bottom: 30px;
    border-bottom: 1px solid #eee
}

.login-section a:hover {
    color: #ff8c00
}

.user-dashboard .left-sidebar {
    background-color: #f4f4f4;
    padding-left: 20px;
    width: 25%;
    height: 100vh;
    position: fixed;
    left: 0;
    z-index: 10;
}

.user-dashboard .left-sidebar ul {
    background-color: #f8f9fb;
    padding: 30px 20px 20px;
    height: 100%
}

.user-dashboard .left-sidebar li {
    margin-bottom: 5px
}

.user-dashboard .left-sidebar li.active a {
    border-left: solid 4px #ff8c00
}

.user-dashboard .left-sidebar a {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 1.25em;
    color: #4d5471;
    padding: 10px 0 10px 10px
}

.available-section p,
.user-dashboard .addProp-btn a {
    font-weight: 400;
    font-size: 16px;
    font-family: Effra-Regular
}

.user-dashboard .left-sidebar a img {
    margin-right: 7px
}

.contact-fixing {
    top: 3rem;
    position: relative;
    z-index: -1;
}

.user-dashboard .main-content {
    width: calc(100% - 25%);
    padding: 30px;
    margin-left: auto
}

.user-dashboard .addProp-btn {
    text-align: right
}

.user-dashboard .addProp-btn a {
    border-radius: 5px;
    line-height: 1.4em;
    letter-spacing: 0;
    background-color: #ff8c00;
    border: 1px solid transparent;
    color: #fff;
    padding: 12px 30px;
    cursor: pointer;
    transition: all .25s ease
}

.user-dashboard .addProp-btn a:hover {
    background-color: #006994
}

.available-section {
    padding: 30px 0 20px
}

.available-section li {
    width: calc(33.33% - 20px);
    margin-right: 30px;
    border-radius: 4px;
    border: 1px solid #006994;
    padding: 25px;
    display: flex;
    align-items: center
}

.available-section li:nth-of-type(3n) {
    margin-right: 0
}

.available-section p {
    color: #4d5471;
    line-height: 1.4em;
    width: calc(100% - 90px)
}

.available-section h2 {
    width: 90px;
    text-align: right;
    font-size: 46px;
    color: #000;
    font-weight: 600;
    font-family: Lato, sans-serif
}

.contact-info a,
.contact-info p,
.service-sec p {
    font-weight: 400;
    font-family: Effra-Regular
}

.service-sec {
    padding: 10px 0 30px
}

.service-sec li {
    background-color: #f4f4f4;
    border-radius: 4px;
    width: calc(33.33% - 20px);
    margin-right: 30px;
    padding: 35px 20px 35px 35px;
    display: flex;
    align-items: center
}

.service-sec li:nth-of-type(3n) {
    margin-right: 0
}

.service-sec p {
    color: #4d5471;
    line-height: 1.4em;
    font-size: 16px;
    padding-left: 25px
}

.contact-info {
    padding: 35px 0 10px
}

.contact-info .col {
    width: 50%
}

.contact-info h2 {
    color: #000;
    font-size: 24px;
    line-height: 1.25em;
    margin-bottom: 15px
}

.contact-info li,
.contact-info p {
    margin-bottom: 7px
}

.contact-info a,
.contact-info p {
    color: #4d5471;
    line-height: 1.4em;
    font-size: 16px
}

.contact-info i {
    margin-right: 10px
}

.contact-info a:hover {
    color: #ff8c00
}

.setActive {
    color: #ff8c00 !important;

}