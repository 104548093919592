*,
ul {
  margin: 0;
  padding: 0;
}

.listings a:hover,
a,
a:hover {
  text-decoration: none;
}

.container-lg,
.relative,
body,
figure img {
  width: 100%;
}

.bookmark-section .bookmark-slider .slick-arrow:before,
.listings .list-img-slider .slick-arrow:before,
body,
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Lato, sans-serif;
}

@font-face {
  font-family: Effra-Regular;
  font-display: auto;
  font-weight: 400;
  src: url(../../assets/fonts/Effra_Std_Rg.ttf) format("TrueType");
}

@font-face {
  font-family: Effra-Light;
  font-display: auto;
  font-weight: 300;
  src: url(../../assets/fonts/Effra_Std_Lt.ttf) format("TrueType");
}

@font-face {
  font-family: Effra-Bold;
  font-display: auto;
  font-weight: 700;
  src: url(../../assets/fonts/Effra_Std_Bd.ttf) format("TrueType");
}

@font-face {
  font-family: Effra-Medium;
  font-display: auto;
  font-weight: 500;
  src: url(../../assets/fonts/Effra-Medium.ttf) format("TrueType");
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.luxy-el,
.slick-slider .slick-list,
.slick-slider .slick-track,
.swiper-container,
.swiper-slide,
.swiper-wrapper,
[data-aos],
[data-jarallax-element] {
  will-change: transform;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body.active {
  overflow: hidden;
}

li {
  list-style: none;
}

img {
  max-width: 100%;
  vertical-align: top;
}

.relative {
  position: relative;
}

.color-white * {
  color: #fff;
}

.container-lg {
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 17px;
}

.container {
  max-width: 1300px;
  margin: 0 auto;
}

.container-sl {
  max-width: 1520px;
  margin: 0 auto;
}

.container-sm {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 20px;
}

figure,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

p {
  font-weight: 600;
}

body,
h3 {
  font-weight: 700;
}

.bg-control {
  background-size: cover;
  background-repeat: no-repeat;
}

.slick-slide {
  outline: 0;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.4);
}

::-webkit-scrollbar-thumb {
  background: #fff;
}

a,
span {
  display: inline-block;
}

body {
  -webkit-text-size-adjust: none;
  position: relative;
  font-size: 18px;
  color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #ff8c00;
}

h1 {
  font-size: 41px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 21px;
}

h5 {
  font-size: 19px;
}

.bannerTab button,
h6,
p {
  font-size: 18px;
}

p {
  line-height: 1.55em;
}

.btn,
a,
li {
  color: #000;
}

.btn {
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

@media (max-width: 1440px) {
  .container {
    max-width: 1180px;
  }
}

@media (max-width: 1280px) {
  .container {
    max-width: 1000px;
  }
}

@media (max-width: 1024px) {
  .container {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .container {
    padding: 0 18px;
  }
}

@media (max-width: 1200px) {
  .container-sm {
    max-width: 100%;
    padding: 0 40px;
  }
}

@media (max-width: 992px) {
  .container-sm {
    padding: 0 20px;
  }
}

@media (max-width: 767px) {
  .container-sm {
    padding: 0 15px;
  }
}

@media (max-width: 1600px) {
  .container-sl {
    max-width: 1300px;
  }
}

@media (max-width: 1440px) {
  .container-sl {
    max-width: 1200px;
  }
}

@media (max-width: 1366px) {
  .container-sl {
    max-width: 1160px;
  }
}

@media (max-width: 1280px) {
  .container-sl {
    max-width: 1060px;
  }
}

@media (max-width: 1140px) {
  .container-sl {
    max-width: 900px;
  }
}

@media (max-width: 992px) {
  .container-sl {
    max-width: 90%;
  }
}

@media (max-width: 768px) {
  .container-sl {
    max-width: 100%;
    padding: 0 40px;
  }
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pd72 {
  padding: 0 72px;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex.nowrap {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.flex.column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
}

.flex.space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.flex.space-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.flex.space-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.flex.space-evenly {
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
}

.flex.space-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.flex.align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex.align-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.flex.align-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.bannerTab .form_group:first-of-type {
  width: 75%;
  padding-right: 10px;
}

@media (max-width: 576px) {
  .bannerTab form {
    flex-wrap: wrap;
  }

  .bannerTab .form_group:first-of-type {
    width: 100%;
    padding-right: 0;
    margin-bottom: 10px;
  }
}

.bannerTab .form_group:nth-of-type(2) {
  width: 23%;
}

@media (max-width: 992px) {
  .bannerTab .form_group:nth-of-type(2) {
    width: 25%;
  }
}

@media (max-width: 576px) {
  .bannerTab .form_group:nth-of-type(2) {
    width: 100%;
  }
}

.bannerTab input {
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  line-height: 18px;
  color: #1d1d23;
  font-family: Lato, sans-serif;
  padding: 15px;
}

.bannerTab input:focus,
.bannerTab input:hover {
  outline: 0;
}

.bannerTab button {
  background-color: #ff8c00;
  color: #fff;
  transition: all 0.25s ease;
  line-height: 1.25em;
  border-radius: 5px;
  padding: 12px;
  width: 100%;
  text-align: center;
  border: 1px solid #ff8c00;
  cursor: pointer;
  font-family: Effra-Medium;
  font-weight: 500;
}

.bannerTab button:hover {
  background-color: #006994;
  border: 1px solid #006994;
}

.bannerTab input::placeholder {
  color: #1d1d23;
  opacity: 1;
}

.bannerTab input:-ms-input-placeholder {
  color: #1d1d23;
}

.bannerTab input::-ms-input-placeholder {
  color: #1d1d23;
}

.newsletterForm .form_group:first-of-type {
  width: calc(100% - 160px);
}

@media (max-width: 767px) {
  .newsletterForm {
    flex-wrap: wrap;
  }

  .newsletterForm .form_group {
    margin-bottom: 10px;
  }

  .newsletterForm .form_group:first-of-type {
    width: 100%;
  }
}

.newsletterForm .form_group:nth-of-type(2) {
  width: 160px;
}

.newsletterForm input {
  width: 100%;
  background-color: #fff;
  border: none;
  border-radius: 5px 0 0 5px;
  font-size: 16px;
  line-height: 1.25em;
  color: #999;
  padding: 20px;
  font-weight: 300;
  font-family: Poppins, sans-serif;
  letter-spacing: 0.1px;
}

@media (max-width: 767px) {
  .newsletterForm .form_group:nth-of-type(2) {
    width: 100%;
  }

  .newsletterForm input {
    border-radius: 5px;
  }
}

.newsletterForm input:focus,
.newsletterForm input:hover {
  outline: 0;
}

.newsletterForm .sub-btns {
  background-color: #ff8c00;
  color: #fff;
  transition: all 0.25s ease;
  font-size: 15px;
  line-height: 1.25em;
  border-radius: 0 5px 5px 0;
  padding: 20px 40px;
  border: 1px solid #ff8c00;
  cursor: pointer;
  font-family: Lato, sans-serif;
  font-weight: 500;
}

@media (max-width: 767px) {
  .newsletterForm .sub-btns {
    width: 100%;
    border-radius: 5px;
  }
}

.newsletterForm .sub-btns:hover {
  background-color: #006994;
  border: 1px solid #006994;
}

.contact-popup button,
.contact-popup input,
.contact-popup textarea {
  border-radius: 4px;
  font-size: 16px;
  font-family: Lato, sans-serif;
}

.newsletterForm input::placeholder {
  color: #999;
  letter-spacing: 0.1px;
  opacity: 1;
}

.newsletterForm input:-ms-input-placeholder {
  color: #999;
  letter-spacing: 0.1px;
}

.newsletterForm input::-ms-input-placeholder {
  color: #999;
  letter-spacing: 0.1px;
}

.contact-popup form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contact-popup .form_group {
  width: 100%;
  margin-bottom: 20px;
}

.contact-popup .form_group.half {
  width: 47.6%;
}

@media (max-width: 576px) {
  .contact-popup .form_group.half {
    width: 100%;
  }
}

.contact-popup .form_group:last-of-type {
  margin-bottom: 0;
}

.contact-popup input,
.contact-popup textarea {
  width: 100%;
  background-color: #fff;
  color: #999;
  padding: 9px 16px;
  font-weight: 400;
  line-height: 1.7em;
  letter-spacing: 0.1px;
  border: 1px solid #818a91;
}

.contact-popup input:focus,
.contact-popup input:hover,
.contact-popup textarea:focus,
.contact-popup textarea:hover {
  outline: 0;
}

.contact-popup textarea {
  resize: none;
  height: 122px;
}

.contact-popup #subscribe {
  width: auto;
}

.contact-popup label {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7em;
  letter-spacing: 0.1px;
  font-family: Lato, sans-serif;
  color: #4d5471;
}

.contact-popup button,
.editProfile button {
  font-weight: 500;
  background-color: #ff8c00;
  cursor: pointer;
  transition: all 0.25s ease;
}

.contact-popup button {
  color: #fff;
  line-height: 1.25em;
  padding: 13px 30px;
  border: 1px solid #ff8c00;
  width: 100%;
  margin-top: 10px;
}

.user-login input,
.user-login label {
  width: 100%;
  font-weight: 400;
  font-family: Roboto, sans-serif;
}

.contact-popup button:hover {
  background-color: #006994;
  border: 1px solid #006994;
}

.contact-popup input::placeholder {
  color: #999;
  opacity: 1;
}

.contact-popup input:-ms-input-placeholder {
  color: #999;
}

.contact-popup input::-ms-input-placeholder {
  color: #999;
}

.contact-popup textarea::placeholder {
  color: #999;
  opacity: 1;
}

.contact-popup textarea:-ms-input-placeholder {
  color: #999;
}

.contact-popup textarea::-ms-input-placeholder {
  color: #999;
}

.user-login {
  width: 100%;
  margin-top: 40px;
}

.user-login .form_group {
  width: 100%;
  margin-bottom: 20px;
}

.user-login .form_group.flex {
  justify-content: space-between;
  align-items: center;
}

.user-login .form_group:last-of-type {
  margin-bottom: 0;
}

.user-login sup {
  color: red;
  line-height: 1em;
}

.user-login label {
  margin-bottom: 6px;
  font-size: 16px;
  line-height: 1.7em;
  letter-spacing: 0.1px;
  color: #4d5471;
  display: block;
}

@media (max-width: 767px) {
  .user-login label {
    font-size: 15px;
  }
}

.user-login input {
  border: 1px solid #efefef;
  padding: 12px 15px;
  font-size: 16px;
  line-height: 1.4em;
  color: #666;
  border-radius: 4px;
}

.user-login input:focus,
.user-login input:hover {
  outline: 0;
}

.user-login .col {
  display: flex;
  align-items: center;
}

.editProfile label,
.user-login .col label {
  display: block;
  font-weight: 400;
  line-height: 1.2em;
}

.user-login .col label {
  width: auto;
  font-size: 15px;
  letter-spacing: 0.1px;
  font-family: Roboto, sans-serif;
  color: #4d5471;
  margin-bottom: 0;
  margin-left: 7px;
}

.editProfile .form_group:nth-of-type(1),
.editProfile .form_group:nth-of-type(2),
.heading-line h3 {
  margin-bottom: 30px;
}

.user-login .col input {
  width: 16px;
  height: 16px;
}

.user-login button {
  width: 100%;
  border-radius: 5px;
  font-family: Effra-Bold;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4em;
  letter-spacing: 0;
  background-color: #ff8c00;
  border: 1px solid transparent;
  color: #fff;
  padding: 12px 20px;
  cursor: pointer;
  transition: all 0.25s ease;
}

.user-login button:hover {
  background-color: #006994;
}

.editProfile .form_group {
  width: 100%;
}

.editProfile .form_group.headingTop {
  border: 1px solid silver;
  padding: 0.35em 0.625em 0.75em;
  position: relative;
  margin-bottom: 20px;
}

.editProfile .form_group.headingTop label {
  width: auto;
  font-size: 14px;
  border-bottom: 2px solid #999;
  font-weight: 600;
  margin-bottom: 20px;
  color: #242429;
  display: inline-block;
  position: absolute;
  top: -10px;
  padding: 0 5px 6px;
  background-color: #fff;
}

.editProfile .form_group.headingTop p {
  color: #333;
  font-size: 11px;
  line-height: 15px;
  font-weight: 400;
  font-family: Lato, sans-serif;
  margin-top: 10px;
  margin-bottom: 22px;
}

.editProfile label {
  color: #242429;
  font-size: 14px;
  font-family: Lato, sans-serif;
}

.editProfile input {
  width: 100%;
  font-weight: 600;
  color: #222;
  font-size: 14px;
  line-height: 1.2em;
  border: none;
  outline: 0;
}

.editProfile .placeHolder {
  width: 135px;
  height: 135px;
  border: 2px dashed #ddd;
  margin-top: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.editProfile .placeHolder .filesInput {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.editProfile .newImg {
  margin-top: 33px;
  margin-left: 15px;
  height: 135px;
  width: 135px;
  position: relative;
}

.editProfile .newImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid silver;
}

.editProfile .newImg .dltImg {
  width: 25px;
  height: 25px;
  background-color: #ff8c00;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
  transition: all 0.25s ease;
  opacity: 0;
  visibility: hidden;
}

.editProfile .newImg .dltImg img {
  width: auto;
  height: auto;
  border: none;
  border-radius: 50%;
}

.editProfile .newImg:hover .dltImg {
  opacity: 1;
  visibility: visible;
}

.editProfile .passWrap {
  margin-top: 45px;
}

.editProfile .inputWrap {
  position: relative;
  margin-bottom: 15px;
}

.editProfile .inputWrap input {
  padding: 14px 0;
  color: #222;
  font-size: 14px;
}

.editProfile .inputWrap input:focus~span {
  top: -15px;
}

.editProfile .inputWrap span {
  position: absolute;
  left: 0;
  top: -4px;
  color: #242429;
  font-size: 13px;
  font-weight: 400;
  font-family: Lato, sans-serif;
  transition: all 0.25s ease;
}

.editProfile button,
.inner-heading h1 {
  font-family: Effra-Medium;
}

.editProfile button {
  padding: 14px 40px;
  border-radius: 5px;
  font-size: 13px;
  line-height: 1.2em;
  color: #fff;
  border: none;
  width: 100%;
}

.editProfile button:hover {
  background-color: #006994;
}

.getInTouch-popup form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.getInTouch-popup .form_group {
  width: 100%;
  margin-bottom: 20px;
}

.getInTouch-popup .form_group.half {
  width: 47.6%;
}

@media (max-width: 576px) {
  .getInTouch-popup .form_group.half {
    width: 100%;
  }
}

.getInTouch-popup .form_group:last-of-type {
  margin-bottom: 0;
}

.getInTouch-popup input,
.getInTouch-popup textarea {
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  color: #999;
  padding: 9px 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7em;
  letter-spacing: 0.1px;
  font-family: Lato, sans-serif;
  border: 1px solid #818a91;
}

.getInTouch-popup input:focus,
.getInTouch-popup input:hover,
.getInTouch-popup textarea:focus,
.getInTouch-popup textarea:hover {
  outline: 0;
}

.getInTouch-popup textarea {
  resize: none;
  height: 122px;
}

.getInTouch-popup #subscribe {
  width: auto;
}

.getInTouch-popup label {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7em;
  letter-spacing: 0.1px;
  font-family: Lato, sans-serif;
  color: #4d5471;
}

.getInTouch-popup button {
  background-color: #ff8c00;
  color: #fff;
  transition: all 0.25s ease;
  font-size: 16px;
  line-height: 1.25em;
  border-radius: 4px;
  padding: 13px 30px;
  border: 1px solid #ff8c00;
  cursor: pointer;
  font-family: Lato, sans-serif;
  font-weight: 500;
  width: 100%;
  margin-top: 10px;
}

.getInTouch-popup button:hover {
  background-color: #006994;
  border: 1px solid #006994;
}

.getInTouch-popup input::placeholder {
  color: #999;
  opacity: 1;
}

.getInTouch-popup input:-ms-input-placeholder {
  color: #999;
}

.getInTouch-popup input::-ms-input-placeholder {
  color: #999;
}

.getInTouch-popup textarea::placeholder {
  color: #999;
  opacity: 1;
}

.getInTouch-popup textarea:-ms-input-placeholder {
  color: #999;
}

.getInTouch-popup textarea::-ms-input-placeholder {
  color: #999;
}

.home-banner .banner-content h1,
.home-banner .tab li {
  color: #fff;
  font-family: Effra-Bold;
}

.home-banner {
  width: 100%;
  height: 100vh;
  position: relative;
}

.home-banner .home-slider {
  width: 100%;
  height: 100%;
}

.home-banner .slick-list,
.home-banner .slick-slide,
.home-banner .slick-track {
  height: 100%;
}

.home-banner figure {
  height: 100%;
  position: relative;
}

.home-banner figure::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(1, 1, 1, 0.26);
}

.home-banner img {
  height: 100%;
  object-fit: cover;
}

.home-banner .banner-content {
  width: 750px;
  position: absolute;
  left: 50%;
  top: 50%;
  background: 0 0;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 9999;
}

@media (max-width: 767px) {
  .home-banner .banner-content {
    width: 100%;
    padding: 0 15px;
    z-index: 9;
  }
}

.home-banner .banner-content h1 {
  font-weight: 700;
  line-height: 1.2em;
  display: inherit;
  margin: 0 0 1.25em;
}

.home-banner .tab {
  display: flex;
  width: 500px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.26);
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
}

.home-banner .tab-wrapper .tab-content,
header.home figure .blue-logo {
  display: none;
}

@media (max-width: 576px) {
  .home-banner .banner-content h1 {
    font-size: 30px;
  }

  .home-banner .tab {
    width: 100%;
  }
}

.home-banner .tab li {
  cursor: pointer;
  padding: 12px 30px;
  font-size: 16px;
  line-height: 1.25em;
  font-weight: 600;
}

@media (max-width: 576px) {
  .home-banner .tab li {
    padding: 10px 18px;
    font-size: 14px;
  }
}

.home-banner .tab li.active {
  color: #3a3a3a;
  background-color: #fff;
  border-radius: 5px;
}

.home-banner .tab-wrapper {
  border-radius: 7px;
  margin-top: 20px;
  background-color: #fff;
  padding: 20px;
  position: relative;
  max-height: 88px;
}

.heading-line,
.innerBanner,
.loader {
  display: flex;
}

.home-banner .tab-wrapper h2 {
  line-height: 1.7em;
  font-size: 28px;
  font-weight: 400;
  font-family: Roboto, sans-serif;
  color: #303030;
}

.home-banner .downBtn {
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
}

.home-banner .downBtn a {
  width: 60px;
  height: 60px;
}

.innerBanner {
  background-position: center center;
  background-image: url('../../assets/images/why-list-with-us-img.jpg');
  background-size: cover;
  background-attachment: fixed;
  align-items: center;
  justify-content: center;
  min-height: 522px;
}

.center-heading,
.loader,
footer .social-icons {
  justify-content: center;
}

@media (max-width: 992px) {
  .innerBanner {
    min-height: 420px;
  }
}

.loader {
  background-color: #fff;
  width: 100%;
  height: 100vh;
  align-items: center;
  z-index: 999999;
  position: fixed;
}

.heading-line,
header {
  justify-content: space-between;
}

.typewriter {
  width: 24%;
}

.typewriter h2 {
  color: #ff8c00;
  font-size: 40px;
  font-family: Effra-Bold;
  font-family: monospace;
  overflow: hidden;
  border-right: 0.15em solid orange;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: 0.15em;
  animation: typing 3.5s steps(30, end), blink-caret 0.5s step-end infinite;
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes blink-caret {

  from,
  to {
    border-color: transparent;
  }

  50% {
    border-color: orange;
  }
}

.heading-line h3,
.inner-heading h1 {
  display: inline-block;
  position: relative;
}

.heading-line h3 {
  color: #303030;
  font-family: Effra-Regular;
  font-weight: 400;
  line-height: 1.25em;
  padding-top: 17px;
}

/* .heading-line h3::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 3px;
  width: 80px;
  background-color: #ff8c00;
} */

.heading-line a {
  font-family: Lato, sans-serif;
  font-size: 15px;
  line-height: 1.5em;
  font-weight: 500;
  color: #fff;
  background-color: #ff8c00;
  transition: all 0.25s ease;
  border-radius: 3px;
  padding: 12px 24px;
  align-self: flex-start;
  margin-top: 10px;
}

.heading-line a:hover {
  background-color: #006994;
}

.center-heading h1::before,
.inner-heading h1:before {
  height: 3px;
  width: 80px;
  /* background-color: #ff8c00; */
  transform: translateX(-50%);
  top: 0;
}

.inner-heading {
  text-align: center;
}

@media (max-width: 767px) {
  .innerBanner {
    min-height: 400px;
  }

  .heading-line {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .heading-line h3 {
    margin-bottom: 8px;
  }

  .heading-line a {
    margin: 10px 0 10px auto;
  }

  .inner-heading {
    padding: 0 10px;
  }
}

.inner-heading h1 {
  font-size: 48px;
  font-weight: 600;
  color: #fff;
  line-height: 1.2em;
  padding-top: 13px;
  margin-bottom: 15px;
}

@media (max-width: 992px) {
  .inner-heading h1 {
    font-size: 40px;
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .inner-heading h1 {
    font-size: 32px;
  }
}

.inner-heading h1:before {
  content: "";
  position: absolute;
  left: 50%;
}

.inner-heading h3 {
  color: #fff;
  font-family: Effra-Regular;
  letter-spacing: 0.1px;
  font-weight: 400;
  line-height: 1.25em;
}

@media (max-width: 992px) {
  .inner-heading h3 {
    font-size: 20px;
  }
}

.center-heading {
  display: flex;
}

.center-heading h1 {
  color: #303030;
  font-family: Effra-Medium;
  font-size: 48px;
  font-weight: 600;
  line-height: 1.25em;
  margin-bottom: 30px;
  display: inline-block;
  position: relative;
  padding-top: 12px;
}

@media (max-width: 992px) {
  .center-heading h1 {
    font-size: 40px;
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .center-heading h1 {
    font-size: 32px;
    margin-bottom: 8px;
  }
}

.center-heading h1::before {
  content: "";
  position: absolute;
  left: 50%;
}

.pagination_div button,
.pagination_div span {
  padding: 4px 7px !important;
  border: none !important;
  font-family: Lato, sans-serif !important;
}

.pagination_div button:hover,
.pagination_div span:hover {
  background-color: #006994 !important;
}

.pagination_div button.active_page,
.pagination_div span.active_page {
  font-size: 16px !important;
  border: none !important;
  background-color: #006994 !important;
}

header li a,
header.dashboard-header .loginName {
  font-size: 17px;
  font-family: Lato, sans-serif;
}

header {
  background-color: #fff;
  z-index: 99;
  position: static;
  left: 0;
  top: 0;
  width: 100%;
  padding: 13px 20px 13px 23px;
  align-items: center;
}

@media (max-width: 992px) {
  header {
    justify-content: unset;
  }
}

header .container_ {
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@media (min-width: 1450px) {
  header .container_ {
    max-width: 1100px;
    margin: 0 auto;
  }
}

header.home {
  background-color: transparent;
  position: absolute;
}

header figure .blue-logo,
header.home figure .white-logo {
  display: block;
}

header.home li a {
  color: #fff;
}

header.home .loginBtn {
  border: 2px solid #ff8c00;
  background-color: #ff8c00;
  color: #fff;
  margin-right: 15px;
}

header.home .loginBtn:hover {
  background-color: #006994;
  color: #fff;
  border: 2px solid #006994;
}

header figure {
  width: 187px;
  height: 31px;
}

@media (max-width: 576px) {
  header {
    padding: 20px 15px;
  }

  header figure {
    width: 130px;
    height: 28px;
  }
}

header figure .white-logo {
  display: none;
}

header li {
  margin: 0 15px;
}

header li a {
  color: #303030;
  font-weight: 700;
  line-height: 1.4em;
}

footer h6,
header .loginBtn {
  font-family: Effra-Medium;
}

@media (max-width: 992px) {
  header .container_ {
    justify-content: unset;
  }

  header figure {
    order: -1;
  }

  header li {
    margin: 0;
    padding: 10px 20px;
  }

  header li a {
    color: #494c4f !important;
  }
}

header nav {
  position: relative;
}

header .loginBtn {
  border: 2px solid #303030;
  background-color: transparent;
  align-self: flex-start;
  padding: 8px 8px 6px;
  font-size: 15px;
  line-height: 21px;
  border-radius: 4px;
  font-weight: 500;
  color: #303030;
  margin-right: 35px;
  transition: all 0.25s ease;
}

@media (max-width: 576px) {
  header .loginBtn {
    padding: 6px 5px 4px 5px;
    font-size: 13px;
  }
}

header .loginBtn:hover {
  background-color: #ff8c00;
  color: #fff;
  border: 2px solid #ff8c00;
}

@media (max-width: 992px) {
  header .loginBtn {
    order: -1;
    margin-left: auto;
    margin-right: 0;
  }

  header .menus {
    display: none;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
    min-width: 290px;
    border: 1px solid #ddd;
    position: absolute;
    background-color: #fff;
    z-index: 99999;
    right: -15px;
  }

  header .menus ul {
    flex-direction: column;
  }
}

header .toogle-btn {
  display: none;
  background: #fff !important;
  border: 1px solid #eee;
  margin-left: 28px;
  border-radius: 3px;
  padding: 0.25em;
  width: 40px;
  height: 38px;
  cursor: pointer;
  position: relative;
}

header.dashboard-header,
header.dashboard-header-white {
  padding: 22px 60px 22px 35px;
}

@media (max-width: 992px) {
  header .toogle-btn {
    display: block;
  }
}

@media (max-width: 576px) {
  header .toogle-btn {
    margin-left: 10px;
    height: 34px;
  }
}

header .toogle-btn.active span {
  transform: rotate(45deg);
  top: 19px;
}

header .toogle-btn.active span:nth-of-type(2) {
  display: none;
}

header .toogle-btn.active span:nth-of-type(3) {
  transform: rotate(-45deg);
  top: 20px;
}

header .toogle-btn span {
  position: absolute;
  top: 10px;
  width: 75%;
  height: 2px;
  background-color: #4d5471;
  left: 4px;
}

@media (max-width: 576px) {
  header .toogle-btn span {
    top: 7px;
  }
}

header .toogle-btn span:nth-of-type(2) {
  top: 18px;
}

@media (max-width: 576px) {
  header .toogle-btn span:nth-of-type(2) {
    top: 16px;
  }
}

header .toogle-btn span:nth-of-type(3) {
  top: 25px;
}

@media (max-width: 576px) {
  header .toogle-btn span:nth-of-type(3) {
    top: 24px;
  }
}

header.dashboard-header {
  background-color: navy;
}

header.dashboard-header figure .white-logo {
  display: block;
}

header.dashboard-header figure .blue-logo {
  display: none;
}

header.dashboard-header li a {
  color: #fff;
}

header.dashboard-header .loginName {
  color: #fff;
  letter-spacing: 0.1px;
  font-weight: 700;
  position: relative;
  padding-right: 20px;
}

footer li i,
footer li:hover a {
  color: #ff8c00;
}

footer li a,
footer li:hover i {
  color: #4d5471;
}

header.dashboard-header .loginName:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0;
  border-color: #fff transparent transparent;
  right: 0;
  top: 50%;
  left: auto;
  transform: translateY(-50%);
}

.blog-list h3:after,
.buying-advice li:before,
.explore-section figure .figcaption h4:after,
.explore-section figure:before,
footer .bottom-links li:after,
footer h6:after {
  content: "";
}

footer {
  background-color: #f9f9f9;
  padding: 50px 0 40px;
}

footer .footer-logo {
  margin: 10px 0 40px;
  width: 202px;
  height: 33.33px;
}

footer .col {
  width: 25%;
}

footer .col:nth-of-type(1) {
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  footer .col {
    width: 50%;
  }

  footer .col:nth-of-type(2) {
    margin-bottom: 30px;
  }
}

footer h6 {
  font-weight: 500;
  font-size: 19px;
  line-height: 1.25em;
  color: #303030;
  padding-bottom: 13px;
  position: relative;
}

/* footer h6:after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 80px;
  height: 1px;
  background-color: #ff8c00;
} */

footer ul {
  margin-top: 17px;
}

footer li {
  margin-bottom: 8px;
}

footer li i {
  font-size: 14px !important;
  padding-right: 10px;
  transition: all 0.25s ease;
}

footer li a {
  font-size: 14px;
  line-height: 24px;
  font-family: Lato, sans-serif;
  font-weight: 400;
  transition: all 0.25s ease;
}

footer .btns,
footer .btns_member {
  font-size: 15px;
  line-height: 1.25em;
  font-family: Lato, sans-serif;
  transition: all 0.25s ease;
}

footer .btns {
  border-radius: 5px;
  border: 2px solid #ff8c00;
  padding: 12px 24px;
  color: #ff8c00;
  font-weight: 400;
  margin-top: 10px;
}

footer .btns:hover,
footer .btns_member {
  color: #fff;
  background-color: #ff8c00;
}

footer .btns_member {
  border-radius: 5px;
  border: 2px solid #ff8c00;
  padding: 8px 24px;
  font-weight: 400;
  width: 90%;
  text-align: center;
}

.contact-popup .head h3,
.home-service h3,
footer .copyright p {
  font-family: Effra-Regular;
  font-weight: 400;
  line-height: 1.25em;
}

@media (max-width: 576px) {
  footer .btns_member {
    padding: 8px 20px;
    width: 100%;
  }
}

footer .btns_member:hover {
  background-color: #fff;
  color: #ff8c00;
}

footer .wrapper {
  padding: 10px 0 50px;
}

footer .copyright {
  padding: 10px;
}

footer .copyright p {
  margin-bottom: 20px;
  color: #303030;
  font-size: 14px;
  text-align: center;
}

footer .social-icons li {
  margin: 0 7px;
}

footer .social-icons a {
  width: 42px;
  height: 42px;
  border: 2px solid #080754;
  border-radius: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}

footer .social-icons a:hover {
  border: 2px solid #ff8c00;
}

footer .social-icons a:hover i {
  color: #ff8c00;
}

footer .social-icons i {
  color: #080754;
  font-size: 21px !important;
  padding-right: 0;
}

footer .bottom-links {
  justify-content: center;
  margin-top: 30px;
}

footer .bottom-links li {
  padding: 0 6px;
  position: relative;
}

footer .bottom-links li:after {
  position: absolute;
  right: 0;
  top: 7px;
  width: 1px;
  height: 12px;
  background-color: #4d5471;
}

.contact-popup .close,
.getInTouch-popup .close {
  border-radius: 50%;
  right: -25px;
  transition: all 0.25s ease;
  cursor: pointer;
}

footer .bottom-links li:last-of-type:after {
  display: none;
}

footer .bottom-links li a {
  font-size: 12px;
  line-height: 1.25em;
}

.contact-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 999;
  overflow-y: scroll;
  display: none;
}

.explore-section,
.home-service,
.login-section {
  background-image: url(../../assets/images/bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.contact-popup.active {
  display: block;
}

.contact-popup .wrapper {
  width: 500px;
  background-color: #fff;
  position: relative;
  margin: 40px auto 20px;
  padding: 0;
}

@media (max-width: 576px) {
  .contact-popup .wrapper {
    width: calc(100% - 40px);
    margin: 40px 20px;
  }
}

.contact-popup .close {
  background-color: #ff8c00;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -25px;
}

.contact-popup .close i {
  color: #fff;
}

.contact-popup .close:hover {
  background-color: #006994;
}

.contact-popup .head {
  background-color: #e4e4e4;
  text-align: center;
  padding: 15px 25px;
}

.contact-popup .head h3 {
  color: #4d5471;
}

.contact-popup .content {
  background-color: #fff;
  padding: 40px;
}

@media (max-width: 576px) {
  .contact-popup .close {
    right: -10px;
    width: 40px;
    height: 40px;
  }

  .contact-popup .content {
    padding: 30px;
  }
}

.home-service {
  padding: 100px 0;
  /* z-index: -1;
  position: relative; */
}

@media (max-width: 767px) {
  .home-service {
    padding: 50px 0;
  }
}

.home-service li {
  padding: 40px 46px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  width: calc(33.33% - 13.33px);
  margin-right: 20px;
  border-radius: 5px;
}

@media (max-width: 576px) {
  .home-service li {
    padding: 40px;
  }
}

.home-service li:nth-of-type(3n) {
  margin-right: 0;
}

@media (max-width: 767px) {
  .home-service li {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .home-service li:last-of-type {
    margin-bottom: 0;
  }
}

.home-service li a {
  text-align: center;
}

.home-service figure {
  margin: 0 auto 15px;
  width: 72px;
  height: 72px;
}

.home-service h3 {
  color: #303030;
  margin: 1em 0;
  display: inherit;
}

.home-service p {
  color: #4d5471;
  font-size: 17px;
  line-height: 1.7em;
  font-family: Lato, sans-serif;
  font-weight: 400;
  letter-spacing: 0.1px;
  margin-bottom: 20px;
}

.who-we-are .accordian h4,
.who-we-are .tab li {
  font-family: Effra-Medium;
  font-weight: 400;
}

.who-we-are {
  background-color: #d2e3e6;
  padding: 100px 0;
}

.who-we-are .col:first-of-type {
  width: 20%;
}

@media (max-width: 767px) {
  .who-we-are {
    padding: 50px 0;
  }

  .who-we-are .heading-line {
    margin-bottom: 10px;
  }

  .who-we-are .col:first-of-type {
    width: 100%;
  }
}

.who-we-are .col:nth-of-type(2) {
  width: 80%;
}

.who-we-are .tab {
  display: flex;
}

@media (max-width: 767px) {
  .who-we-are .col:nth-of-type(2) {
    width: 100%;
  }

  .who-we-are .tab {
    display: none;
  }
}

.who-we-are .tab li {
  cursor: pointer;
  padding: 20px 25px;
  color: #303030;
  font-size: 14px;
  line-height: 1.25em;
}

@media (max-width: 1200px) {
  .who-we-are .tab li {
    padding: 20px 19px;
  }
}

@media (max-width: 1024px) {
  .who-we-are .tab li {
    padding: 20px 12px;
  }
}

.who-we-are .tab li.active {
  color: #ff8c00;
}

.who-we-are .tab-wrapper {
  padding-top: 20px;
  padding-left: 20px;
  display: block;
}

.who-we-are .accordian,
.who-we-are .tab-wrapper .tab-content {
  display: none;
}

.who-we-are .tab-wrapper h2 {
  line-height: 1.7em;
  font-size: 28px;
  font-weight: 400;
  font-family: Roboto, sans-serif;
  color: #303030;
}

@media (max-width: 1024px) {
  .who-we-are .tab-wrapper h2 {
    font-size: 24px;
  }
}

@media (max-width: 767px) {
  .who-we-are .tab-wrapper {
    display: none;
  }

  .who-we-are .accordian {
    display: block;
  }
}

.who-we-are .accordian h4 {
  cursor: pointer;
  padding: 10px;
  color: #303030;
  font-size: 14px;
  line-height: 1.25em;
}

.who-we-are .accordian h2 {
  line-height: 1.7em;
  font-size: 18px;
  font-weight: 400;
  font-family: Roboto, sans-serif;
  color: #303030;
  padding: 10px;
}

.who-we-are .accordian .box.active .head h4 {
  color: #ff8c00;
}

.who-we-are .accordian .content {
  display: none;
}

.explore-section {
  padding: 100px 0;
}

.explore-section li {
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  width: calc(33.33% - 13.33px);
  margin-right: 20px;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .explore-section {
    padding: 50px 0;
  }

  .explore-section li {
    width: 100%;
    margin-right: 0;
  }
}

.explore-section li:nth-child(3n) {
  margin-right: 0;
}

.explore-section li:nth-of-type(4) {
  width: calc(50% - 10px);
}

.explore-section li:nth-of-type(4) figure {
  height: 378px;
}

@media (max-width: 992px) {
  .explore-section li:nth-of-type(4) figure {
    height: 340px;
  }
}

@media (max-width: 767px) {
  .explore-section li:nth-of-type(4) {
    margin-right: 0;
    width: 100%;
  }

  .explore-section li:nth-of-type(4) figure {
    height: 324px;
  }
}

@media (max-width: 576px) {
  .explore-section li:nth-of-type(4) figure {
    height: 210px;
  }
}

.explore-section li:nth-of-type(5) {
  width: calc(50% - 10px);
  margin-right: 0;
}

.explore-section li:nth-of-type(5) figure {
  height: 378px;
}

@media (max-width: 992px) {
  .explore-section li:nth-of-type(5) figure {
    height: 340px;
  }
}

@media (max-width: 767px) {
  .explore-section li:nth-of-type(5) {
    margin-right: 0;
    width: 100%;
  }

  .explore-section li:nth-of-type(5) figure {
    height: 324px;
  }

  .explore-section .heading-line a {
    width: auto;
  }
}

@media (max-width: 576px) {
  .explore-section li:nth-of-type(5) figure {
    height: 210px;
  }
}

.explore-section a {
  border-radius: 5px;
  height: 100%;
}

.explore-section figure {
  width: 100%;
  height: 213px;
  border-radius: 5px;
  position: relative;
}

@media (max-width: 767px) {
  .explore-section a {
    width: 100%;
  }

  .explore-section figure {
    height: 324px;
  }
}

@media (max-width: 576px) {
  .explore-section figure {
    height: 210px;
  }
}

.explore-section figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.explore-section figure:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-image: linear-gradient(300deg, #00699400 0, #000 71%);
  opacity: 0.2;
  border-radius: 5px;
}

.explore-section figure .figcaption {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 2em;
}

.explore-section figure .figcaption h4 {
  padding: 0.5em 0;
  font-size: 20px;
  font-weight: 400;
  font-family: Effra-Medium;
  line-height: 1.7em;
  letter-spacing: 0.1px;
  color: #fff;
  position: relative;
}

.news-section h4,
.news-section h4 a,
.newsletter h3 {
  font-family: Effra-Regular;
  color: #303030;
  line-height: 1.25em;
}

@media (max-width: 576px) {
  .explore-section figure .figcaption h4 {
    padding: 0;
  }
}

.explore-section figure .figcaption h4:after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 3px;
  background-color: #fff;
  transition: all 0.25s ease;
}

.explore-section figure:hover .figcaption h4:after {
  width: 100%;
}

.newsletter {
  background-color: #d2e3e6;
  padding: 100px 0;
}

.newsletter h3 {
  width: calc(100% - 642px);
  font-weight: 400;
  font-size: 24px;
}

@media (max-width: 992px) {
  .newsletter h3 {
    width: calc(100% - 550px);
  }
}

@media (max-width: 767px) {
  .newsletter {
    padding: 50px 0;
  }

  .newsletter .container-sm {
    padding: 0;
  }

  .newsletter h3 {
    width: 100%;
    margin-bottom: 15px;
    text-align: center;
  }
}

.newsletter .newsletterForm {
  width: 642px;
  display: flex;
}

@media (max-width: 992px) {
  .newsletter .newsletterForm {
    width: 550px;
    padding-left: 30px;
  }
}

@media (max-width: 767px) {
  .newsletter .newsletterForm {
    width: 100%;
    padding-left: 0;
  }
}

.newsletter .wrapper {
  align-items: center;
}

.news-section {
  padding: 60px 0 30px;
}

.news-section li {
  width: calc(33.33% - 20px);
  margin-right: 30px;
  border-radius: 5px;
  border: 1px solid #e4e4e4;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  transition: all 0.35s ease;
  margin-bottom: 30px;
}

.news-section li:hover {
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
}

.news-section li:nth-of-type(3n) {
  margin-right: 0;
}

@media (max-width: 767px) {
  .news-section li {
    width: 100%;
    margin-right: 0;
  }

  .news-section li:nth-of-type(3n) {
    margin-bottom: 0;
  }
}

.news-section h4,
.news-section p {
  font-weight: 400;
  margin-bottom: 25px;
}

.news-section figure {
  width: 100%;
  height: 232px;
}

@media (max-width: 767px) {
  .news-section figure {
    height: 278px;
  }
}

@media (max-width: 576px) {
  .news-section figure {
    height: 175px;
  }
}

.news-section figure a,
.news-section figure img {
  width: 100%;
  height: 100%;
}

.news-section .content {
  padding: 30px 30px 25px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 280px);
}

.news-section h4 a {
  font-weight: 400;
}

.news-section .read_more,
.news-section p {
  font-family: Roboto, sans-serif;
}

.news-section p {
  font-size: 15px;
  line-height: 1.7em;
  color: #777;
  letter-spacing: 0.1px;
}

.news-section .read_more {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  color: #ff8c00;
  margin-top: auto;
}

.news-section .date {
  border-top: 1px solid #eaeaea;
  padding: 15px 30px;
  width: 100%;
}

.news-section .date p {
  font-family: Effra-Regular;
  font-weight: 400;
  line-height: 1.3em;
  font-size: 12px;
  color: #adadad;
  margin-bottom: 0;
}

.about-content p,
.blog-list .tag {
  font-family: Lato, sans-serif;
}

.about-content {
  padding: 85px 0;
}

@media (max-width: 992px) {
  .about-content {
    padding: 50px 0;
  }
}

.about-content .container-sm {
  max-width: 833px;
}

.about-content .wrapper {
  text-align: center;
}

.about-content p {
  margin-bottom: 1em;
  letter-spacing: 0.1px;
  line-height: 1.7em;
  font-weight: 400;
  font-size: 16px;
  color: #4d5471;
}

.about-service h3,
.about-service li h3 {
  color: #303030;
  font-weight: 400;
  font-family: Effra-Regular;
  line-height: 1.25em;
}

.about-content p:last-of-type {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .news-section .content {
    height: auto;
  }

  .about-content .container-sm,
  .about-service .container-sm {
    padding: 0 10px;
  }
}

.about-service h3 {
  text-align: center;
  margin-bottom: 50px;
  margin-top: 10px;
}

.about-service ul {
  padding-bottom: 100px;
}

@media (max-width: 992px) {
  .about-service ul {
    padding-bottom: 75px;
  }
}

@media (max-width: 767px) {
  .about-service ul {
    padding-bottom: 45px;
  }
}

.about-service li {
  width: calc(33.33% - 13.33px);
  margin-right: 20px;
  padding: 40px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  border-radius: 6px;
}

@media (max-width: 767px) {
  .about-service li {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.about-service li:nth-of-type(3n) {
  margin-right: 0;
}

.about-service li h3 {
  margin: 1em 0;
}

.about-service li figure {
  margin: 0 auto 15px;
  width: 72px;
  height: 72px;
}

.blog-list {
  padding: 80px 0;
  background-color: #f4f4f4;
}

.blog-list li {
  margin-bottom: 45px;
  background-color: #f6f6f6;
}

.blog-list .content,
.blog-list.second {
  background-color: #fff;
}

.blog-list figure {
  width: 50%;
  height: 375px;
}

@media (max-width: 992px) {
  .blog-list figure {
    height: 280px;
  }
}

@media (max-width: 767px) {
  .blog-list {
    padding: 50px 0;
  }

  .blog-list figure {
    width: 100%;
    height: auto;
  }
}

.blog-list figure a,
.blog-list figure img {
  height: 100%;
  width: 100%;
}

.blog-list figure img {
  object-fit: cover;
  border-radius: 10px;
}

.blog-list .content {
  width: 50%;
  padding: 30px;
  margin: 30px 30px 30px -30px;
  z-index: 1;
  border-radius: 10px;
  box-shadow: 0 1px 25px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 1px 25px rgba(0, 0, 0, 0.05);
  position: relative;
}

@media (max-width: 992px) {
  .blog-list .content {
    width: calc(50% + 20px);
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .blog-list .content {
    width: 100%;
    margin: 0;
    padding: 25px;
  }
}

.blog-list .tag {
  color: #006994;
  padding: 6px 10px;
  border-radius: 2px;
  background-color: #e4e4e4;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.25em;
  font-weight: 400;
}

.blog-list .tag:hover,
.blog-list h3 {
  color: #303030;
}

.blog-list h3,
.blog-list p {
  margin: 1em 0 20px;
  font-weight: 400;
}

.blog-list h3 {
  font-family: Effra-Regular;
  line-height: 1.25em;
  padding-bottom: 12px;
  position: relative;
}

.blog-list h3:after {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 25%;
  background-color: #006994;
}

.blog-list p {
  font-family: Lato, sans-serif;
  font-size: 14px;
  line-height: 1.7em;
  letter-spacing: 0.1px;
  padding-right: 10px;
  margin: 0;
  padding: 0;
}

.blog-list .date {
  line-height: 1.3em;
  font-size: 12px;
  color: #adadad;
  font-family: Effra-Medium;
  font-weight: 400;
  margin: 0;
  display: inline-flex;
  align-items: center;
  margin-top: 20px;
  position: absolute;
  left: 30px;
  bottom: 17px;
}

.blog-list .date i {
  padding-right: 5px;
  font-weight: 500;
}

.blogBanner {
  padding: 45px 0 50px;
}

@media (max-width: 767px) {
  .blogBanner {
    padding: 25px 0 20px;
  }
}

.blogBanner .container-sm {
  padding: 0;
}

@media (max-width: 1200px) {
  .blogBanner .container-sm {
    padding: 0 80px;
  }
}

@media (max-width: 992px) {
  .blogBanner .container-sm {
    padding: 0 40px;
  }
}

@media (max-width: 767px) {
  .blogBanner .container-sm {
    padding: 0 15px;
  }

  .blogBanner h1 {
    text-align: center;
  }
}

.blogBanner h1 {
  margin-bottom: 20px;
  padding: 10px;
  font-weight: 600;
  color: #303030;
  font-size: 27px;
  line-height: 1em;
  font-family: Effra-Bold;
}

.blogDetails h3,
.blogDetails p {
  font-family: Lato, sans-serif;
}

.blogBanner figure,
.blogBanner img {
  border-radius: 40px;
  width: 100%;
}

.blogBanner figure {
  height: 470px;
}

.blogBanner figure img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.blogDetails {
  padding-bottom: 35px;
}

.blogDetails .container-sm {
  max-width: 870px;
  padding: 0;
}

@media (max-width: 767px) {
  .blogBanner figure {
    height: 100%;
  }

  .blogDetails {
    padding-bottom: 0;
  }

  .blogDetails .container-sm {
    padding: 0 15px;
  }
}

.blogDetails .col:first-of-type {
  width: 11%;
  padding: 10px 30px 10px 10px;
}

@media (max-width: 767px) {
  .blogDetails .col:first-of-type {
    width: 100%;
    padding: 0;
  }
}

.blogDetails .col:nth-of-type(2) {
  width: 89%;
  padding: 10px;
}

.blogDetails li {
  margin-bottom: 25px;
  text-align: center;
}

@media (max-width: 767px) {
  .blogDetails .col:nth-of-type(2) {
    width: 100%;
    padding: 0;
  }

  .blogDetails ul {
    display: flex;
    justify-content: center;
  }

  .blogDetails li {
    margin-bottom: 0;
    margin-right: 10px;
    margin-left: 10px;
  }
}

.blogDetails li svg {
  width: 22px;
  height: 22px;
}

.blogDetails li svg path {
  fill: #006994;
}

.blogDetails li a:hover svg path {
  fill: #ff8c00;
}

.blogDetails h3,
.blogDetails p {
  margin: 1em 0;
}

.blogDetails p {
  color: #4d5471;
  font-size: 16px;
  line-height: 1.7em;
  font-weight: 400;
  letter-spacing: 0.1px;
}

.blogDetails p a {
  transition: all 0.25s ease;
  color: #006994;
}

.blogDetails p a:hover {
  color: #ff8c00;
}

.blogDetails h3 {
  font-weight: 700;
  color: #242429;
  line-height: 1.25em;
}

.blog-list.second .container-sm {
  padding: 10px;
}

@media (max-width: 992px) {
  .blog-list.second .container-sm {
    padding: 0 15px;
  }
}

.blog-list.second h2 {
  margin-bottom: 50px;
  padding-top: 35px;
  border-top: solid 1px #e4e4e4;
  color: #303030;
  font-weight: 400;
  font-size: 24px;
  line-height: 1em;
  font-family: Effra-Regular;
}

.blog-list.second .readMore {
  padding: 10px;
  background-color: #006994;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  border-radius: 3px;
  transition: all 0.3s;
  color: #fff;
  font-size: 15px;
  line-height: 1em;
}

.blog-list.second .readMore:hover {
  background-color: #ff8c00;
}

.comman-contents {
  padding: 60px 0;
  background-color: #f4f4f4;
}

@media (max-width: 767px) {
  .comman-contents {
    padding: 50px 0;
  }

  .comman-contents .center-heading h1 {
    text-align: center;
  }
}

.comman-contents h3 {
  margin: 1em 0;
  color: #242429;
  padding-top: 60px;
  font-family: Lato, sans-serif;
}

@media (max-width: 992px) {
  .comman-contents h3 {
    padding-top: 40px;
  }
}

@media (max-width: 767px) {
  .comman-contents h3 {
    font-size: 22px;
    padding-top: 25px;
  }
}

.comman-contents ol li,
.comman-contents p {
  font-size: 16px;
  line-height: 27px;
  font-family: Lato, sans-serif;
  font-weight: 400;
}

.comman-contents p {
  color: #4d5471;
  margin: 1em 0;
}

.comman-contents p a {
  color: #000;
}

.comman-contents p a:hover {
  color: #ff8c00;
}

.comman-contents ol {
  margin: 1em 0;
  padding-left: 50px;
}

.comman-contents ol li {
  list-style: decimal;
  color: #4d5471;
}

.list_box li {
  width: calc(50% - 10px);
  margin-right: 20px;
  margin-bottom: 20px;
}

@media (max-width: 576px) {
  .list_box li {
    width: 100%;
    margin-right: 0;
  }
}

.list_box li:nth-of-type(2n) {
  margin-right: 0;
}

.list_box li a {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid #e4e4e4;
  padding: 20px;
  background-color: #fff;
  text-align: center;
}

.list_box li a i {
  color: #303030;
  font-size: 40px;
  transition: all 0.25s ease;
}

.list_box li a:hover h5,
.list_box li a:hover i {
  color: #ff8c00;
}

.list_box li h5,
.login-section h3 {
  color: #303030;
  font-family: Effra-Regular;
}

.list_box li h5 {
  margin-top: 15px;
  font-size: 24px;
  line-height: 1.2em;
  font-weight: 400;
  transition: all 0.25s ease;
}

.login-section {
  padding: 70px 0;
}

@media (max-width: 767px) {
  .login-section {
    padding: 50px 0;
  }
}

.login-section .container-sm {
  max-width: 600px;
}

.login-section .wrapper {
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 20px 25px 40px;
  background-color: #fff;
}

@media (max-width: 767px) {
  .login-section .wrapper {
    padding: 20px 20px 40px;
  }
}

.login-section h3 {
  font-weight: 400;
  margin-bottom: 20px;
}

.login-section a,
.login-section p {
  color: #4d5471;
  line-height: 1.7em;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.1px;
}

.login-section a,
.login-section p,
.login-section.signUp p a,
.otherLogin .content p {
  font-family: Lato, sans-serif;
}

.login-section p {
  padding-bottom: 30px;
  border-bottom: 1px solid #eee;
}

.login-section a:hover {
  color: #ff8c00;
}

.login-section.signUp p a {
  font-weight: 600;
}

.otherLogin {
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 20px;
}

@media (max-width: 767px) {
  .otherLogin {
    justify-content: center;
  }

  .otherLogin li:first-of-type {
    margin-bottom: 15px;
  }
}

.otherLogin a {
  display: flex;
  background-color: #4285f4;
  border-radius: 3px;
  padding: 1px;
  min-width: 235px;
  align-items: center;
}

.otherLogin .icon {
  width: 38px;
  height: 38px;
  background-color: #fff;
  border-radius: 3px;
  padding: 7px;
}

.otherLogin .content {
  padding-left: 12px;
}

.otherLogin .content p {
  border: none;
  padding: 0;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #fff;
}



.otherLogin li:nth-of-type(2) a svg {
  width: 100%;
  height: 100%;
}

.login-section.registration .container-sm {
  max-width: 780px;
}

.login-section.registration h2 {
  font-size: 28px;
  font-weight: 400;
  text-align: center;
  font-family: Effra-Regular;
  color: #303030;
}

.login-section.registration .user-login {
  display: flex;
  flex-wrap: wrap;
}

.login-section.registration .user-login .form_group {
  width: calc(50% - 10px);
  margin-right: 20px;
}

.login-section.registration .user-login .form_group:nth-of-type(2n) {
  margin-right: 0;
}

.login-section.registration .user-login .form_group:last-of-type,
.login-section.registration .user-login .form_group:nth-last-child(2) {
  width: 100%;
}

.login-section.registration .user-login button {
  width: auto;
  padding: 12px 40px;
}

.login-section.registration .user-login textarea {
  width: 100%;
  border: 1px solid #efefef;
  padding: 12px 15px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4em;
  color: #666;
  border-radius: 4px;
  min-height: 190px;
}

.login-section.registration .user-login textarea:focus,
.login-section.registration .user-login textarea:hover {
  outline: 0;
}

.user-dashboard {
  position: relative;
}

.user-dashboard .left-sidebar {
  background-color: #f4f4f4;
  padding-left: 20px;
  width: 25%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

@media (max-width: 1200px) {
  .user-dashboard .left-sidebar {
    width: 100%;
    position: static;
    padding-left: 0;
    padding-top: 25px;
  }
}

.user-dashboard .left-sidebar ul {
  background-color: #f8f9fb;
  padding: 30px 20px 20px;
  height: 100%;
}

.user-dashboard .left-sidebar li {
  margin-bottom: 5px;
}

.user-dashboard .left-sidebar li:not(:last-of-type) a svg path {
  /* fill: #b2b2b2; */
}

.request-view-slider .slick-arrow svg path,
.user-dashboard .left-sidebar li.active a svg path {
  /* fill: #303030; */
}

.user-dashboard .left-sidebar li.active a {
  border-left: solid 4px #ff8c00;
}

@media (max-width: 1200px) {
  .user-dashboard .left-sidebar ul {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
  }

  .user-dashboard .left-sidebar li.active a {
    border-left: none;
    border-bottom: solid 4px #ff8c00;
  }
}

.user-dashboard .left-sidebar a {
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 1.25em;
  color: #4d5471;
  font-weight: 400;
  font-family: Lato, sans-serif;
  padding: 10px 0 10px 10px;
}

.user-dashboard .left-sidebar a:hover {
  color: #ff8c00;
}

.user-dashboard .left-sidebar a svg {
  margin-right: 7px;
  width: 28px;
}

@media (max-width: 1200px) {
  .user-dashboard .left-sidebar a {
    padding: 5px 5px 14px;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
  }

  .user-dashboard .left-sidebar a svg {
    margin-right: 0;
    margin-bottom: 8px;
  }
}

.user-dashboard .main-content {
  width: calc(100% - 25%);
  padding: 30px;
  margin-left: auto;
}

@media (max-width: 1200px) {
  .user-dashboard .main-content {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .user-dashboard .left-sidebar a {
    font-size: 0;
  }

  .user-dashboard .left-sidebar a svg {
    margin-bottom: 0;
  }

  .user-dashboard .main-content {
    padding: 20px 15px;
  }
}

.user-dashboard .main-content .main-wrapper {
  display: none;
}

.user-dashboard .addProp-btn {
  text-align: right;
}

.user-dashboard .addProp-btn a {
  border-radius: 5px;
  font-family: Effra-Regular;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4em;
  letter-spacing: 0;
  background-color: #ff8c00;
  border: 1px solid transparent;
  color: #fff;
  padding: 12px 30px;
  cursor: pointer;
  transition: all 0.25s ease;
}

.user-dashboard .addProp-btn a:hover {
  background-color: #006994;
}

.available-section {
  padding: 30px 0 20px;
}

.available-section li {
  width: calc(33.33% - 20px);
  margin-right: 30px;
  border-radius: 4px;
  border: 1px solid #006994;
  padding: 25px;
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .available-section li {
    width: calc(50% - 20px);
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .available-section li {
    width: 100%;
    margin-right: 0;
  }
}

.available-section li:nth-of-type(3n) {
  margin-right: 0;
}

@media (max-width: 767px) {
  .available-section li:nth-of-type(3n) {
    margin-right: 30px;
  }

  .available-section li:nth-of-type(2n) {
    margin-right: 0;
  }
}

@media (max-width: 576px) {
  .available-section li:nth-of-type(3n) {
    margin-right: 0;
  }
}

.available-section p {
  color: #4d5471;
  font-weight: 400;
  line-height: 1.4em;
  font-size: 16px;
  font-family: Effra-Medium;
  width: calc(100% - 90px);
}

.available-section h2,
.availableProperty h2,
.contact-info a,
.contact-info p {
  font-family: Lato, sans-serif;
}

.available-section h2 {
  width: 90px;
  text-align: right;
  font-size: 46px;
  color: #000;
  font-weight: 600;
}

.service-sec {
  padding: 10px 0 30px;
}

.service-sec li {
  background-color: #f4f4f4;
  border-radius: 4px;
  width: calc(33.33% - 20px);
  margin-right: 30px;
  padding: 35px 20px 35px 35px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

@media (max-width: 767px) {
  .service-sec li {
    width: calc(50% - 20px);
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .service-sec li {
    width: 100%;
    margin-right: 0;
  }
}

.service-sec li:nth-of-type(3n) {
  margin-right: 0;
}

@media (max-width: 767px) {
  .service-sec li:nth-of-type(3n) {
    margin-right: 30px;
  }

  .service-sec li:nth-of-type(2n) {
    margin-right: 0;
  }
}

@media (max-width: 576px) {
  .service-sec li:nth-of-type(3n) {
    margin-right: 0;
  }
}

.service-sec svg {
  filter: drop-shadow(-40px 4px 1px #ebebeb);
}

.service-sec p {
  color: #4d5471;
  font-weight: 400;
  line-height: 1.4em;
  font-size: 16px;
  font-family: Effra-Medium;
  padding-left: 25px;
}

.contact-info {
  padding: 35px 0 10px;
}

.contact-info .col {
  width: 50%;
}

@media (max-width: 767px) {
  .contact-info {
    padding: 10px;
  }

  .contact-info .col {
    width: 100%;
  }

  .contact-info .col:first-of-type {
    margin-bottom: 20px;
  }
}

.contact-info .col:first-of-type a {
  color: #ff8c00;
}

.contact-info h2 {
  color: #000;
  font-size: 24px;
  line-height: 1.25em;
  margin-bottom: 15px;
}

.contact-info li,
.contact-info p {
  margin-bottom: 7px;
}

.contact-info a,
.contact-info p {
  color: #4d5471;
  font-weight: 400;
  line-height: 1.4em;
  font-size: 16px;
}

.contact-info i {
  margin-right: 10px;
}

.contact-info a:hover {
  color: #ff8c00;
}

.availableProperty h2 {
  font-size: 30px;
  font-weight: 800;
  letter-spacing: 1px;
  width: 100%;
  margin: 10px 0;
  color: #303030;
}

@media (max-width: 767px) {
  .availableProperty h2 {
    font-size: 24px;
  }
}

.availableProperty .tableFilter {
  justify-content: space-between;
  align-items: center;
}

.availableProperty .tableFilter .dataTables_length {
  align-items: center;
  color: #666;
  font-size: 14px;
  line-height: 1.25em;
  font-family: Lato, sans-serif;
  width: calc(100% - 410px);
}

.availableProperty .tableFilter .searchFilter input,
.availableProperty .tableFilter label,
.availableProperty .tableFilter select,
.availableProperty .tablesorter-blue .tablesorter-header,
.availableProperty .tablesorter-blue td {
  font-size: 16px;
  font-family: Lato, sans-serif;
}

@media (max-width: 767px) {
  .availableProperty .tableFilter .dataTables_length {
    width: 100%;
  }
}

.availableProperty .tableFilter label {
  color: #666;
  margin-bottom: 0;
  font-weight: 400;
}

.availableProperty .tableFilter select {
  color: #666;
  line-height: 1.25em;
  background: #fff;
  border: 1px solid #e8e8e8;
  margin: 0 5px;
  border-radius: 3px;
  padding: 4px;
  cursor: pointer;
}

.availableProperty .tableFilter select option {
  cursor: pointer;
}

.availableProperty .tableFilter .searchFilter {
  width: 410px;
}

@media (max-width: 767px) {
  .availableProperty .tableFilter .searchFilter {
    width: 100%;
    margin-top: 20px;
  }
}

.availableProperty .tableFilter .searchFilter input {
  width: 100%;
  outline: 0;
  padding: 10px;
  color: #666;
  background: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  line-height: 1.25em;
}

.availableProperty .tableWrap {
  background: #fff;
  padding: 15px 35px;
  border: 0;
  margin-top: 10px;
  min-width: 810px;
  width: 100%;
}

@media (max-width: 992px) {
  .availableProperty .tableWrap {
    padding: 15px;
    min-width: 100%;
  }

  .buying-advice li h4 {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .availableProperty .tableWrap {
    padding: 10px 0;
    overflow-x: scroll;
  }
}

.availableProperty .tableWrap table {
  width: 700px;
}

.availableProperty .tablesorter-blue {
  border: none;
}

.availableProperty .tablesorter-blue .tablesorter-header {
  color: #303030;
  font-weight: 400;
  padding: 10px 18px;
  border: none;
  border-bottom: 1px solid #e9e9e9;
  background-color: #fff;
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: center right;
}

.availableProperty .tablesorter-blue .tablesorter-header.tablesorter-headerUnSorted {
  background-image: url(../../assets/images/up-down.svg);
}

.availableProperty .tablesorter-blue .tablesorter-header.tablesorter-headerAsc {
  background-image: url(../../assets/images/up.svg);
}

.availableProperty .tablesorter-blue .tablesorter-header.tablesorter-headerDesc {
  background-image: url(../../assets/images/down.svg);
}

.availableProperty .tablesorter-blue td {
  padding: 10px 16px;
  color: #666;
  border: 0;
  background: #fff;
  font-weight: 400;
}

.dataTables_info {
  padding: 15px 0;
  font-size: 14px;
  line-height: 1.25em;
  color: #666;
  font-weight: 400;
  font-family: Lato, sans-serif;
}

.filterDownload input,
.filterDownload p {
  font-size: 16px;
  color: #666;
  font-family: Lato, sans-serif;
}

.filterDownload {
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 30px;
}

.filterDownload form {
  display: flex;
}

@media (max-width: 767px) {
  .filterDownload form {
    width: 100%;
  }
}

.filterDownload .form_group:first-of-type {
  width: 410px;
}

@media (max-width: 767px) {
  .filterDownload .form_group:first-of-type {
    width: 100%;
  }
}

.filterDownload .form_group:nth-of-type(2) {
  width: 55px;
  padding-left: 15px;
}

.filterDownload input {
  width: 100%;
  outline: 0;
  padding: 10px;
  background: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  line-height: 1.25em;
}

.filterDownload button {
  border-radius: 2px;
  border: none;
  background-color: #ff8c00;
  width: 100%;
  height: 40px;
  cursor: pointer;
  transition: all 0.25s ease;
}

.filterDownload button:hover {
  background-color: #006994;
}

.filterDownload button svg {
  width: 25px;
  height: 25px;
  stroke: #fff;
}

.filterDownload p {
  text-transform: capitalize;
  padding-right: 15px;
  font-weight: 400;
}

.buying-advice li h4,
.getInTouch h2 {
  font-weight: 400;
  font-family: Effra-Regular;
}

.buying-advice {
  padding: 100px 0;
}

@media (min-width: 1450px) {
  .buying-advice {
    height: 100vh;
  }
}

.buying-advice li {
  width: calc(33.33% - 13.33px);
  margin-right: 20px;
  height: 280px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  position: relative;
  border-radius: 10px;
}

.buying-advice li .content,
.buying-advice li:before {
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
}

.buying-advice li:nth-of-type(3n) {
  margin-right: 0;
}

@media (max-width: 767px) {
  .buying-advice {
    padding: 70px 0;
  }

  .buying-advice li {
    margin-right: 0;
    margin-bottom: 20px;
    width: 100%;
  }

  .buying-advice li:last-of-type {
    margin-bottom: 0;
  }
}

.buying-advice li:before {
  background-color: #07070761;
  border-radius: 10px;
}

.buying-advice li figure {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.buying-advice li figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.buying-advice li .content {
  padding: 35px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.buying-advice li h4 {
  color: #fff;
  margin-bottom: 15px;
  font-size: 24px;
  line-height: 1.25em;
}

.buying-advice li a {
  border: 1px solid #ff8c00;
  background-color: #ff8c00;
  font-size: 15px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  padding: 12px 24px;
  border-radius: 3px;
  line-height: 1.2em;
  color: #fff;
  transition: all 0.25s ease;
}

.buying-advice li a:hover {
  background-color: #006994;
  border: 1px solid #006994;
}

.why-lists .wrapper {
  padding: 80px;
}

@media (max-width: 992px) {
  .why-lists .wrapper {
    padding: 50px 0;
  }
}

@media (max-width: 767px) {
  .why-lists .wrapper {
    padding: 40px 0;
  }
}

.why-lists .wrapper:nth-of-type(odd) {
  background-color: #fff;
}

.why-lists .wrapper:nth-of-type(even) {
  background-color: #f6f6f6;
}

.why-lists .container-sm {
  align-items: center;
}

.why-lists .col {
  width: 50%;
  padding: 0 30px;
}

@media (max-width: 767px) {
  .why-lists .col {
    width: 100%;
    padding: 15px 5px;
  }

  .getInTouch h2 {
    text-align: center;
  }
}

.why-lists figure,
.why-lists figure img {
  border-radius: 10px;
}

.why-lists p {
  margin-bottom: 1.1em;
  color: #4d5471;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7em;
  letter-spacing: 0.1px;
}

.getInTouch {
  padding: 60px 0;
  background-color: #d2e3e6;
}

.getInTouch .container-sm {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.getInTouch h2 {
  margin-bottom: 30px;
  color: #303030;
  font-size: 24px;
  line-height: 1.2em;
}

.account-edit h5,
.account-edit.lost_password h5,
.account-edit.lost_password p,
.bookmark-section li .bookmark-content .top h5:first-of-type a,
.bookmark-section li .bookmark-content .top h5:nth-of-type(2) a,
.getInTouch a {
  font-family: Lato, sans-serif;
}

.getInTouch a {
  font-weight: 500;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.25em;
  padding: 15px 30px;
  background-color: #ff8c00;
  color: #fff;
  transition: all 0.25s ease;
}

.getInTouch a:hover {
  background-color: #006994;
}

.getInTouch-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 999;
  overflow-y: scroll;
  display: none;
}

.getInTouch-popup.active {
  display: block;
}

.getInTouch-popup .wrapper {
  width: 500px;
  background-color: #fff;
  position: relative;
  margin: 40px auto 20px;
}

@media (max-width: 576px) {
  .getInTouch-popup .wrapper {
    width: calc(100% - 40px);
    margin: 40px 20px;
  }
}

.getInTouch-popup .close {
  background-color: #ff8c00;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -25px;
}

.getInTouch-popup .close i {
  color: #fff;
}

.getInTouch-popup .close:hover {
  background-color: #006994;
}

.getInTouch-popup .head {
  background-color: #e4e4e4;
  text-align: center;
  padding: 15px 25px;
}

.getInTouch-popup .head h3 {
  color: #4d5471;
  font-weight: 400;
  font-family: Effra-Regular;
  line-height: 1.25em;
}

.getInTouch-popup .content {
  background-color: #fff;
  padding: 40px;
}

@media (max-width: 576px) {
  .getInTouch-popup .close {
    right: -10px;
    width: 40px;
    height: 40px;
  }

  .getInTouch-popup .content {
    padding: 30px;
  }
}

.account-edit {
  background-color: #f4f4f4;
  padding: 50px 0;
}

.account-edit .wrapper {
  max-width: 550px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  padding: 20px;
  background-color: #fff;
  margin: 0 auto;
}

.account-edit .head {
  align-items: center;
  margin-bottom: 15px;
}

.account-edit .icon {
  background-color: #ff8c00;
  border-radius: 50%;
  margin-right: 15px;
}

.account-edit h5 {
  color: #242429;
  font-size: 14px;
  line-height: 20px;
}

.account-edit.lost_password .wrapper {
  background: #fff;
  padding: 20px;
  margin-bottom: 25px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  max-width: 370px;
}

.account-edit.lost_password .head {
  margin: 30px 0 20px;
  padding-bottom: 25px;
}

.account-edit.lost_password .icon {
  margin-right: 9px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.account-edit.lost_password h5 {
  font-size: 24px;
  color: #242429;
}

.account-edit.lost_password p {
  color: #242429;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  margin-bottom: 31px;
}

.account-edit.lost_password .editProfile label {
  color: #4d5471;
}

.account-edit.lost_password .editProfile input {
  border: 1px solid #eee;
  border-radius: 3px;
  padding: 14px 10px;
  margin-top: 7px;
  font-weight: 400;
}

.account-edit.lost_password .editProfile .form_group:nth-of-type(1) {
  margin-bottom: 20px;
}

.account-edit.lost_password .editProfile button {
  padding: 14px 40px 12px;
  font-size: 18px;
}

.bookmark-section {
  padding: 50px 0;
  background-color: #f4f4f4;
}

.bookmark-section li {
  width: calc(33.3% - 15px);
  margin-right: 23px;
  margin-bottom: 25px;
  position: relative;
}

@media (max-width: 1024px) {
  .bookmark-section li {
    width: calc(33.3% - 11.5px);
  }
}

@media (max-width: 992px) {
  .bookmark-section li {
    width: calc(50% - 16px);
  }
}

@media (max-width: 767px) {
  .bookmark-section li {
    width: 100%;
    margin-right: 0;
  }
}

.bookmark-section li:nth-of-type(3n) {
  margin-right: 0;
}

@media (max-width: 992px) {
  .bookmark-section li:nth-of-type(3n) {
    margin-right: 23px;
  }

  .bookmark-section li:nth-of-type(2n) {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .bookmark-section li:nth-of-type(3n) {
    margin-right: 0;
  }

  .bookmark-section li:last-of-type {
    margin-bottom: 0;
  }

  .saved-searches .listing-search {
    overflow-x: scroll;
  }
}

.bookmark-section li .bookmark-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background-color: transparent;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.bookmark-section li .bookmark-content .top {
  justify-content: space-between;
}

.bookmark-section li .bookmark-content .top h5:first-of-type a {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background: #f88c03;
  padding: 10px 20px;
  border-radius: 8px;
  transition: all 0.25s ease;
}

.bookmark-section li .bookmark-content .top h5:nth-of-type(2) a {
  font-size: 14px;
  font-weight: 700;
  color: #000;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  transition: all 0.25s ease;
}

.bookmark-section li .bookmark-content .top a:hover {
  transform: translateY(-4px);
}

.bookmark-section li .bookmark-content .bottom {
  justify-content: center;
  margin-top: auto;
}

.bookmark-section li .bookmark-content .bottom a {
  margin: 0 15px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ff8c00;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all o 0.25s ease;
}

.bookmark-section li .bookmark-content .bottom a:hover {
  transform: translateY(-4px);
}

/* .bookmark-section li:hover .slick-arrow {
  opacity: 0.5;
}
.bookmark-section .bookmark-slider {
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
  height: 350px;
}
@media (max-width: 576px) {
  .bookmark-section .bookmark-slider {
    height: 320px;
  }
}
.bookmark-section .bookmark-slider figure {
  border-radius: 15px;
  height: 100%;
}
.bookmark-section .bookmark-slider figure img {
  border-radius: 15px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.bookmark-section .bookmark-slider .slick-list,
.bookmark-section .bookmark-slider .slick-slide,
.bookmark-section .bookmark-slider .slick-track {
  height: 100%;
}
.bookmark-section .bookmark-slider .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  width: 50px;
  z-index: 1;
  opacity: 0;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(0, 0, 0, 0)),
    to(#262637)
  );
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0), #262637);
  font-size: 0;
  border-radius: 15px 0 0 15px;
  transition: all 0.25s ease;
}
.bookmark-section .bookmark-slider .slick-arrow:before {
  font-family: fontawesome;
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #000;
  content: "\f104";
  cursor: pointer;
  position: absolute;
  left: 20px;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
}
.bookmark-section .bookmark-slider .slick-arrow:after {
  content: "";
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-color: #fff;
  opacity: 0.5;
  border-radius: 50%;
  cursor: pointer;
}
.bookmark-section .bookmark-slider .slick-arrow.slick-next {
  left: auto;
  right: 0;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 0)),
    to(#262637)
  );
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0), #262637);
  border-radius: 0 15px 15px 0;
}
.bookmark-section .bookmark-slider .slick-arrow.slick-next:before {
  content: "\f105";
  left: auto;
  right: 20px;
}
.bookmark-section .bookmark-slider .slick-arrow.slick-next:after {
  left: auto;
  right: 15px;
} */




.bookmark-slider .slick-arrow {
  z-index: 12 !important;
}

.bookmark-slider .slick-next {
  right: 1rem !important;
}

.bookmark-slider .slick-prev {
  left: 14px !important;
}


.bookmark-section .bookmark-slider figure {
  border-radius: 15px;
  height: 100%;
}

.bookmark-section .bookmark-slider figure img {
  border-radius: 15px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.map_setpostion {
  position: relative;
  width: 100%;
  height: 360px;
}


.saved-searches {
  background-color: #f4f4f4;
  padding: 0.67em 0 30px;
}

.saved-searches h1 {
  font-weight: 700;
  font-size: 27px;
  line-height: 1.25em;
  color: #242429;
  font-family: Lato, sans-serif;
}

.saved-searches .listing-search #alert-fq thead th,
.saved-searches p {
  font-size: 14px;
  font-family: Lato, sans-serif;
}

.saved-searches p {
  line-height: 26px;
  color: #242429;
  margin: 0 0 20px;
  font-weight: 400;
}

.saved-searches .listing-search {
  background: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
}

.saved-searches .listing-search .table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  color: #484848;
  background: 0 0;
}

@media (max-width: 767px) {
  .saved-searches .listing-search .table {
    width: 800px;
    max-width: unset;
  }

  .fillter-section div#bookmarks {
    overflow-x: scroll;
  }
}

.saved-searches .listing-search #alert-fq {
  border-spacing: 0 20px;
  border-collapse: separate;
}

.saved-searches .listing-search #alert-fq thead th {
  border-bottom: 1px solid #eee;
  vertical-align: bottom;
  padding: 8px;
  line-height: 1.42857143;
  text-align: left;
  font-weight: 700;
}

.fillter-section .pricesearch .price-btn,
.fillter-section .searchSelect,
.saved-searches .listing-search #alert-fq tbody td {
  font-size: 14px;
  font-family: Lato, sans-serif;
  font-weight: 400;
}

.saved-searches .listing-search #alert-fq tbody tr {
  box-shadow: 0 5px 18px rgba(0, 0, 0, 0.08);
  border-radius: 100px;
}

.saved-searches .listing-search #alert-fq tbody td {
  border-color: transparent;
  padding: 10px 20px;
  vertical-align: middle;
  line-height: 1.42857143;
}

.saved-searches .listing-search #alert-fq tbody select {
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -webkit-transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
}

.saved-searches .listing-search #alert-fq tbody .frq-link {
  background: #ff8c00;
  padding: 4px 10px;
  border-radius: 5px;
  font-size: 13px;
  color: #fff;
  font-weight: 600;
  transition: all 0.25s ease;
}

.saved-searches .listing-search #alert-fq tbody .frq-link:hover {
  background-color: #006994;
}

.saved-searches .listing-search #alert-fq tbody .frqDeleteBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: 1px solid #ddd;
  border-radius: 50%;
  background: #eee;
  cursor: pointer;
  transition: all 0.25s ease;
}

.saved-searches .listing-search #alert-fq tbody .frqDeleteBtn:hover {
  background-color: #ff8c00;
  border: 1px solid #ff8c00;
}

.saved-searches .listing-search #alert-fq tbody .frqDeleteBtn:hover svg {
  stroke: #fff;
}

.saved-searches .create-search {
  background: #fff;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  border-radius: 10px;
  margin: 10px 0 30px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
}

.saved-searches .create-search a {
  font-weight: 600;
  text-decoration: underline;
  color: #333;
  font-family: Lato, sans-serif;
  font-size: 14px;
  line-height: 26px;
}

.fillter-section {
  padding: 20px 15px;
  background: #fff;
  border-top: 1px solid #ddd;
  position: relative;
}

.fillter-section .container_ {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.fillter-section form {
  display: flex;
  margin: 0 48px;
}

@media (max-width: 1280px) {
  .fillter-section form {
    margin: 0 10px;
  }
}

@media (max-width: 1200px) {
  .fillter-section {
    background: #f4f4f4;
    padding: 20px;
  }

  .fillter-section .container_ {
    padding: 10px 15px;
    background-color: #fff;
    border-radius: 5px;
  }

  .fillter-section form {
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 61px;
    border-radius: 0 0 5px 5px;
    padding: 10px 15px;
    width: calc(100% - 40px);
    margin: 0 20px;
    flex-wrap: wrap;
    z-index: 999;
    opacity: 1;
    visibility: visible;
    transition: all 0.25s ease;
  }

  .fillter-section form.active .closeSearch,
  .fillter-section form.active .form-group:not(:first-of-type) {
    display: block;
  }

  .fillter-section .form-group {
    width: 100%;
  }
}

.fillter-section .form-group {
  margin-bottom: 0;
  position: relative;
}

.fillter-section .form-group:first-of-type {
  width: 536px;
  margin: 0 10px;
}

@media (max-width: 1200px) {
  .fillter-section .form-group:first-of-type {
    width: calc(100% - 160px);
    position: absolute;
    left: 15px;
    top: -30px;
  }

  .fillter-section .form-group:not(:first-of-type) {
    display: none;
  }
}

.fillter-section .form-group:nth-of-type(4),
.fillter-section .form-group:nth-of-type(5) {
  position: static;
}

.fillter-section .searchSelect {
  background-color: #f5f5f5;
  border-radius: 4px;
  border: 1px solid #4d5471;
  padding: 3px 16px;
  line-height: 28px;
  color: #999;
  width: 100%;
}

@media (max-width: 1200px) {
  .fillter-section .searchSelect {
    border: 1px solid #ddd;
    padding: 5px 16px;
    background-color: #fff;
  }

  .fillter-section .pricesearch .price-btn {
    display: none;
  }
}

.fillter-section .search_select .select2-list {
  background-color: #fff !important;
  border: 1px solid #ddd;
  max-height: 200px;
  width: 100%;
}

.fillter-section .search_select .select2-list input {
  padding: 12px 15px;
  border: none;
  border-radius: 0;
  width: 100%;
  outline: 0;
}

.fillter-section .search_select .select-search-wrap {
  border-radius: 0 0 5px 5px;
  border: 1px solid #e4e4e4;
  padding: 10px;
  background: #fff;
  box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.17);
}

.fillter-section .search_select .select-search-wrap .select2-item {
  border-radius: 8px;
  border: 0;
  padding: 6px 12px;
  background: #fff;
  font-family: Lato, sans-serif;
  font-size: 14px;
  line-height: 1.25em;
  color: #000;
  cursor: pointer;
}

.fillter-section .search_select .select-search-wrap .select2-item:hover {
  background-color: #eaeaea;
}

.fillter-section .pricesearch .price-btn {
  border: 1px solid #4d5471;
  border-radius: 3px;
  line-height: 1.25em;
  color: #000;
  cursor: pointer;
  padding: 8px 15px;
  background-color: #f5f5f5;
  margin: 0 5px;
}

.fillter-section .innerbeds,
.fillter-section .innerprice {
  position: absolute;
  top: calc(100% + 24px);
  display: flex;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
  padding: 12px;
  height: auto;
  width: 380px;
  right: 0;
  border-radius: 6px;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s ease;
}

.fillter-section .innerbeds.active,
.fillter-section .innerprice.active {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 1200px) {

  .fillter-section .innerbeds,
  .fillter-section .innerprice {
    position: static;
    opacity: 1;
    visibility: visible;
    box-shadow: none;
    width: 100%;
    padding: 10px;
  }

  .fillter-section .beds-btn {
    display: none;
  }
}

.fillter-section .beds-btn,
.fillter-section .c-select {
  font-size: 14px;
  color: #000;
  padding: 8px 15px;
  background-color: #f5f5f5;
  margin: 0 5px;
  line-height: 1.25em;
  font-weight: 400;
  font-family: Lato, sans-serif;
}

.fillter-section .beds-btn {
  border: 1px solid #4d5471;
  border-radius: 3px;
  cursor: pointer;
}

.fillter-section .c-select {
  border: 1px solid #4d5471;
  border-radius: 3px;
  cursor: pointer;
}

.fillter-section .closeSearch,
.fillter-section .save-search {
  font-weight: 500;
  font-family: Effra-Medium;
  font-size: 14px;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .fillter-section .c-select {
    margin: 7px 10px;
    background-color: #fff;
    width: calc(100% - 22px);
    border: 1px solid #ddd;
  }
}

.fillter-section .save-search {
  padding: 12px 32px 6px;
  background: #ff8c00;
  border-radius: 5px;
  border: none;
  line-height: 1.25em;
  color: #fff;
  margin-left: 10px;
  outline: 0;
  transition: all 0.25s ease;
}

.fillter-section .save-search:hover {
  background-color: #006994;
}

@media (max-width: 1200px) {
  .fillter-section .save-search {
    margin: 7px 10px;
    width: calc(100% - 22px);
  }
}

.fillter-section .closeSearch {
  background: 0 0;
  color: #000;
  margin: 20px 0;
  width: 100%;
  text-align: center;
  min-height: 32px;
  border: none;
  box-shadow: none;
  display: none;
}

.fillter-section .responsive_filter {
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  background: #e9e9e9;
  margin-right: 17px;
  display: none;
  margin-left: auto;
  z-index: 9999;
}

.fillter-section .responsive_filter svg {
  stroke: #666;
  transform: rotate(90deg);
}

.fillter-section #bookmark-btn {
  padding: 5px 20px;
  background: #ff8c00;
  border-radius: 5px;
  border: none;
  outline: 0;
  cursor: pointer;
  transition: all 0.25s ease;
}

.fillter-section #bookmark-btn:hover {
  background-color: #006994;
}

.fillter-section #bookmark-btn svg {
  stroke: #fff;
  height: 20px;
}

@media (max-width: 1200px) {
  .fillter-section .responsive_filter {
    display: block;
  }

  .fillter-section #bookmark-btn {
    padding: 8px 10px;
  }

  .fillter-section .toSeperator {
    display: none;
  }

  .fillter-section .maxBedrooms,
  .fillter-section .maxprice,
  .fillter-section .minBedrooms,
  .fillter-section .minprice {
    width: 49%;
  }
}

.fillter-section .maxBedrooms select,
.fillter-section .maxprice select,
.fillter-section .minBedrooms select,
.fillter-section .minprice select {
  border: 1px solid #ddd !important;
  padding: 1px 10px !important;
  border-radius: 6px;
  height: 40px !important;
  font-size: 14px;
  line-height: 1.25em;
  color: #000;
  font-family: Lato, sans-serif;
  width: 160px !important;
  cursor: pointer;
  position: static !important;
}

@media (max-width: 1200px) {

  .fillter-section .maxBedrooms select,
  .fillter-section .maxprice select,
  .fillter-section .minBedrooms select,
  .fillter-section .minprice select {
    width: 100% !important;
  }
}

.fillter-section .bookmark-btn-outer {
  position: relative;
  z-index: 9999;
}

.fillter-section .bookmark-btn-outer .bookmark-coumt {
  position: absolute;
  top: -10px;
  right: 6px;
  width: 20px;
  height: 20px;
  display: flex;
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 10px;
  font-family: Lato, sans-serif;
}

.fillter-section .mainfiltersection,
.fillter-section .propertytypelist {
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  background: #fff;
  z-index: 2;
  border-bottom: 1px solid #e2e2e2;
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s ease;
}

.fillter-section .mainfiltersection.active,
.fillter-section .propertytypelist.active {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 1200px) {

  .fillter-section .mainfiltersection.active,
  .fillter-section .propertytypelist.active {
    position: static;
  }
}

.fillter-section .mainfiltersection ul,
.fillter-section .propertytypelist ul {
  display: flex;
  flex-wrap: wrap;
  padding: 30px 15px;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.fillter-section .mainfiltersection li,
.fillter-section .propertytypelist li {
  display: flex;
  flex-basis: 20%;
  max-width: 20%;
  margin: 10px 0;
  border-left: 1px dashed #bbb;
  padding: 15px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
}

@media (max-width: 576px) {

  .fillter-section .mainfiltersection ul,
  .fillter-section .propertytypelist ul {
    padding: 15px;
  }

  .fillter-section .mainfiltersection li,
  .fillter-section .propertytypelist li {
    flex-basis: 33.33%;
    max-width: 33.33%;
  }
}

.fillter-section .mainfiltersection li input,
.fillter-section .propertytypelist li input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

.fillter-section .mainfiltersection li label,
.fillter-section .propertytypelist li label {
  color: #242429;
  font-weight: 400;
  font-family: Lato, sans-serif;
  padding: 2px 10px;
  width: 100%;
  text-align: center;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 14px;
}

.fillter-section .mainfiltersection li img,
.fillter-section .propertytypelist li img {
  opacity: 0.5;
  width: 40px;
  height: 40px;
}

.fillter-section .mainfiltersection li input:checked+.customtypedata label,
.fillter-section .propertytypelist li input:checked+.customtypedata label {
  background-color: #262637;
  color: #fff;
}

.fillter-section .mainfiltersection li .customtypedata,
.fillter-section .propertytypelist li .customtypedata {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fillter-section .doneFilter {
  flex-basis: 100%;
  margin-top: 15px;
}

.fillter-section .doneFilter button {
  color: #303030;
  border: 2px solid #303030;
  background: 0 0;
  border-radius: 5px;
  padding: 12px 20px 9px;
  font-weight: 500;
  font-family: Effra-Medium;
  cursor: pointer;
  font-size: 14px;
  line-height: 1em;
  transition: all 0.25s ease;
}

.fillter-section .doneFilter button:hover {
  color: #fff;
  background: #ff8c00;
  border: 2px solid #ff8c00;
}

.fillter-section .datefilter {
  display: flex;
  padding: 0 15px 16px;
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: center;
}

.fillter-section .datefilter .addedSite__select,
.fillter-section .datefilter .search-subtype-property {
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.fillter-section .datefilter .addedSite__select label,
.fillter-section .datefilter .search-subtype-property label {
  white-space: nowrap;
  margin-right: 4px;
  color: #242429;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: -4px;
  text-align: left;
  font-family: Lato, sans-serif;
}

.fillter-section .datefilter .addedSite__select .select-outer,
.fillter-section .datefilter .search-subtype-property .select-outer {
  width: 100%;
  max-width: 220px;
  position: relative;
  border-radius: 6px;
}

.fillter-section .datefilter .addedSite__select .select-outer select,
.fillter-section .datefilter .search-subtype-property .select-outer select {
  color: #333;
  padding: 0 20px 0 10px;
  width: 100%;
  background: #fff;
  border-color: #ddd;
  border-radius: 6px;
  height: 38px;
  font-size: 14px;
  font-family: Lato, sans-serif;
  text-align: center;
}

#subtype-propertyy {
  text-align: center;
}

.fillter-section .datefilter .addedSite__select #subtype-propertyy,
.fillter-section .datefilter .search-subtype-property #subtype-propertyy {
  background: #fff;
  height: 38px;
  border-color: #ddd;
  border-radius: 6px;
  width: 134px;
  font-size: 14px;
  font-family: Lato, sans-serif;
}

.fillter-section .tagsfilter-Outer {
  background: #f7f7f7;
  border-top: 1px dashed #ccc;
}

.fillter-section .tagsfilter-Outer .material-symbols-outlined,
.fillter-section .tagsfilter-Outer i {
  font-size: 40px;
  color: navy;
  opacity: 0.6;
}

.fillter-section #save-search-div,
.fillter-section div#bookmarks {
  top: 100%;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s ease;
}

.fillter-section #save-search-div {
  position: absolute;
  right: 10px;
  width: 100%;
  max-width: 320px;
  background: #fff;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 15px;
}

.fillter-section #save-search-div.active {
  opacity: 1;
  visibility: visible;
}

.fillter-section #save-search-div h2 {
  margin: 0.83em 0;
  font-size: 16px;
  font-weight: 700;
  color: #242429;
  font-family: Lato, sans-serif;
}

.fillter-section #save-search-div form {
  margin: 0;
}

@media (max-width: 1200px) {
  .fillter-section #save-search-div.active {
    z-index: 99999;
  }

  .fillter-section #save-search-div form {
    padding: 0;
    margin: 0;
    position: static;
    width: 100%;
  }

  .fillter-section #save-search-div form .closeSearch {
    display: none;
  }
}

.fillter-section #save-search-div label {
  color: #242429;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.25em;
  text-align: left;
  font-family: Lato, sans-serif;
  margin: 0;
  width: 100%;
}

.fillter-section #save-search-div #alerts-frequency,
.fillter-section #save-search-div #save-search-name {
  height: 36px;
  color: #222;
  line-height: 1.25em;
  font-family: Lato, sans-serif;
  font-size: 14px;
  width: 100%;
}

.fillter-section #save-search-div #save-search-name {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 8px 0 15px;
  padding: 0 15px;
}

.fillter-section #save-search-div #alerts-frequency {
  background: #fff;
  border: 1px solid #ddd;
  margin-bottom: 15px;
  margin-top: 10px;
  border-radius: 5px;
  padding: 0 10px;
}

.fillter-section #save-search-div button {
  background: #f68c04;
  color: #fff;
  border-radius: 5px;
  border: 0;
  padding: 11px 10px 6px;
  max-width: 100px;
  font-weight: 500;
  font-family: Effra-Medium;
  font-size: 14px;
  width: 100px;
  transition: all 0.25s ease;
}

.fillter-section #save-search-div button:hover {
  background-color: #006994;
}

.fillter-section div#bookmarks {
  position: absolute;
  right: 15px;
  width: calc(100% - 30px);
  background: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
}

.fillter-section div#bookmarks.active {
  opacity: 1;
  visibility: visible;
  z-index: 111;
}

.fillter-section div#bookmarks h4 {
  display: flex;
  align-items: center;
  margin: 1.33em 0;
  font-weight: 700;
  color: #242429;
  font-size: 14px;
  font-family: Lato, sans-serif;
}

.fillter-section div#bookmarks .bookmark-btn-outer {
  margin-left: auto;
}

.fillter-section div#bookmarks .bookmark-btn-outer button {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  padding: 4px;
}

.fillter-section div#bookmarks .bookmark-btn-outer button svg {
  min-width: 30px;
  min-height: 30px;
}

.fillter-section div#bookmarks p {
  margin: 1em 0;
  color: #242429;
  font-size: 14px;
  line-height: 26px;
  font-family: Lato, sans-serif;
  font-weight: 400;
}

.fillter-section div#bookmarks table tbody tr td,
.fillter-section div#bookmarks table th {
  padding: 8px;
  color: #484848;
  font-family: Lato, sans-serif;
  font-size: 14px;
}

.fillter-section div#bookmarks table th {
  white-space: nowrap;
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
  border-top: none;
  line-height: 1.2em;
  font-weight: 700;
  text-align: left;
}

.fillter-section div#bookmarks table tbody tr:nth-of-type(odd) {
  background-color: #f3f3f3;
}

.fillter-section div#bookmarks table tbody tr td {
  vertical-align: middle;
  border-top: 1px solid #ddd;
  line-height: 1.2em;
  font-weight: 400;
}

.fillter-section div#bookmarks table tbody select {
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -webkit-transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
}

.fillter-section div#bookmarks table tbody button.delete-bookmark {
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #eee;
  position: relative;
  z-index: 999;
  font-family: Effra-Medium;
  font-weight: 500;
}

.fillter-section .select2 {
  position: relative;
}

.fillter-section .select2-list {
  display: none;
  position: absolute;
  max-height: 300px;
  overflow: overlay;
  z-index: 9;
}

.fillter-section .select2-item:hover {
  background-color: #94adff31;
  cursor: default;
}

.fillter_res {
  background-color: #f4f4f4;
  padding: 15px 0;
}

.fillter_res .container_ {
  max-width: 1275px;
  margin: 0 auto;
  padding: 0 24px;
}

@media (max-width: 992px) {
  .fillter_res .container_ {
    padding: 0 15px;
  }
}

.fillter_res .top_bar {
  justify-content: space-between;
  align-items: center;
}

.fillter_res .top_bar h5 {
  font-family: Lato, sans-serif;
  font-size: 18px;
  line-height: 1.25em;
  font-weight: 600;
  color: #242429;
}

@media (max-width: 767px) {
  .fillter_res .container_ {
    padding: 15px;
  }

  .fillter_res .top_bar h5 {
    font-size: 16px;
    margin-bottom: 5px;
  }
}

.fillter_res .top_bar label,
.fillter_res .top_bar select {
  font-family: Lato, sans-serif;
  font-size: 14px;
}

.fillter_res .top_bar label {
  color: #242429;
  font-weight: 400;
  text-align: left;
}

.fillter_res .top_bar select {
  background-color: #fff;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  padding: 8px 8px 10px;
  height: 40px;
  margin-left: 10px;
  color: #222;
  line-height: 1.25em;
}

.fillter_res .top_heading,
.fillter_res h1 {
  font-weight: 600;
  color: #242429;
  font-family: Lato, sans-serif;
}

.fillter_res .top_heading {
  font-size: 18px;
  margin: 40px 0 28px;
  line-height: 1.25em;
}

@media (max-width: 767px) {
  .fillter_res .top_heading {
    font-size: 16px;
    margin: 26px 0 28px;
    width: calc(100% - 90px);
  }
}

.fillter_res h1 {
  font-size: 27px;
  margin: 0.67em 0;
  line-height: 1.25;
}

/* .result-slider-wrap,
.result-slider-wrap .result-slider {
  width: 100%;
}
.result-slider-wrap .slick-slide {
  margin: 0 8px;
}
.result-slider-wrap .slick-list {
  margin: 0 -8px;
}
.result-slider-wrap .listings {
  box-shadow: rgba(0, 0, 0, 0.12) 0 4px 8px;
}
.result-slider-wrap .listings .col_:first-of-type {
  padding: 0;
  width: 100%;
}
.result-slider-wrap .listings .col_:nth-of-type(2) {
  width: 100%;
}
.result-slider-wrap .listings .pricesection {
  display: flex;
}
.result-slider-wrap .listings .pricesection span {
  font-size: 14px;
  display: flex;
}
.result-slider-wrap .listings .pricesection .price {
  font-size: 18px;
  margin-right: auto;
}



@media (max-width: 992px) {
  .result-slider-wrap .listings .pricesection .price {
    font-size: 17px;
  }
}
@media (max-width: 767px) {
  .result-slider-wrap .listings .pricesection .price {
    font-size: 0;
  }
}
.result-slider-wrap .listings .pricesection .price bdi {
  font-size: 17px;
  padding-top: 8px;
}
.result-slider-wrap .listings .pricesection img {
  width: 16px;
  height: 16px;
}
.result-slider-wrap .listings .pricesection .bathrooms_size {
  margin-right: 0;
}
.result-slider-wrap .listings .listDataRight a {
  display: none;
}
.result-slider-wrap .listings .listDataContainer a {
  width: 100%;
}
.result-slider-wrap .listings .listingaddress {
  margin-bottom: 55px;
}
.result-slider-wrap .slick-arrow {
  top: -60px;
  width: 40px;
  height: 44px;
  position: absolute;
  font-size: 0;
  background-size: 15px !important;
  border-width: 1px;
  border-style: solid;
  border-color: #222;
  border-image: initial;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  outline: 0;
}
.result-slider-wrap .slick-arrow.slick-prev {
  right: 42px;
  left: auto;
  background: url(../../assets/images/arrow-left.svg);
  border-radius: 8px 0 0 8px;
}
.result-slider-wrap .slick-arrow.slick-next {
  right: 2px;
  background: url(../../assets/images/arrow-right.svg);
  border-radius: 0 8px 8px 0;
  border-left: 0;
} */
.listings {
  border-radius: 15px;
  box-shadow: 1px 1px 2px rgba(51, 51, 51, 0.28);
  background: #fff;
  margin-bottom: 30px;
}

.listings .col_ {
  padding: 14px;
}

.listings .col_:first-of-type {
  width: 43%;
  position: relative;
}

@media (max-width: 767px) {
  .listings .col_:first-of-type {
    width: 100%;
  }
}

.listings .col_:first-of-type .slider_link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.listings .col_:first-of-type:hover .slick-arrow {
  opacity: 0.5;
}

.listings .col_:nth-of-type(2) {
  width: calc(100% - 43%);
  display: flex;
  flex-direction: column;
}

@media (max-width: 767px) {
  .listings .col_:nth-of-type(2) {
    width: 100%;
    position: relative;
  }
}

.listings .list-img-slider {
  width: 100%;
  height: 300px;
}

.listings .list-img-slider figure {
  border-radius: 15px;
  height: 100%;
}

.listings .list-img-slider figure img {
  border-radius: 15px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.listings .list-img-slider .slick-list,
.listings .list-img-slider .slick-slide,
.listings .list-img-slider .slick-track {
  height: 100%;
}

.listings .list-img-slider .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  width: 50px;
  z-index: 1;
  opacity: 0;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
  background-image: -webkit-gradient(linear,
      right top,
      left top,
      from(rgba(0, 0, 0, 0)),
      to(#262637));
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0), #262637);
  font-size: 0;
  border-radius: 15px 0 0 15px;
  transition: all 0.25s ease;
}

.listings .list-img-slider .slick-arrow:before {
  font-family: fontawesome;
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #000;
  content: "\f104";
  cursor: pointer;
  position: absolute;
  left: 20px;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
}

.listings .listingtitle,
.listings .pricesection span {
  font-family: Lato, sans-serif;
}

.listings .list-img-slider .slick-arrow:after {
  content: "";
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-color: #fff;
  opacity: 0.5;
  border-radius: 50%;
  cursor: pointer;
}

.listings .list-img-slider .slick-arrow.slick-next {
  left: auto;
  right: 0;
  background-image: -webkit-gradient(linear,
      left top,
      right top,
      from(rgba(0, 0, 0, 0)),
      to(#262637));
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0), #262637);
  border-radius: 0 15px 15px 0;
}

.listings .list-img-slider .slick-arrow.slick-next:before {
  content: "\f105";
  left: auto;
  right: 20px;
}

.listings .list-img-slider .slick-arrow.slick-next:after {
  left: auto;
  right: 15px;
}

.listings .listDataContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.listings .listingtitle {
  color: #333;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
}

.listings .pricesection {
  padding: 10px 0;
}

.listings .pricesection>span {
  padding-left: 10px;
  margin-right: 10px;
  font-weight: 600;
  font-size: 17px;
  line-height: 1.25em;
  color: #666;
  border-left: 1px solid #ccc;
}

.listings .pricesection .price {
  font-size: 21px;
  font-weight: 700;
  color: #333;
  margin-right: 20px;
  line-height: 1.25em;
  font-family: Lato, sans-serif;
  padding-left: 0;
  border: none !important;
}

.about_mallorca p,
.listings .listingaddress {
  font-weight: 400;
  font-family: Lato, sans-serif;
}

.listings .listingaddress i,
.request-view .listingaddress svg {
  margin-right: 5px;
}

@media (max-width: 992px) {
  .listings .pricesection .price {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .listings .pricesection .price {
    font-size: 16px;
    width: 100%;
    margin: 0 0 10px;
  }
}

.listings .pricesection .price bdi {
  font-size: 17px;
  padding-top: 8px;
}

.listings .pricesection .bathrooms_size {
  border-right: none;
}

.listings .pricesection img {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  margin-right: 5px;
}

.firstalpha {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f78fb3;
  color: #fff !important;
  text-align: center;
  display: grid;
  align-items: center;
  font-size: 18px !important;
  font-weight: 400 !important;
}

.firstalpha2 {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #f78fb3;
  color: #fff !important;
  text-align: center;
  display: grid;
  align-items: center;
  margin-right: 12px;
  font-size: 25px !important;
  font-weight: 400 !important;
}

.listings .listingaddress {
  font-size: 16px;
  line-height: 1.25em;
  margin-bottom: 11px;
  color: #333;
  margin-top: 3px;
}

.listings .listDataRight a {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767px) {
  .listings .listingaddress {
    margin-bottom: 0px;
    margin-top: 10px;
  }

  .listings .listDataRight a {
    position: absolute;
    right: 10px;
    top: 0;
  }
}

.listings .listDataRight a i {
  font-size: 16px;
}

.listings .agentname {
  margin-bottom: 15px;
  padding-top: 10px;
  /* border-top: 1px solid #ccc; */
  margin-top: auto;
}

.listings .agentname .wrap {
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}

.listings .agentname a,
.listings .agentname p {
  font-weight: 600;
  color: #39c;
  font-size: 14px;
  line-height: 19px;
  font-family: Lato, sans-serif;
}

.listings .agentname figure {
  width: 50px;
  height: 50px;
  margin-left: 10px;
}

.listings .agentname figure a,
.listings .agentname figure img {
  width: 100%;
  height: 100%;
}

.table#tbUser {
  width: 100%;
  border-collapse: collapse;
}

.about_mallorca .col,
.property-tab .col {
  width: 50%;
}

.fillter-section .mainfiltersection li div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about_mallorca {
  padding: 50px 0;
  background-color: #f4f4f4;
}

.about_mallorca .container-sm {
  padding: 0;
}

@media (max-width: 1024px) {
  .about_mallorca .container-sm {
    padding: 0 20px;
  }
}

.about_mallorca .col:first-of-type {
  padding-right: 50px;
}

@media (max-width: 767px) {
  .about_mallorca .col {
    width: 100%;
  }

  .about_mallorca .col:first-of-type {
    padding-right: 0;
  }
}

.about_mallorca .inner-heading {
  text-align: left;
}

.about_mallorca .inner-heading h3 {
  padding-top: 13px;
  display: inline-block;
  position: relative;
  margin-bottom: 20px;
  color: #303030;
}

.about_mallorca .inner-heading h3:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 80px;
  height: 3px;
  /* background-color: #ff8c00; */
}

.about_mallorca p {
  color: #4d5471;
  letter-spacing: 0.1px;
  line-height: 1.7em;
  font-size: 16px;
  margin: 1em 0;
}

.property-tab .head h2,
.property-tab ul li {
  font-family: Effra-Regular;
  font-weight: 400;
}

.about_mallorca figure,
.about_mallorca img {
  border-radius: 10px;
  width: 100%;
}

.about_mallorca figure {
  height: 313px;
}

.about_mallorca figure img {
  height: 100%;
  object-fit: cover;
}

.property-tab:nth-of-type(odd) figure {
  padding-right: 30px;
}

@media (max-width: 767px) {
  .property-tab:nth-of-type(odd) figure {
    padding-right: 0;
    height: 325px;
  }

  .property-tab:nth-of-type(odd) .col:first-of-type {
    padding: 0;
  }
}

.property-tab:nth-of-type(odd) .col:nth-of-type(2) {
  padding: 10px;
}

.property-tab:nth-of-type(even) figure {
  padding-left: 30px;
}

@media (max-width: 767px) {
  .property-tab:nth-of-type(even) figure {
    padding-left: 0;
    height: 325px;
  }

  .property-tab .col {
    width: 100%;
  }

  .property-tab:nth-of-type(even) .col:first-of-type {
    padding: 0;
  }
}

.property-tab:nth-of-type(even) .col:first-of-type {
  padding: 10px;
}

.property-tab .head {
  position: relative;
  padding: 40px 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.property-tab .head:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
}

.property-tab .head h2 {
  padding: 10px;
  color: #fff;
  text-align: center;
  font-size: 34px;
  position: relative;
}

.property-tab .wrapper {
  padding: 50px 0;
}

.property-tab .tab-wrapper {
  padding: 10px;
}

.property-tab .tab-wrapper .tab-content {
  display: none;
}

.property-tab ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.property-tab ul li {
  cursor: pointer;
  padding: 10px 19px;
  color: #4d5471;
  font-size: 18px;
  transition: all 0.25s ease;
  border-top: solid 3px transparent;
}

.buyingAdvice .ag-timeline-card_desc,
.property-tab p {
  font-size: 16px;
  letter-spacing: 0.1px;
  font-family: Lato, sans-serif;
}

.property-tab ul li.active {
  border-top: solid 3px #ff8c00;
  color: #000;
}

.property-tab ul li:hover {
  color: #000;
}

.property-tab figure,
.property-tab img {
  border-radius: 10px;
  width: 100%;
}

.property-tab figure {
  height: 455px;
}

.property-tab figure img {
  height: 100%;
  object-fit: cover;
}

.property-tab p {
  margin: 1em 0;
  color: #2265ab;
  line-height: 1.7em;
  font-weight: 400;
}

.buyingAdvice {
  background: #f4f4f4;
  padding: 50px 0;
}

.buyingAdvice .container-sm {
  padding: 0;
}

.buyingAdvice .ag-format-container {
  position: relative;
  padding-bottom: 20px;
}

.buyingAdvice .ag-timeline_title-box {
  padding: 0 0 30px;
  text-align: center;
}

.buyingAdvice .ag-timeline_tagline {
  font-size: 40px;
  color: #54595f;
}

.buyingAdvice .ag-timeline_item {
  margin: 0 0 35px;
  position: relative;
  display: flex;
  align-items: center;
}

@media (max-width: 1024px) {
  .buyingAdvice .ag-timeline_item:nth-of-type(odd) {
    flex-direction: row-reverse;
  }
}

.buyingAdvice .ag-timeline_item:nth-child(2n) {
  text-align: left;
  justify-content: flex-end;
}

.buyingAdvice .ag-timeline {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}

.buyingAdvice .ag-timeline_line {
  width: 2px;
  background-color: #e8e8f6;
  position: absolute;
  top: 0;
  left: 50%;
  bottom: 0;
  overflow: hidden;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

@media (max-width: 1025) {
  .buyingAdvice .ag-timeline_line {
    top: 120px !important;
  }
}

.buyingAdvice .ag-timeline_line-progress {
  width: 100%;
  height: 20%;
  background-color: #ff8c00;
}

.buyingAdvice .ag-timeline-card_box {
  padding: 0 0 0 55px;
}

@media (max-width: 1024px) {
  .buyingAdvice .ag-timeline_line {
    left: 22px;
    transform: unset;
  }

  .buyingAdvice .ag-timeline-card_box {
    padding: 20px 0 0 25px;
  }
}

.buyingAdvice .ag-timeline_item:nth-child(2n) .ag-timeline-card_box {
  padding: 0 55px 0 0;
}

.buyingAdvice .ag-timeline-card_point-box {
  display: inline-block;
  margin: 0 20px 0 -20px;
}

.buyingAdvice .ag-timeline_item:nth-child(2n) .ag-timeline-card_point-box {
  margin: 0 -20px 0 0;
  display: flex;
  align-items: center;
}

.buyingAdvice .ag-timeline_item:nth-child(2n) .ag-timeline-card_point-box .ag-timeline-card_point {
  margin-left: 20px;
}

@media (max-width: 1024px) {
  .buyingAdvice .ag-timeline_item:nth-child(2n) .ag-timeline-card_box {
    padding: 0 22px 0 0;
  }

  .buyingAdvice .ag-timeline_item:nth-child(2n) .ag-timeline-card_point-box .ag-timeline-card_point {
    margin-left: 6px;
  }

  .buyingAdvice .ag-timeline_item:nth-child(2n) .ag-timeline-card_point-box {
    margin: 0;
  }
}

.buyingAdvice .ag-timeline-card_point {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e8e8f6;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.buyingAdvice .ag-timeline-card_point svg {
  width: 18px;
  height: 16px;
}

.buyingAdvice .ag-timeline-card_point svg path {
  fill: #fff;
}

.buyingAdvice .js-ag-active .ag-timeline-card_point {
  color: #fff;
  background-color: #ff8c00;
}

.buyingAdvice .ag-timeline-card_meta-box {
  display: inline-block;
}

.buyingAdvice .ag-timeline-card_meta {
  margin: 10px 0 0;
  font-family: Effra-Medium;
  font-weight: 700;
  font-size: 20px;
  color: #a0a0a2;
  letter-spacing: 0.1px;
}

@media (max-width: 1024px) {
  .buyingAdvice .ag-timeline-card_meta {
    display: none;
  }
}

.buyingAdvice .js-ag-active .ag-timeline-card_meta {
  color: #ff8c00;
}

.buyingAdvice .ag-timeline-card_item {
  display: inline-block;
  width: 45%;
  margin: 0;
  background-color: #fff;
  opacity: 1;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 2px 2px 30px 0 rgba(156, 156, 192, 0.26);
  -moz-box-shadow: 2px 2px 30px 0 rgba(156, 156, 192, 0.26);
  -o-box-shadow: 2px 2px 30px 0 rgba(156, 156, 192, 0.26);
  box-shadow: 2px 2px 30px 0 rgba(156, 156, 192, 0.26);
  position: relative;
}

@media (max-width: 1024px) {
  .buyingAdvice .ag-timeline-card_item {
    width: calc(100% - 10px);
    margin-right: 10px;
  }
}

.buyingAdvice .ag-timeline-card_arrow {
  height: 20px;
  width: 20px;
  background-color: #fff;
  z-index: 1;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%) rotate(45deg);
  -moz-transform: translateY(-50%) rotate(45deg);
  -ms-transform: translateY(-50%) rotate(45deg);
  -o-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
}

@media (max-width: 1024px) {
  .buyingAdvice .ag-timeline-card_arrow {
    right: auto;
  }
}

.buyingAdvice .ag-timeline_item:nth-child(2n + 1) .ag-timeline-card_arrow {
  margin-left: calc(-18px / 2);
  margin-right: calc(-18px / 2);
}

.buyingAdvice .ag-timeline_item:nth-child(2n) .ag-timeline-card_arrow {
  margin-left: -10px;
  right: auto;
  left: 0;
}

.buyingAdvice .ag-timeline-card_img {
  width: 100%;
}

.buyingAdvice .ag-timeline-card_info {
  padding: 40px;
}

.buyingAdvice .ag-timeline-card_title {
  margin: 0 0 1.67em;
  font-family: Effra-Medium;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.25;
  color: #242429;
}

.buyingAdvice .ag-timeline-card_desc {
  line-height: 1.7em;
  font-weight: 400;
  color: #4d5471;
}

.buyingAdvice .boxContent {
  padding: 50px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px 11px rgba(0, 0, 0, 0.04);
}

@media (max-width: 992px) {
  .buyingAdvice .boxContent {
    padding: 30px 20px 20px;
  }
}

.buyingAdvice .boxContent h4 {
  color: #303030;
  font-weight: 400;
  font-family: Effra-Regular;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 20px;
}

.buyingAdvice .boxContent p,
.buyingAdvice .notes p {
  color: #4d5471;
  font-size: 16px;
  font-weight: 400;
  font-family: Lato, sans-serif;
}

.buyingAdvice .boxContent p,
.buyingAdvice .notes p,
.property-inner .price_map .col a,
.property-inner .price_map h3,
.property-inner .price_map p {
  line-height: 1.7em;
  letter-spacing: 0.1px;
}

.buyingAdvice .boxContent p {
  margin: 1em 0;
}

.buyingAdvice .notes {
  padding-top: 50px;
}

.buyingAdvice .notes p {
  padding: 10px;
}

.banner-gallery {
  padding: 50px 0 40px;
}

@media (max-width: 767px) {
  .banner-gallery {
    padding: 40px 0 30px;
  }
}

.banner-gallery .container-sm {
  padding: 0 10px;
}

@media (max-width: 1280px) {
  .banner-gallery .container-sm {
    padding: 0 20px;
  }
}

#demo-test-gallery {
  display: grid;
  grid-template-columns: 50% 25% 25%;
  grid-template-rows: 1fr 1fr;
}

@media (max-width: 767px) {
  .banner-gallery .container-sm {
    padding: 0 10px;
  }

  #demo-test-gallery {
    grid-template-columns: 50% 50%;
  }
}

#demo-test-gallery .galleryImg {
  height: 170px;
  width: 100%;
  background-color: #000;
  border-radius: 10px;
  position: relative;
}

#demo-test-gallery .galleryImg:first-of-type {
  grid-row: 1/3;
  grid-column: 1/2;
  height: 340px;
}

#demo-test-gallery .galleryImg:before {
  transition: all 0.25s ease;
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  width: calc(100% - 14px);
  height: calc(100% - 14px);
  background-color: #000;
  opacity: 0;
  visibility: hidden;
}

#demo-test-gallery .galleryImg:hover:before {
  visibility: visible;
  opacity: 0.18;
}

#demo-test-gallery .galleryImg figure {
  width: 100%;
  height: 100%;
  padding: 7px;
  background-color: #fff;
}

#demo-test-gallery .galleryImg img {
  border-radius: 10px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.property-inner .container-sm {
  padding: 0;
}

@media (max-width: 1280px) {
  .property-inner .container-sm {
    padding: 0 20px;
  }
}

.property-inner .price_map .col {
  width: 50%;
}

@media (max-width: 767px) {
  .property-inner .price_map .col:first-of-type {
    width: 85%;
  }

  .property-inner .price_map .col:nth-of-type(2) {
    width: 15%;
  }
}

.property-inner .price_map .col a {
  margin-top: 5px;
  display: flex;
  align-items: center;
  width: 20%;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #ff8c00;
}

@media (max-width: 992px) {
  .property-inner .price_map .col a {
    width: 22%;
  }
}

@media (max-width: 767px) {
  .property-inner .price_map .col a {
    width: 33.3%;
  }
}

.property-inner .price_map .col a:hover {
  color: #006994;
  text-decoration: underline;
}

.property-inner .price_map .col a:hover svg path {
  fill: #006994;
}

.property-inner .price_map .col a svg path,
.property-inner .price_map .col:nth-of-type(2) a:hover svg path {
  fill: #ff8c00;
}

.property-inner .price_map .col a svg {
  width: 11px;
  height: 14px;
  margin-right: 11px;
}

.property-inner .price_map .col:nth-of-type(2) a {
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: #f4f4f4;
  margin-left: auto;
  border-radius: 50%;
}

.property-inner .price_map .col:nth-of-type(2) a svg {
  width: 20px;
  height: 20px;
  margin-right: 0;
}

.property-inner .price_map .col:nth-of-type(2) a svg path {
  fill: #000;
}

.property-inner .price_map h3 {
  font-size: 26px;
  font-weight: 600;
  font-family: Lato, sans-serif;
  color: #333;
}

.property-inner .price_map p {
  color: #333;
  font-family: Poppins, Sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.property-inner .wrap {
  padding: 20px 0 50px;
}

.property-inner .wrap .col:first-of-type {
  width: 65%;
  padding-right: 10px;
}

@media (max-width: 767px) {
  .property-inner .wrap .col:first-of-type {
    width: 100%;
    padding-right: 0;
  }
}

.property-inner .wrap .col:nth-of-type(2) {
  width: 35%;
  padding: 10px;
}

@media (max-width: 767px) {
  .property-inner .wrap .col:nth-of-type(2) {
    width: 100%;
    padding: 0;
  }
}

.property-inner .highlight {
  column-count: 2;
}

.property-inner .highlight li {
  margin-bottom: 20px;
  align-items: center;
}

.property-inner .highlight .icon {
  border: 2px solid #006994;
  border-radius: 50%;
  width: 46px;
  height: 46px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.property-inner .highlight .flex .content p {
  margin-bottom: 0;
}



.property-inner .highlight .icon svg path {
  fill: #006994;
}

.property-inner .highlight h4 {
  font-weight: 700;
  margin: 5px 0 3px;
  font-size: 16px;
  line-height: 1em;
  font-family: Lato, sans-serif;
  color: #333;
}

.property-inner .highlight .flex .content h4 {
  padding-top: 0 !important;
}

.property-inner .highlight p,
.property-inner p {
  font-weight: 400;
  color: #4d5471;
  font-family: Lato, sans-serif;
}

.property-inner .highlight p {
  font-size: 14px;
  line-height: 26px;
  margin: 0;
}

.property-inner .highlight.multyside {
  column-count: auto;
  float: left;
  width: 100%;
}

.heading-line,
header {
  clear: both;
}

.property-inner .highlight.multyside li {
  float: left;
  width: 50%;
}

@media (max-width: 767px) {
  .property-inner .highlight p {
    margin-bottom: 0 !important;
  }
}

.property-inner p {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.7em;
  letter-spacing: 0.1px;
}

.property-inner p:last-of-type {
  margin-bottom: 25px;
}

.property-inner .features-row {
  margin-bottom: 20px;
}

.property-inner .features-row .col_ {
  width: 50%;
  margin-bottom: 5px;
  padding: 10px;
}

@media (max-width: 767px) {
  .property-inner .features-row .col_ {
    width: 100%;
  }
}

.property-inner .features-row h4 {
  margin-bottom: 25px;
  font-weight: 700;
  font-family: Lato, sans-serif;
  color: #333;
  font-size: 18px;
  line-height: 1.1em;
}

.property-inner #location p,
.property-inner .features-row h5 {
  font-family: Lato, sans-serif;
  font-size: 16px;
  color: #4d5471;
  font-weight: 400;
}

.property-inner .features-row li {
  margin-bottom: 11px;
}

.property-inner .features-row .icon {
  width: 32px;
  height: 32px;
  border-radius: 18px;
  background-color: #006994;
  display: flex;
  align-items: center;
  justify-content: center;
}

.property-inner .features-row .icon svg path {
  fill: #fff;
}

.property-inner .features-row h5 {
  padding-left: 13px;
  line-height: 27px;
}

.property-inner #location p {
  margin-top: 20px;
  line-height: 1.7em;
  letter-spacing: 0.1px;
}

.agent-profile {
  border-radius: 5px;
  border: 1px solid #dfdfe1;
  padding: 30px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.18);
}

.agent-profile h3 {
  color: #303030;
  font-family: Effra-Regular;
  font-weight: 400;
  margin: 20px 0 10px;
}

.agent-profile .user-name p,
.agent-profile h4 {
  color: #303030;
  font-size: 18px;
  font-weight: 700;
  font-family: Lato, sans-serif;
}

.agent-profile h4 {
  margin: 10px 0;
}

.agent-profile #user-details {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.agent-profile .user-name {
  flex-basis: calc(100% - 80px);
  padding-right: 20px;
}

.agent-profile .user-name p {
  margin: 0;
}

.agent-profile .user-img {
  flex-basis: 80px;
}

.agent-profile ul {
  margin-bottom: 20px;
}

.agent-profile li {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.7em;
  letter-spacing: 0.1px;
  color: #4d5471;
  font-family: Lato, sans-serif;
  display: flex;
  align-items: center;
}

.agent-profile li a {
  color: #ff8c00;
  display: flex;
  align-items: center;
}

.agent-profile li svg {
  margin-right: 8px;
}

.agent-profile li svg path {
  fill: #4d5471;
}

.agent-profile li a:hover {
  text-decoration: underline;
}

.agent-profile li a svg path {
  fill: #ff8c00;
}

.agent-profile .btn_custom {
  border: 2px solid #006994;
  border-radius: 3px;
  padding: 11px 24px 13px;
  width: 100%;
  margin-bottom: 20px;
  background-color: #fff;
  transition: all 0.25s ease;
}

.agent-profile .btn_custom:nth-of-type(2) {
  margin-bottom: 30px;
}

.agent-profile .btn_custom:nth-of-type(3) {
  padding: 9px 24px 16px;
}

.agent-profile .btn_custom svg path {
  fill: #006994;
  transition: all 0.25s ease;
}

.agent-profile .btn_custom:hover svg path,
.call_popup .closeBtn svg path,
.related-properties .related_pop_slider .slick-arrow svg path,
.related-properties li .content svg path {
  fill: #fff;
}

.agent-profile .btn_custom span {
  color: #006994;
  font-size: 15px;
  line-height: 1em;
  font-weight: 400;
  font-family: Lato, sans-serif;
  width: calc(100% - 20px);
  text-align: center;
  transition: all 0.25s ease;
}

.agent-profile .btn_custom:hover {
  background-color: #ff8c00;
  border: 2px solid #ff8c00;
}

.agent-profile .btn_custom:hover span {
  color: #fff;
}

.related-properties {
  padding: 20px 0 70px;
}

@media (max-width: 767px) {
  .related-properties {
    padding: 20px 0 30px;
  }
}

.related-properties .container-sm {
  padding: 0;
}

@media (max-width: 1280px) {
  .related-properties .container-sm {
    padding: 0 20px;
  }
}

.related-properties .heading-line h3 {
  margin-bottom: 20px;
}

.related-properties li {
  width: calc(24% - 11px);
  margin-right: 30px;
  position: relative;
}

@media (max-width: 767px) {
  .related-properties li {
    width: calc(50% - 20px);
    margin-bottom: 30px;
  }

  .related-properties li:nth-of-type(2) {
    margin-right: 0;
  }
}

@media (max-width: 576px) {
  #demo-test-gallery {
    grid-template-columns: 100%;
  }

  .related-properties li {
    width: 100%;
  }
}

.related-properties li:last-of-type {
  margin-right: 0;
}

.related-properties .related_pop_slider {
  width: 100%;
  height: 230px;
  border-radius: 5px;
}

.related-properties .related_pop_slider .slick-arrow {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  z-index: 5;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border: none;
  background: 0 0;
  border-radius: 50%;
  font-size: 0;
  opacity: 0;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.related-properties a,
.related-properties a:before,
.related-properties figure,
.related-properties img {
  border-radius: 5px;
  width: 100%;
}

.related-properties .related_pop_slider .slick-arrow.slick-next {
  right: 15px;
  left: auto;
}

.related-properties .related_pop_slider .slick-arrow.slick-next svg {
  transform: rotate(180deg);
}

.related-properties .related_pop_slider .slick-arrow:hover {
  background-color: #ff8c00;
}

.related-properties .slick-list,
.related-properties .slick-slide,
.related-properties .slick-track,
.related-properties a,
.related-properties figure,
.related-properties img {
  height: 100%;
}

.related-properties img {
  object-fit: cover;
  object-position: center;
}

.related-properties a {
  position: relative;
}

.related-properties a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #242429;
  opacity: 0.2;
  visibility: visible;
  transition: all 0.25s ease;
  z-index: 1;
}

.related-properties li:hover a:before {
  opacity: 0.5;
  visibility: visible;
}

.related-properties li:hover .slick-arrow {
  opacity: 1;
  visibility: visible;
}

.related-properties li .content {
  position: absolute;
  left: 0;
  bottom: 20px;
  width: 100%;
  height: auto;
}

.related-properties li .content a {
  text-align: center;
}

.related-properties li .content a:before {
  display: none;
}

.related-properties li .content h5,
.related-properties li .content p {
  color: #fff;
}

.related-properties li .content h5 {
  font-size: 16px;
  font-weight: 400;
  font-family: Lato, sans-serif;
  line-height: 1.25em;
  margin-bottom: 2px;
}

.contact-agent .form_group input,
.contact-agent .form_group label,
.contact-agent .form_group textarea {
  color: #242429;
  line-height: 1.25em;
  font-weight: 700;
  font-family: Lato, sans-serif;
  width: 100%;
}

.related-properties li .content p {
  font-size: 16px;
  line-height: 1.25em;
}

.contact-agent {
  background-color: #f4f4f4;
  padding: 30px 0;
}

.contact-agent .container-sm {
  padding: 0;
}

@media (max-width: 1280px) {
  .contact-agent .container-sm {
    padding: 0 20px;
  }
}

.contact-agent .form_group {
  width: 100%;
  margin-bottom: 16px;
}

.contact-agent .form_group label {
  display: block;
  font-size: 15px;
}

.contact-agent .form_group input,
.contact-agent .form_group textarea {
  outline: 0;
  box-shadow: none;
  border: none;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  padding: 14px 0;
  border-radius: 0;
  font-size: 14px;
  background: 0 0;
}

.contact-agent .form_group input:focus,
.contact-agent .form_group textarea:focus {
  border-bottom: solid 2px #ff8c00;
}

.contact-agent .form_group textarea {
  height: 90px;
}

.contact-agent .form_group button {
  color: #fff;
  margin-bottom: 15px;
  font-size: 13px;
  font-weight: 400;
  padding: 16px 40px 14px;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  border-radius: 2px;
  line-height: 16px;
  border: none;
  background-color: #ff8c00;
  cursor: pointer;
  font-family: Effra-Medium;
}

.agentContact,
.call_popup {
  overflow-y: scroll;
  visibility: hidden;
  opacity: 0;
}

.contact-agent .form_group button:hover {
  background-color: #006994;
}

.call_popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
}

.call_popup.active {
  opacity: 1;
  visibility: visible;
  z-index: 999;
}

.call_popup .closeBtn {
  cursor: pointer;
  position: absolute;
  right: 50px;
  top: 50px;
}

.call_popup .wrapper {
  width: 500px;
  background-color: #fff;
  margin: 20px auto;
}

@media (max-width: 767px) {
  .call_popup .closeBtn {
    right: 25px;
    top: 25px;
  }

  .call_popup .wrapper {
    width: calc(100% - 30px);
    margin: 20px 15px;
  }

  #location iframe {
    width: 100%;
  }
}

.call_popup .head {
  padding: 15px 25px;
  background-color: #303030;
  color: #fff;
  font-weight: 400;
  font-family: Effra-Regular;
  font-size: 24px;
}

.call_popup .form_group input,
.call_popup .form_group label {
  color: #242429;
  font-weight: 700;
  font-family: Lato, sans-serif;
  width: 100%;
  line-height: 1.25em;
}

.call_popup .content {
  padding: 25px;
  background-color: #fff;
}

.call_popup .form_group {
  width: 100%;
  margin-bottom: 16px;
}

.call_popup .form_group label {
  display: block;
  font-size: 15px;
}

.call_popup .form_group input {
  outline: 0;
  box-shadow: none;
  border: none;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  padding: 14px 0;
  border-radius: 0;
  font-size: 14px;

  background: 0 0;
}

input {
  appearance: textfield;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}

.call_popup .form_group button,
.request-view button {
  background-color: #ff8c00;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.call_popup .form_group input:focus {
  border-bottom: solid 2px #ff8c00;
}

.call_popup .form_group button {
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  padding: 16px 40px 14px;
  overflow: hidden;
  border-radius: 2px;
  line-height: 16px;
  border: none;
  font-family: Effra-Medium;
  margin-bottom: 30px;
}

.call_popup .form_group button:hover {
  background-color: #006994;
}

#contact_agent form {
  display: flex;
  flex-wrap: wrap;
}

#contact_agent form .form_group {
  width: calc(50% - 15px);
  margin-right: 30px;
}

@media (max-width: 576px) {
  #contact_agent form .form_group {
    width: 100%;
    margin-right: 0;
  }
}

#contact_agent form .form_group:nth-of-type(2n) {
  margin-right: 0;
}

#contact_agent form .form_group:nth-last-of-type(2) {
  width: 100%;
  margin-right: 0;
}

.request-view h3,
.request-view p {
  color: #242429;
  margin: 1em 0;
  font-family: Lato, sans-serif;
}

#contact_agent form .form_group:last-of-type {
  width: 100%;
}

.request-view {
  background-color: #f4f4f4;
  padding: 60px 0;
}

.request-view .container-sm {
  max-width: 1170px;
  padding: 0;
}

@media (max-width: 1280px) {
  .request-view .container-sm {
    padding: 0 20px;
  }
}

@media (max-width: 767px) {
  .request-view .container-sm {
    padding: 0 15px;
  }
}

.request-view .wrapper {
  background: #fff;
  border-radius: 15px;
  padding: 10px 20px 30px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
}

.request-view .col {
  width: 50%;
}

.request-view .col:first-of-type {
  padding-right: 10px;
}

.request-view .col:nth-of-type(2) {
  padding-left: 10px;
}

@media (max-width: 767px) {
  .request-view .col {
    width: 100%;
  }

  .request-view .col:first-of-type {
    padding-right: 0;
  }

  .request-view .col:nth-of-type(2) {
    padding-left: 0;
  }
}

.request-view h3 {
  font-weight: 700;
  line-height: 1.25;
}

.request-view p {
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
}

.request-view input {
  width: 100%;
  border: 1px solid #ddd;
  box-shadow: none;
  border-radius: 4px;
  font-size: 15px;
  color: #333;
  padding: 11px 12px;
  background-color: #fff;
  line-height: 1.42857143;
  outline: 0;
  font-family: Lato, sans-serif;
}

.request-view .input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0 4px 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.request-view .input-date {
  display: flex;
}

.request-view .input-date input {
  border-radius: 4px 0 0 4px;
}

.request-view .form_group {
  margin-bottom: 15px;
}

.request-view label {
  font-weight: 600;
  margin-bottom: 5px;
  color: #242429;
  font-size: 14px;
  width: 100%;
  display: block;
  font-family: Lato, sans-serif;
}

.request-view button {
  color: #fff;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 400;
  padding: 18px 40px 14px;
  overflow: hidden;
  border-radius: 2px;
  line-height: 16px;
  border: none;
  font-family: Effra-Medium;
  width: 100%;
  display: none;
}

.request-confirmation p,
.request-view .listingaddress,
.request-view .pricesection .price,
.request-view .pricesection span {
  font-family: Lato, sans-serif;
  font-weight: 400;
}

.request-view button:hover {
  background-color: #006994;
}

.request-view .listingaddress {
  margin-top: 20px;
  font-size: 14px;
  color: #242429;
  display: flex;
  align-items: center;
}

.request-view .listingaddress svg path {
  fill: #242429;
}

.request-view .pricesection {
  padding: 10px 0;
}

.request-view .pricesection span {
  padding-right: 10px;
  margin-right: 10px;
  font-size: 17px;
  line-height: 1.25em;
  color: #666;
  border-right: 1px solid #ccc;
}

@media (max-width: 576px) {
  .request-view .pricesection span {
    font-size: 14px;
  }
}

.request-view .pricesection .price {
  font-size: 17px;
  color: #333;
  margin-right: 20px;
  line-height: 1.25em;
  padding-right: 0;
  border-right: none;
}

.request-view .pricesection .bathrooms_size {
  border-right: none;
}

.request-view .pricesection img {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  vertical-align: middle;
}

@media (max-width: 576px) {
  .request-view .pricesection .price {
    font-size: 14px;
  }

  .request-view .pricesection img {
    width: 15px;
    height: 16px;
  }
}

.request-view-slider {
  width: 100%;
  height: 333px;
  border-radius: 15px;
}

@media (max-width: 576px) {
  .request-view-slider {
    height: 270px;
  }
}

.request-view-slider .slick-arrow {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  z-index: 5;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: none;
  background: #fff;
  opacity: 0.75;
  border-radius: 50%;
  font-size: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.request-view-slider .slick-arrow.slick-next {
  right: 12px;
  left: auto;
}

.request-view-slider .slick-arrow.slick-next svg {
  transform: rotate(180deg);
}

.request-view-slider .slick-arrow:hover {
  background: #fff;
  opacity: 0.75;
}

.choose-listing .col_ .icon svg path,
.user-profile-cover .myBtn svg path {
  fill: #fff;
}

.request-view-slider .slick-list,
.request-view-slider .slick-slide,
.request-view-slider .slick-track,
.request-view-slider figure,
.request-view-slider img {
  height: 100%;
}

.request-view-slider figure,
.request-view-slider img {
  width: 100%;
  border-radius: 15px;
}

.request-view-slider img {
  object-fit: cover;
  aspect-ratio: 5/3;
}

.request-confirmation p {
  width: 100%;
  padding: 10px 15px;
  border-radius: 10px;
  margin: 0 0 10px;
  color: #242429;
  font-size: 14px;
  line-height: 26px;
}

@media (max-width: 767px) {
  .request-confirmation p {
    padding: 5px 0;
  }
}

.request-confirmation p:first-of-type {
  font-size: 18px;
}

.request-confirmation p:not(:first-of-type) strong {
  width: 106px;
  display: inline-block;
  text-align: left;
}

.user-profile-cover {
  background: #fff;
  border-bottom: 1px solid #ebebeb;
}

.user-profile-cover .listing-main-info {
  position: static;
  -webkit-transform: none;
  transform: none;
  background: #fff;
  padding: 25px 25px 0;
}

@media (max-width: 767px) {
  .user-profile-cover .listing-main-info {
    padding: 20px 10px 0;
  }
}

.user-profile-cover .container {
  max-width: 100%;
}

@media (min-width: 1450px) {
  .user-profile-cover .container {
    max-width: 1140px;
  }
}

.user-profile-cover .col-md-6 {
  width: 50%;
  padding: 0 10px;
}

@media (max-width: 767px) {
  .user-profile-cover .col-md-6 {
    width: 100%;
    padding: 10px;
  }
}

.user-profile-cover .profile-name {
  min-height: 80px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding-left: 90px;
  position: relative;
}

.user-profile-cover .profile-avatar {
  border-radius: 50%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 80px;
  height: 80px;
  background: 50% no-repeat #dde5e6;
  display: inline-block;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.user-profile-cover h1 {
  color: #242429;
  font-size: 34px;
  font-weight: 400;
  font-family: Effra-Regular;
}

@media (max-width: 767px) {
  .user-profile-cover .profile-name {
    justify-content: center;
  }

  .user-profile-cover h1 {
    font-size: 24px;
  }
}

.user-profile-cover ul {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  flex-wrap: nowrap;
  padding-left: 40px;
  margin-top: 1em;
}

.user-profile-cover ul li {
  margin-left: 35px;
}

@media (max-width: 767px) {
  .user-profile-cover ul {
    -webkit-box-pack: center;
    justify-content: center;
    padding-left: 0;
  }

  .user-profile-cover ul li:first-of-type {
    margin-left: 0;
  }
}

.user-profile-cover .lmb-label {
  font-size: 14px;
  opacity: 0.7;
  white-space: nowrap;
  font-family: Lato, sans-serif;
  color: #242429;
  font-weight: 400;
}

.agentContact h2,
.user-profile-cover .value {
  font-size: 20px;
  color: #242429;
  font-family: Lato, sans-serif;
}

.user-profile-cover .value {
  font-weight: 600;
}

.user-profile-cover .myBtn {
  background-color: #ff8c00;
  padding: 15px 17px;
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
  font-family: Lato, sans-serif;
  transition: all 0.25s ease;
}

.user-profile-cover .myBtn svg {
  margin-right: 7px;
}

.user-profile-cover .myBtn:hover {
  background-color: #006994;
}

.user-profile-cover .heading_center {
  margin-top: 25px;
  width: 100%;
  text-align: center;
}

.user-profile-cover .heading_center p {
  position: relative;
  color: #242429;
  font-weight: 600;
  font-family: Effra-Bold;
  font-size: 18px;
  line-height: 1em;
  padding-bottom: 7px;
}

.user-profile-cover .heading_center p:before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -20px;
  transform: translateX(-50%);
  width: 170px;
  height: 2px;
  /* background-color: #ff8c00; */
}

.author-listing {
  background-color: #f4f4f4;
  padding: 40px 0 50px;
}

.author-listing .container-sm {
  max-width: 1160px;
  padding: 0;
}

@media (max-width: 1280px) {
  .author-listing .container-sm {
    padding: 0 20px;
  }
}

@media (max-width: 767px) {
  .author-listing .container-sm {
    padding: 0 15px;
  }
}

.author-listing .related-properties {
  padding: 20px 0;
}

.author-listing .related-properties li {
  margin-bottom: 30px;
}

/* .author-listing .related-properties li:nth-of-type(4n) {
  margin-right: 0;
} */

@media (max-width: 767px) {
  .author-listing .related-properties li:nth-of-type(3n) {
    margin-right: 30px;
  }

  .author-listing .related-properties li:nth-of-type(2n) {
    margin-right: 0;
  }
}

@media (max-width: 576px) {
  .author-listing .related-properties li:nth-of-type(3n) {
    margin-right: 0;
  }
}

.agentContact {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
}

.agentContact.active {
  opacity: 1;
  visibility: visible;
  z-index: 999;
}

.agentContact .wrapper {
  width: 600px;
  background-color: #fefefe;
  margin: 50px auto;
  border: 1px solid #888;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.14);
  -webkit-box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
}

.agentContact .head {
  padding: 20px 30px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .agentContact .wrapper {
    width: calc(100% - 40px);
    margin: 40px 20px;
  }

  .agentContact .head {
    padding: 20px;
  }
}

.agentContact h2 {
  line-height: 1.25em;
}

.agentContact .close {
  color: #000;
  opacity: 0.2;
  text-shadow: 0 1px 0 #fff;
  line-height: 1;
  font-size: 28px;
  font-weight: 700;
  margin-top: -5px;
  margin-right: -10px;
  cursor: pointer;
}

.agentContact .close:hover {
  opacity: 1;
}

.agentContact .content {
  padding: 15px;
}

.agentContact form {
  display: flex;
  flex-wrap: wrap;
}

.agentContact .form_group {
  flex-basis: 50%;
  padding: 10px;
}

@media (max-width: 767px) {
  .agentContact .form_group {
    flex-basis: 100%;
  }
}

.agentContact .form_group.full {
  flex-basis: 100%;
}

.agentContact input,
.agentContact textarea {
  width: 100%;
  padding: 13px 16px;
  border: 1px solid #818a91;
  border-radius: 4px;
  margin-bottom: 5px;
  font-size: 16px;
  color: #222;
  outline: 0;
  font-family: Lato, sans-serif;
  line-height: 1.25em;
  font-weight: 400;
}

.agentContact textarea {
  height: 195px;
}

.agentContact button {
  color: #fff;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 400;
  padding: 16px 40px 14px;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  border-radius: 2px;
  line-height: 16px;
  border: none;
  background-color: #ff8c00;
  cursor: pointer;
  font-family: Effra-Medium;
}

.agentContact button:hover {
  background-color: #006994;
}

.add_listing {
  background-color: #f4f4f4;
  padding: 60px 0;
}

.choose-listing {
  height: 70vh;
}

.choose-listing h3 {
  width: 100%;
  text-align: center;
  color: #000;
  line-height: 1;
  font-weight: 400;
  font-family: Effra-Regular;
  margin-bottom: 60px;
}

.choose-listing .col_ h6,
.choose-listing .col_ p {
  font-size: 14px;
  font-family: Lato, sans-serif;
  font-weight: 400;
}

.choose-listing .col_ {
  margin: 0 10px 20px;
  height: 120px;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: all 1s linear;
  transition: all 1s linear;
  position: relative;
  width: calc(50% - 20px);
}

@media (max-width: 576px) {
  .choose-listing .col_ {
    width: 100%;
    margin: 10px 0;
  }
}

.choose-listing .col_ .face {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: 0.35s;
  transition: 0.35s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.choose-listing .col_ .front {
  background: #fff;
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
  border-radius: 5px;
  border: 1px solid #ebebeb;
  z-index: 2;
}

.choose-listing .col_ .back {
  box-sizing: border-box;
  background-color: #ff8c00;
  -webkit-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
  border-radius: 5px;
  color: #fff;
  text-align: center;
  z-index: 1;
}

.choose-listing .col_:hover .front {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
  z-index: 1;
}

.choose-listing .col_:hover .back {
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
  z-index: 2;
}

.choose-listing .col_ .icon {
  width: 40px;
  height: 40px;
  background-color: #ff8c00;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.choose-listing .col_ p {
  color: #242429;
  text-align: center;
  line-height: 1;
  margin-top: 10px;
}

.choose-listing .col_ h6 {
  color: #fff;
  margin-bottom: 0;
  line-height: 1em;
}

.multisteps .multistep-form {
  display: none;
  padding: 60px;
  background-color: #fff;
}

.multisteps h2 {
  color: #303030;
  font-size: 36px;
  line-height: 1.2em;
  font-family: Effra-Regular;
  font-weight: 400;
  margin-bottom: 40px;
}

.multisteps .input-wrap label,
.multisteps .tablist .number,
.multisteps .tablist li {
  font-family: Lato, sans-serif;
}

.multisteps .steps {
  display: block !important;
}

.multisteps .tablist {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.multisteps .tablist:before {
  content: "";
  position: absolute;
  height: 1px;
  background: #ededed;
  width: 100%;
  top: 40px;
  right: 0;
  left: 0;
  bottom: auto;
}

@media (max-width: 767px) {
  .multisteps .multistep-form {
    padding: 30px 20px;
  }

  .multisteps h2 {
    margin-bottom: 30px;
    font-size: 30px;
  }

  .multisteps iframe {
    width: 100%;
  }

  .multisteps .tablist:before {
    top: 30px;
  }
}

.multisteps .tablist li {
  width: auto;
  max-width: unset;
  position: relative;
  z-index: 2;
  background: 0 0;
  text-align: center;
  color: #303030;
  font-size: 16px;
  line-height: 1.4;
  flex-flow: column;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  cursor: pointer;
}

@media (max-width: 576px) {
  .multisteps .tablist li {
    font-size: 0;
  }
}

.multisteps .tablist li.active .number {
  background-color: #ff8c00;
  color: #fff;
}

.multisteps .tablist .number {
  height: 66px;
  width: 66px;
  display: inline-block;
  margin: 10px 10px 16px;
  padding: 0;
  background: #f4f4f4;
  text-align: center;
  border-radius: 100%;
  color: #c7c7c7;
  font-size: 24px;
  line-height: 60px;
  font-weight: 400;
  border: 3px solid #fff;
  position: relative;
}

@media (max-width: 767px) {
  .multisteps .tablist .number {
    width: 40px;
    height: 40px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.multisteps .tablist .number:before {
  content: "";
  position: absolute;
  left: -5px;
  right: -5px;
  top: -5px;
  bottom: -5px;
  z-index: 1;
  border-radius: 100%;
  border: 2px solid #f4f4f4;
  background: 0 0;
}

.multisteps .tablist .number:after {
  position: absolute;
  left: -20%;
  width: 140%;
  height: 100%;
  background: #fff;
  content: "";
  z-index: -1;
}

.multisteps .formWrappers {
  display: none;
  padding-top: 45px;
}

.multisteps .input-wrap label {
  margin-bottom: 15px;
  font-size: 16px;
  color: #303030;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  width: 100%;
}

.multisteps .input-wrap label span {
  font-size: 13px;
  opacity: 0.8;
  font-weight: 400;
}

.multisteps .input-wrap .form_group {
  width: 100%;
  margin-bottom: 20px;
}

.multisteps .optional,
div#stepsFours {
  margin-bottom: 40px;
}

.multisteps .input-wrap .form_group.half {
  width: 50%;
  padding-right: 20px;
}

@media (max-width: 576px) {
  .multisteps .input-wrap .form_group.half {
    width: 100%;
    padding-right: 0;
  }
}

.multisteps .input-wrap .form_group.pdrt0 {
  padding-right: 0;
}

.multisteps .input-wrap input,
.multisteps .input-wrap textarea {
  width: 100%;
  outline: 0;
  border: 1px solid #efefef;
  background-color: #fff;
  border-radius: 5px;
  color: #8e8e91;
  font-size: 16px;
  padding: 13px 15px;
  line-height: 1.25em;
  font-family: Lato, sans-serif;
}

.multisteps .input-wrap span,
.multisteps .optional label,
.multisteps .upload__btn-box p {
  font-size: 13px;
  font-family: Lato, sans-serif;
}

.multisteps .input-wrap textarea {
  height: 120px;
}

.multisteps .input-wrap span {
  opacity: 0.8;
  color: #242429;
  top: 5px;
  right: 28px;
}

.multisteps .optional label {
  opacity: 0.8;
  margin-bottom: 0;
  color: #242429;
  font-weight: 600;
  line-height: 1em;
}

.multisteps .optional .field {
  display: flex;
  flex-wrap: wrap;
}

.multisteps .optional .field .md-checkbox {
  margin: 0 25px 0 0;
  color: #303030;
  font-size: 15px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
}

@media (max-width: 576px) {
  .multisteps .optional .field .md-checkbox {
    margin: 7px 25px 7px 0;
  }
}

.multisteps .optional .field .md-checkbox input {
  width: 20px;
  margin: 0 10px 0 0;
  height: 20px;
  border: 2px solid #ccc !important;
}

.multisteps .button-wrapper {
  display: flex !important;
  justify-content: space-between;
  margin-top: 10px;
}

.multisteps .button-wrapper button {
  position: relative;
  cursor: pointer;
  padding: 14px 15px;
  border-radius: 5px;
  line-height: 1.25em;
  font-size: 16px;
  font-weight: 600;
  font-family: Lato, sans-serif;
  border: none;
  overflow: hidden;
  z-index: 0;
}

.multisteps .button-wrapper button:after {
  position: absolute;
  content: "";
  width: 100%;
  visibility: hidden;
  opacity: 0;
  left: -100%;
  top: 0;
  height: 100%;
  -webkit-transition: linear 0.25s;
  -o-transition: linear 0.25s;
  transition: linear 0.25s;
  background: #333;
  z-index: -1;
}

.multisteps .button-wrapper button:hover:after {
  left: 0;
  visibility: visible;
  opacity: 1;
}

.multisteps .button-wrapper .prev {
  background: #eee;
  color: #aaa;
}

.multisteps .button-wrapper .save {
  background: #ff8c00;
  color: #fff;
}

.multisteps .upload__btn {
  width: 100%;
  height: 100%;
  border: 2px dashed #ddd;
  background-color: #fff;
  border-radius: 0;
}

.multisteps .upload__box {
  padding: 0;
  width: 100%;
  position: relative;
  display: flex;
}

@media (max-width: 767px) {
  .multisteps .upload__box {
    flex-wrap: wrap;
  }
}

.multisteps .upload__box input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.multisteps .upload__btn-box {
  width: 280px;
  height: 120px;
}

.multisteps .upload__btn-box p {
  opacity: 0.8;
  font-weight: 400;
  line-height: 1.2em;
  color: #242429;
}

.multisteps label.upload__btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.multisteps .upload__img-wrap {
  width: calc(100% - 248px);
}

@media (max-width: 767px) {
  .multisteps .upload__img-wrap {
    width: 100%;
    margin-top: 45px;
  }

  .multisteps .upload__img-wrap .upload__img-box {
    width: 50%;
  }
}

.multisteps .select2,
.multisteps .selection {
  width: 100% !important;
}

.multisteps .select2-results__option[aria-selected] {
  font-size: 14px !important;
}

.multisteps .select2-container .select2-selection--multiple {
  min-height: 48px;
  border: 1px solid #efefef;
  opacity: 1;
}

.multisteps input.select2-search__field {
  width: 100% !important;
  margin-top: 0 !important;
  padding: 11px !important;
  font-size: 16px !important;
}

.multisteps .input-wrap input::placeholder,
.multisteps .input-wrap textarea::placeholder {
  color: #8e8e91;
  opacity: 1;
}

.multisteps .input-wrap input:-ms-input-placeholder,
.multisteps .input-wrap textarea:-ms-input-placeholder {
  color: #8e8e91;
}

.multisteps .input-wrap input::-ms-input-placeholder,
.multisteps .input-wrap textarea::-ms-input-placeholder {
  color: #8e8e91;
}

.multisteps input.select2-search__field::placeholder {
  color: #525252;
  opacity: 1;
}

.multisteps input.select2-search__field:-ms-input-placeholder {
  color: #525252;
}

.multisteps input.select2-search__field::-ms-input-placeholder {
  color: #525252;
}



.multisteps .select2,
.multisteps .selection {
  width: 100% !important;
}

.multisteps .select2-results__option[aria-selected] {
  font-size: 14px !important;
}

.multisteps .select2-container .select2-selection--multiple {
  min-height: 48px;
  border: 1px solid #efefef;
  opacity: 1;
}

.multisteps input.select2-search__field {
  width: 100% !important;
  margin-top: 0 !important;
  padding: 11px !important;
  font-size: 16px !important;
}

.multisteps .input-wrap input::placeholder,
.multisteps .input-wrap textarea::placeholder {
  color: #8e8e91;
  opacity: 1;
}

.multisteps .input-wrap input:-ms-input-placeholder,
.multisteps .input-wrap textarea:-ms-input-placeholder {
  color: #8e8e91;
}

.multisteps .input-wrap input::-ms-input-placeholder,
.multisteps .input-wrap textarea::-ms-input-placeholder {
  color: #8e8e91;
}

.multisteps input.select2-search__field::placeholder {
  color: #525252;
  opacity: 1;
}

.multisteps input.select2-search__field:-ms-input-placeholder {
  color: #525252;
}

.multisteps input.select2-search__field::-ms-input-placeholder {
  color: #525252;
}




.innerBanner2 {
  background-image: url("../../assets//images/mallorca-banner.jpg");
}


.head_back1 {
  background-image: url("../../assets//images/Palma-scaled-1.jpeg");

}

.head_back2 {
  background-image: url("../../assets//images/South-West-scaled-1.jpg");

}

.head_back3 {
  background-image: url("../../assets//images/South-East-scaled-1.jpg");

}

.head_back4 {
  background-image: url("../../assets//images/West-2-scaled-1.jpg");

}

.head_back5 {
  background-image: url("../../assets//images/the-north-bg.jpg");

}

.head_back6 {
  background-image: url("../../assets//images/the-north-bg.jpg");

}

.head_back7 {
  background-image: url("../../assets//images/Central-Mallorca-2.jpg");

}

.menu_active {
  border-top: solid 3px #ff8c00 !important;
  color: #000 !important;
}

.innerBanner_spanish {
  background-image: url("../../assets//images/english-buying-advise-banner-img.jpeg");
  /* filter: drop-shadow(0.2); */

}

.slider_brightness:hover {
  filter: brightness(0.5);
}

.loader_set {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.fav_add_btn {
  background-color: #f68c04;
  color: #fff;
  border-radius: 3px;
  padding: 5px;
  cursor: pointer;
}

.active_page {
  border: 1px solid black !important;
  font-size: 18px !important;
}

.slickArrow .slick-slider .slick-prev {
  left: 0px !important;
}

.slickArrow .slick-slider .slick-next {
  right: 0px !important;
  transform: translateX(6px) !important;
  top: 0 !important;
}

/* .slickArrow  .slick-arrow .slick-next::after {
    content:"s" !important;
  }
   
.slickArrow .slickArrow .slick-next::before {
  display: none;
} */


.listing_div {
  width: 400px !important;
}

.listing_main {
  display: flex;
  justify-content: space-around;
}



.search_input_width {
  width: 100%;
  height: 2rem;
  outline: none;
}

.taglist-list li div {
  display: flex;
  flex-direction: column;
}

.customtypedata {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.table_resp {
  width: 100%;
}

.bathrooms_size img {
  margin-top: 7px;

}


.agent_login {
  max-width: 750px !important;
  border: none !important;
  box-shadow: none !important;
}

.agent_login .wrapper {
  border: none !important;
}

.agent_login_heading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
}

.agent_login_heading h1 {
  color: #303030;
  font-family: "Effra Bold", Sans-serif;
  font-size: 35px;
  font-weight: bold;
  line-height: 1em;
}

.ser_btnR {
  color: white;
}

.pagination_div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
  padding: 2rem auto;
}

.pagination_div span,
button {
  background-color: #f68c04;
  color: white;
  border-radius: 5px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  text-align: center;
  border: 1px solid #bbb;
  min-width: 14px;
  padding: 0 7px;
  margin: 0 5px 0 0;
  border-radius: 3px;
  cursor: pointer;
}


.contact_btn {
  background-color: transparent;
}

.eng_desFont {
  font-size: 14px;
  line-height: 26px !important;
  padding: 2rem 0;
  border-bottom: 1px solid #ccc;
  color: #242429 !important;
  font-weight: 400 !important;
  padding-top: 0;
}

.forgot_section {
  padding: 70px 0
}

.forgot_section .container-sm {
  max-width: 400px
}


.forgot_section .container-sm {
  max-width: 600px
}

.forgot_section .wrapper {
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 20px 25px 40px;
  background-color: #fff
}

.heart_hover {
  width: 32px;
  height: 32px;
  display: block;
  border-radius: 50%;
  background: #f4f4f4;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: 14px;
}

.sub_success {
  display: inline-block;
  /* font-family: eicons; */
  font-weight: 300;
  font-style: normal;
  vertical-align: middle;
  margin-right: 5px;
}

.searchWrapper {
  border: none !important;
}

.multiSelectContainer li {
  cursor: pointer;
  font-size: 13px;
  padding: 5px auto !important;
  background-color: transparent !important;

  font-weight: 400;
}

.multiSelectContainer li:hover {
  cursor: pointer;
  font-size: 13px;
  padding: 5px auto !important;
  background-color: #999 !important;
  font-weight: 400;
}

.multiSelectContainer ul {
  border-top: none !important;
  border-radius: 0px !important;
  border: 1px solid #ccc;
}

.chip {
  background-color: white !important;
  border: 1px solid #ccc !important;
}

.closeIcon {
  cursor: pointer;
  float: right;
  height: 13px;
  margin-left: 5px;
  width: 13px;
  color: black;
  filter: brightness(0.5);
}

.multisteps .input-wrap span {
  padding: 1px 7px 3px !important;
}

.next_custom {
  right: 2px;
  background: url(../../assets/images/arrow-right.svg);
  border-radius: 0 8px 8px 0;
  border-left: 0;
  border: 1px solid red;
  height: 44px;
  width: 44px;
  z-index: 110;
  padding: 6px;
  /* position: absolute; */
  font-size: 0;
  background-size: 15px !important;
  border-width: 1px;
  border-style: solid;
  border-color: #222;
  border-image: initial;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  outline: 0;
  cursor: pointer;
}

.prev_custom {
  right: 2px;
  background: url(../../assets/images/arrow-left.svg);
  border-radius: 8px 0 0 8px;
  border-left: 0;
  border: 1px solid red;
  height: 44px;
  width: 44px;
  padding: 6px;
  z-index: 110;
  /* position: absolute; */
  font-size: 0;
  background-size: 15px !important;
  border-width: 1px;
  border-style: solid;
  border-color: #222;
  border-image: initial;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  outline: 0;
  cursor: pointer;

}


.pre_heading {
  font-size: 18px;
  margin: 40px 0 28px;
  /* margin-left: 1rem; */
  line-height: 1.25em;
  font-weight: 600;
  color: #242429;
  font-family: Lato, sans-serif;
}

.listingtitle2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slickArrow .slick-arrow.slick-next:before {
  content: url("../../assets//images/arrow-right2.svg");
  left: 14px !important;
  /* top: 22px; */
  height: 24px !important;
  width: 23px !important;
}

.slickArrow .slick-arrow.slick-prev:before {
  content: url("../../assets//images/arrow-left2.svg");
  left: 14px !important;
  /* top: 22px; */
  height: 24px !important;
  width: 23px !important;
}

.heart_hover:hover {
  color: #FF8C00 !important;
  cursor: pointer;
}

.agent_action {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.agent_action span img {
  cursor: pointer;

}

.agent_action span i {
  font-size: 25px;
  color: #FF8C00;
  cursor: pointer;
}



.login-section.registration .container-sm {
  max-width: 780px;
}

.login-section.registration h2 {
  font-size: 28px;
  font-weight: 400;
  text-align: center;
  font-family: Effra-Regular;
  color: #303030;
}

.login-section.registration .user-login {
  display: flex;
  flex-wrap: wrap;
}

.login-section.registration .user-login .form_group {
  width: calc(50% - 10px);
  margin-right: 20px;
}

.login-section.registration .user-login .form_group:nth-of-type(2n) {
  margin-right: 0;
}

.login-section.registration .user-login .form_group:last-of-type,
.login-section.registration .user-login .form_group:nth-last-child(2) {
  width: 100%;
}

.login-section.registration .user-login button {
  width: auto;
  padding: 12px 40px;
}

.login-section.registration .user-login textarea {
  width: 100%;
  border: 1px solid #efefef;
  padding: 12px 15px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4em;
  color: #666;
  border-radius: 4px;
  min-height: 190px;
}

.login-section.registration .user-login textarea:focus,
.login-section.registration .user-login textarea:hover {
  outline: 0;
}


.prograssWrapper {
  background-color: transparent !important;
  width: 2px !important;
  position: sticky;
  z-index: 1;
  height: 100%;
}

.prograssWrappermain {
  background-color: FF8C00 !important;
  width: 2px !important;
  z-index: 1;
}


.timeline .default-line {
  content: "";
  position: absolute;
  left: 50%;
  width: 4px;
  background: #bdc3c7;
  top: 100px;
}

.timeline .draw-line {
  width: 4px;
  height: 0;
  position: absolute;
  left: 50%;
  background: #ff8c00;
  top: 100px;
}

.ag-timeline_item.in-view .ag-timeline-card_point {
  background-color: #ff8c00;

}

.loading_div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 100%;
}


.scrollBtn {
  width: 100%;
  padding-right: 2rem;
  display: flex;
  justify-content: end;
  position: fixed;
  bottom: 1rem;
}

#stopbtn {
  display: none;
}

.sticky2 {
  display: inline-flex !important;
}

.btn_style {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0px 0px 3px black;
  cursor: pointer;
}

.btn_style:hover {
  background-color: #FF8C00;
  color: white;

}

.btn_style i {
  transform: rotate(-90deg);
  right: 2rem;
  padding-bottom: 1px;
}

.btn_style i:hover {
  color: white;
}

.wrapper2 {
  display: flex;
}

.back_home {
  padding: 0 0 2rem 0;
}

.back_home p {
  color: #000000;
  font-family: "Effra Fonts", Sans-serif;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.hover_effect:hover {
  filter: brightness(0.8);
}


.availableProperty .tableWrap table {
  width: 100%;
}

.availableProperty .propertyOuter {
  display: flex;
  align-items: center;
}

.availableProperty .propertyOuter .property-img {
  width: 103px;
  height: 70px;
  border-radius: 6px;
  overflow: hidden;
}

.availableProperty .propertyOuter .property-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.availableProperty .propertyOuter .property-name {
  width: calc(100% - 103px);
  padding-left: 10px;
  font-size: 16px;
  color: #303030;
  line-height: normal;
  font-family: 'Lato', sans-serif;
}

.availableProperty .tablesorter-blue td {
  vertical-align: middle;
}

.availableProperty .table-controls .btn {
  background: #f88c03;
  color: #fff;
  border-radius: 5px;
  padding: 7px 13px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  transition: all 0.25s ease;
}

.availableProperty .table-controls .btn:hover {
  background-color: #006994;
}

.availableProperty .table-controls .remove-property-btn {
  border: none;
  background: none;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
}

.availableProperty .table-controls .add-property-btn {
  color: #f88c03;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  border: none;
  background: none;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  font-family: "Effra-Medium";
  align-items: center;
  line-height: 1em;
}

.availableProperty .table-controls .add-property-btn .add-icon {
  background: #f88c03;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  font-size: 30px;
  position: relative;
  margin-right: 8px;
}

.availableProperty .table-controls .add-property-btn .add-icon i {
  font-size: 24px;
  font-style: normal;
  padding-top: 6px;
}

.set_btns {
  display: flex;
  justify-content: space-around;
}

.credit_design {
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  font-weight: 400;
  padding: 5px 0;
  color: #666;
}


.dropbtn2 {
  font-size: 16px;
  border: none;
}

.dropdown2 {
  position: relative;
  display: inline-block;
}

.dropdown-content2 {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 110px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 12222;
}

.dropdown-content2 a {
  color: #666 !important;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  -webkit-font-smoothing: antialiased !important;
  font-family: 'Lato', sans-serif;
  font-family: 'Lato', sans-serif !important;
  font-weight: 700 !important;
}

.fonts a {
  font-size: 14px;

}

.dropdown-content2 a:hover {
  background-color: #f68c04;
  color: white;
  transition: 0.3s;
}

.dropdown2:hover .dropdown-content2 {
  display: block;
}

/* //.dropdown2:hover .dropbtn2 {background-color: #3e8e41;} */

.clear_btn3 {
  position: relative;
  bottom: 2.5rem;
  left: 15rem;
  cursor: pointer;
}

.clear_btn4 {
  position: absolute;
  right: 1rem;
  top: 4px;
  cursor: pointer;

}

.clear_btn4:hover {
  color: #666;
}

.clear_btn3:hover {
  color: #666;
}


.sliderWrap {
  position: absolute;
  z-index: 22;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  flex-direction: column;
}

.prev_btn {
  position: absolute;
  left: 2rem;
}

.next_btn {
  position: absolute;
  right: 2rem;
}

.close_btn {
  position: relative;
  left: 38rem;
  cursor: pointer;
}

.fullscreen {}

.fullscreen img {
  width: 500px;
}

.delete_bt {
  position: relative;
  top: 2px;
  right: 26px;
  background: white;
  border-radius: 50%;
  font-size: 18px;
  cursor: pointer;
}

/* additional css add @D */
.listings .list-img-slider .slick-slider,
.listings .list-img-slider .slick-slider div {
  height: 100%;
}

.fillter-section form .formUnderForm,
.fillter-section form .formUnderForm .form_group {
  width: 100%;
}

.fillter-section form .formUnderForm .searchSelect {
  height: auto;
}

.fillter-section .select2.search_select {
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.fillter-section .select2.search_select .search_country {
  border-radius: 0px 0px 5px 5px;
  border: 1px solid #e4e4e4;
  padding: 10px;
  background: #fff;
  box-shadow: 2px 2px 6px 0px #0000002b;
}

.fillter-section .select2.search_select .search_country p {
  border-radius: 8px;
  border: 0;
  padding: 6px 12px;
  background: #fff;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  color: #000;
  transition: all 0.25s ease;
  font-weight: 400;
}

.fillter-section .select2.search_select .search_country p:hover {
  background: #eaeaea;
}

.home-banner .search_country2 {
  position: absolute;
  width: 70%;
  background: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  overflow-y: scroll;
  padding: 10px;
  box-shadow: 2px 2px 6px 0px #0000002b;
  max-height: 320px;
  z-index: 9999999999;
  top: 70px;
}

.home-banner .search_country2 p {
  text-align: left;
  border-radius: 8px;
  border: 0;
  padding: 6px 12px;
  margin: 0;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.25em;
  font-weight: 400;
  color: #000;
  font-family: 'Lato', sans-serif;
  transition: all 0.25s ease;
}

.home-banner .search_country2 p:hover {
  background: #eaeaea;
}


@media (max-width: 1200px) {
  .fillter-section form .formUnderForm {
    position: static;
    padding: 0;
    border-radius: 0;
  }
}

@media (max-width: 1024px) {

  .timeline .default-line,
  .timeline .draw-line {
    left: 23px;
  }
}

@media (max-width: 992px) {
  .dropdown.user_Heading {
    order: -1;
    margin-left: auto;
  }
}

@media (max-width: 767px) {
  .pagination_div {
    padding: 0 20px;
  }

  .pagination_div button {
    min-width: auto;
    margin: 0;
  }

  .pagination_div p {
    flex-wrap: wrap;
    padding: 0 15px;
  }

  .pagination_div p span {
    margin-bottom: 5px;
  }

  .timeline .default-line,
  .timeline .draw-line {
    top: 170px;
  }

  .comman-contents p a {
    word-break: break-all;
  }

  .property-tab {
    overflow-x: hidden;
  }
}

@media (max-width: 576px) {
  .login-section.registration .user-login .form_group {
    width: 100%;
    margin-right: 0;
  }
}



.clear_btn3 {
  position: relative;
  bottom: 2.5rem;
  left: 15rem;
  cursor: pointer;
}

.clear_btn4 {
  position: absolute;
  right: 1rem;
  top: 4px;
  cursor: pointer;

}

.clear_btn4:hover {
  color: #666;
}

.clear_btn3:hover {
  color: #666;
}



.sliderWrap {
  position: absolute;
  z-index: 22;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  flex-direction: column;
}

.prev_btn {
  position: absolute;
  left: 2rem;
  cursor: pointer;

}

.arrow_n {
  background-color: rgba(0, 0, 0, .25);
  align-items: center;
  display: flex;
  padding: 1px 4px;
  border-radius: 2px;

}

.close_btn span {
  /* background-color: rgba(0, 0, 0, .25); */
  align-items: center;
  display: flex;
  padding: 1px 4px;
  /* border-radius: 50%; */
}

.total_image {
  color: white;
  font-weight: 700;
  padding-left: 2rem;
}



.light_head {
  background-color: rgba(0, 0, 0, .25);
  position: absolute;
  top: 0;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.next_btn {
  position: absolute;
  right: 2rem;
  cursor: pointer;

}

.close_btn {
  position: absolute;
  /* left: 38rem; */
  cursor: pointer;
  top: 1rem;
  right: 2rem;
}

.fullscreen {}

.fullscreen img:hover,
.light_head {
  /* //width: 700px; */
  /* //background-color: rgba(0, 0, 0, .25); */
  /* transition: 0.3s; */

}

.right_btns {
  display: flex;
  justify-content: space-between;
  padding-right: 2rem;
}

.right_btns span {
  cursor: pointer;
}

.delete_bt {
  position: relative;
  top: 50px;
  right: 125px;
  background: white;
  border-radius: 50%;
  font-size: 18px;
  cursor: pointer;
}


.show_image {
  width: 100vw;
  height: 100vh;
  object-fit: contain;
}

/* additional css add @D */
.listings .list-img-slider .slick-slider,
.listings .list-img-slider .slick-slider div {
  height: 100%;
}

.fillter-section form .formUnderForm,
.fillter-section form .formUnderForm .form_group {
  width: 100%;
}

.fillter-section form .formUnderForm .searchSelect {
  height: auto;
}

.fillter-section .select2.search_select {
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.fillter-section .select2.search_select .search_country {
  border-radius: 0px 0px 5px 5px;
  border: 1px solid #e4e4e4;
  padding: 10px;
  background: #fff;
  height: 200px;
  overflow-y: auto;
  box-shadow: 2px 2px 6px 0px #0000002b;
  position: absolute;
  width: 100%;
  z-index: 99;
}

.fillter-section .select2.search_select .search_country p {
  border-radius: 8px;
  border: 0;
  padding: 6px 12px;
  background: #fff;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  color: #000;
  transition: all 0.25s ease;
  font-weight: 400;
}

.fillter-section .select2.search_select .search_country p:hover {
  background: #eaeaea;
}


@media (max-width: 1200px) {
  .fillter-section form .formUnderForm {
    position: static;
    padding: 0;
    border-radius: 0;
  }
}

@media (max-width: 1024px) {

  .timeline .default-line,
  .timeline .draw-line {
    left: 23px;
  }
}

@media (max-width: 992px) {
  .dropdown.user_Heading {
    order: -1;
    margin-left: auto;
  }
}

@media (max-width: 767px) {
  .pagination_div {
    padding: 0 20px;
  }

  .pagination_div button {
    min-width: auto;
    margin: 0;
  }

  .pagination_div p {
    flex-wrap: wrap;
    padding: 0 15px;
  }

  .pagination_div p span {
    margin-bottom: 5px;
  }

  .timeline .default-line,
  .timeline .draw-line {
    top: 170px;
  }

  .comman-contents p a {
    word-break: break-all;
  }

  .property-tab {
    overflow-x: hidden;
  }
}

@media (max-width: 576px) {
  .login-section.registration .user-login .form_group {
    width: 100%;
    margin-right: 0;
  }
}

.listing_div_res {
  width: 350px;
}

@media only screen and (max-width: 400) {
  .listing_div_res {
    width: 300px;

  }

  .listing_div {
    width: 300px;

  }

}

.backGround_click {
  position: absolute;
  width: 100%;
  height: 100vh;
  padding: 0;
  /* background: red; */
  z-index: 0;
  left: 0;
}

.customfiltersearch,
.propertytypesearch,
.result-fillter {
  z-index: 110;
}


.highlight {
  color: #000 !important;
}

.save-search-div {
  z-index: 11111 !important;
}

.kep-login-facebook {
  display: contents;
  background-color: transparent !important;
  padding: 1px 4px !important;
  font-family: Helvetica, sans-serif;
  font-weight: 400 !important;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  cursor: pointer;
  border: none !important;
  /* display: inline-block; */
  font-size: calc(.27548vw + 11.71074px);
  text-decoration: none;
  /* text-transform: uppercase; */
  transition: background-color .3s, border-color .3s;

}

.kep-login-facebook button {
  padding: 1px !important;
  background: transparent;
  border: none;
  font-size: 14px;
  font-weight: 500;
}

table {
  border: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  border-spacing: 0;
}

thead {
  height: 20px !important;
}


.leadReport .row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.leadReport .row p {
  font-size: 14px;
  line-height: 26px;
  color: #242429;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

.leadReport .row span {
  font-weight: 700;
  font-family: 'Lato', sans-serif;
  margin: 0 12px;
  font-size: 14px;
  line-height: 26px;
  color: #242429;
}

.leadReport .row .form-control {
  padding: 6px 12px;
  border: 1px solid #ddd;
  font-size: 13px;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  border-radius: 4px;
  color: #555;
}



.result-slider-wra .col_ {
  position: relative;
}

.result-slider-wra .listDataRight {
  position: absolute;
  right: 20px;
  top: -20px;
}

.result-slider-wra .heart_hover {
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.result-slider-wra .heart_hover i {
  font-size: 20px;
}

.result-slider-wra .listing_mai.result-slide>.slick-slider.slick-initialized>button {
  display: none !important;
}


.bottomPagenation {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.pagenationTable {
  width: calc(100% - 180px);
  display: flex;
  justify-content: center;
}

.pagenationTable button {
  border: 1px solid transparent;
  margin-left: 2px;
  font-size: 14px;
  line-height: 1.25em;
  font-weight: 400;
  background-color: transparent;
  border-radius: 2px;
  color: #666;
  padding: .5em 1em;
  font-family: 'Lato', sans-serif;
  margin-right: 0;
  transition: all 0.25s ease;
  align-self: flex-start;
}

.pagenationTable p {
  align-self: flex-start;
}

.pagenationTable span {
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  cursor: pointer;
  color: #333;
  border: 1px solid transparent;
  border-radius: 2px;
  font-size: 14px;
  line-height: 1.25em;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  transition: all 0.25s ease;
}

.pagenationTable span:hover,
.pagenationTable button:hover {
  border: 1px solid #111;
  color: #fff;
  background: linear-gradient(to bottom, #585858 0%, #111 100%);
}

.pagenationTable span.active_page {
  background: #f88c03;
  border-color: #f88c03 !important;
  color: #fff;
  font-size: 14px !important;
}

.home-slider {
  height: 100vh;
  width: 100%;
  filter: brightness(0.7);
}



.pagination_div.second span,
.pagination_div.second button {
  border: 1px solid #d0d1d5 !important;
  font-size: 13px;
  background-color: transparent;
  color: #484848;
  border-radius: 50px;
  padding: 7px 15px !important;
  line-height: 1.5em;
  transition: all 0.25s ease;
  cursor: pointer;
}

.pagination_div.second span {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.pagination_div.second span.active_page {
  border: 1px solid #e5e5e5 !important;
  background-color: #e5e5e5 !important;
  color: #484848 !important;
}

.pagination_div.second span:hover,
.pagination_div.second button:hover {
  border: 1px solid #ff8c00 !important;
  background-color: #ff8c00 !important;
  color: #fff !important;
}

.active_page2 {
  background-color: #ff8c00 !important;
  color: #fff !important;
}


.submitActivebtn {
  display: block !important;
}

.Map_postion {
  position: relative;
  height: 400px;
}

.garage_logo i {
  font-size: 40px;
  color: navy;
  opacity: 0.6;
}

/* new css start here 24th Jan */
.home-banner .slick-slider,
.home-banner .slick-slide div {
  height: 100%;
}
ul.otherLogin li {
  padding: 0 !important;
}
.author-listing .related-properties li {
  width: calc(33.33% - 20px);
}
.bookmark-slider {
  height: 320px;
}
.bookmark-slider .slick-slider {
  height: 100%;
}
.bookmark-slider .slick-slider div {
  height: 100%;
}
.multisteps .input-wrap input.location-search-input {
  margin-bottom: 20px;
}
#stepsThrees .map_setpostion {
  margin-bottom: 60px;
}
.related-properties .related_pop_slider .bookmark-slider_mob{
  height: 100%;
}
.related-properties .related_pop_slider .bookmark-slider_mob .slick-list{
  border-radius: 5px;
}


@media (max-width: 1180px) {
  .blog-list .date{
    position: static;
  }
}

@media (max-width:767px) {
  .news-section figure img {
    object-fit: cover;
  }

  .blog-list .date {
    position: static;
  }

  .blog-list.second .readMore {
    margin-top: 15px;
  }

  .choose-listing {
    height: 100%;
  }

  .pagination_div span,
  button {
    font-size: 13px !important;
    margin-bottom: 0 !important;
  }

  .pagination_div button.active_page,
  .pagination_div span.active_page {
    font-size: 15px !important;
  }

  .dataTables_info {
    width: 100%;
  }

  .pagenationTable {
    width: 100%;
  }

  .pagenationTable button,
  .pagenationTable span {
    padding: 0.5em;
  }

  .bookmark-section .loader_set {
    display: none;
  }

  .buyingAdvice .notes p br {
    display: none;
  }

  .leadReport .row {
    flex-wrap: wrap;
  }

  .leadReport .row .col-md-8,
  .leadReport .row .col-md-4 {
    width: 100%;
  }

  .leadReport .row .form-control {
    width: 100%;
  }

  .leadReport .row .form-control:first-of-type {
    margin-top: 10px;
  }

  #menuFive .container {
    overflow-x: scroll;
  }
  .listDataRight.uper_side{
    z-index: 999;
  }
  /* .fillter-section{
    overflow: hidden;
  } */
  .author-listing .related-properties li {
    width: calc(50% - 20px);
  }
  .user-profile-cover .profile-name{
    padding-left: 0;
  }
  .pagination_div.second {
    padding: 0;
    flex-direction: column;
  }
  .pagination_div.second p{
    margin: 10px 0;
  }
  .otherLogin .content .kep-login-facebook {
    font-size: 16px !important;
  }

}

@media (max-width:576px) {
  .home-banner .tab-wrapper {
    max-height: inherit;
  }
  .author-listing .related-properties li {
    width: 100%;
    margin-right: 0;
  }
}