.home-img {
  max-width: 100%;
}

.image {
  display: none;
}

.imgWrapper {
  width: 100vh;
  height: 100vh;
}

@keyframes blender {
  0% {
    opacity: 0;
    transform: translate(-25px);
  }

  30% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translate(-10px);
  }
}

.imageActive {
  float: left;
  width: 150vw;
  height: 100vh;
  object-fit: cover;
  box-sizing: border-box;
  opacity: 0;
  animation: blender 15s ease-in-out 50ms;
}














.flex-width {
  width: 100%;
}


.active_one {

  color: #3a3a3a !important;
  background-color: #fff;
  border-radius: 5px;

}


.blog_back {
  background-image: url("../assets/images/blog-banner-img.jpg")
}

.about_back {
  background-image: url("../assets/images/about-banner-img.jpg")
}

.tgg_add {
  display: block;
}


@media only screen and (max-width: 750px) {
  .flex_resp {
    flex-direction: column;
  }

  .flex_resp li {
    width: 100% !important;
  }

}


*,
ul {
  margin: 0;
  padding: 0;
}

.bg-control,
.explore-section,
.home-service,
.login-section {
  background-repeat: no-repeat;
}

.listings a:hover,
a,
a:hover {
  text-decoration: none;
}

/* 
.bookmark-section .bookmark-slider .slick-arrow:before,
.listings .list-img-slider .slick-arrow:before,
body,
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Lato, sans-serif;
}

.about-content .wrapper,
.about-service h3,
.about-service li,
.bannerTab button,
.blogDetails li,
.contact-popup .head,
.getInTouch-popup .head,
.home-banner .banner-content,
.home-service li a,
.inner-heading,
.list_box li a,
footer .btns_member,
footer .copyright p {
  text-align: center;
}


* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.luxy-el,
.slick-slider .slick-list,
.slick-slider .slick-track,
.swiper-container,
.swiper-slide,
.swiper-wrapper,
[data-aos],
[data-jarallax-element] {
  will-change: transform;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body.active {
  overflow: hidden;
}

li {
  list-style: none;
}

img {
  max-width: 100%;
  vertical-align: top;
}

.relative {
  position: relative;
  width: 100%;
}

.color-white * {
  color: #fff;
}

.container-lg {
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
  padding: 0 17px;
}

.container {
  max-width: 1300px;
  margin: 0 auto;
}

.container-sl {
  max-width: 1520px;
  margin: 0 auto;
}

.container-sm {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 20px;
}

figure,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

p {
  font-weight: 600;
}

body,
h3 {
  font-weight: 700;
}

.bg-control {
  background-size: cover;
}

.slick-slide {
  outline: 0;
}

figure img {
  width: 100%;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.4);
}

::-webkit-scrollbar-thumb {
  background: #fff;
}

a,
span {
  display: inline-block;
}

body {
  -webkit-text-size-adjust: none;
  position: relative;
  width: 100%;
  font-size: 18px;
  color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #ff8c00;
}

h1 {
  font-size: 41px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 21px;
}

h5 {
  font-size: 19px;
}

h6 {
  font-size: 18px;
}

p {
  line-height: 1.55em;
  font-size: 18px;
}

.btn,
a,
li {
  color: #000;
}

.btn {
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

@media (max-width: 1440px) {
  .container {
    max-width: 1180px;
  }
}

@media (max-width: 1280px) {
  .container {
    max-width: 1000px;
  }
}

@media (max-width: 1024px) {
  .container {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .container {
    padding: 0 18px;
  }
}

@media (max-width: 1200px) {
  .container-sm {
    max-width: 100%;
    padding: 0 40px;
  }
}

@media (max-width: 992px) {
  .container-sm {
    padding: 0 20px;
  }
}

@media (max-width: 767px) {
  .container-sm {
    padding: 0 15px;
  }
}

@media (max-width: 1600px) {
  .container-sl {
    max-width: 1300px;
  }
}

@media (max-width: 1440px) {
  .container-sl {
    max-width: 1200px;
  }
}

@media (max-width: 1366px) {
  .container-sl {
    max-width: 1160px;
  }
}

@media (max-width: 1280px) {
  .container-sl {
    max-width: 1060px;
  }
}

@media (max-width: 1140px) {
  .container-sl {
    max-width: 900px;
  }
}

@media (max-width: 992px) {
  .container-sl {
    max-width: 90%;
  }
}

@media (max-width: 768px) {
  .container-sl {
    max-width: 100%;
    padding: 0 40px;
  }
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pd72 {
  padding: 0 72px;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex.nowrap {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.flex.column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
}

.flex.space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.flex.space-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.flex.space-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.flex.space-evenly {
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
}

.flex.space-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.flex.align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex.align-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.flex.align-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.bannerTab .form_group:first-of-type {
  width: 75%;
  padding-right: 10px;
}

@media (max-width: 576px) {
  .bannerTab form {
    flex-wrap: wrap;
  }

  .bannerTab .form_group:first-of-type {
    width: 100%;
    padding-right: 0;
    margin-bottom: 10px;
  }
}

.bannerTab .form_group:nth-of-type(2) {
  width: 23%;
}

@media (max-width: 992px) {
  .bannerTab .form_group:nth-of-type(2) {
    width: 25%;
  }
}

@media (max-width: 576px) {
  .bannerTab .form_group:nth-of-type(2) {
    width: 100%;
  }
}

.bannerTab input {
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  line-height: 18px;
  color: #1d1d23;
  font-family: Lato, sans-serif;
  padding: 15px;
}

.bannerTab input:focus,
.bannerTab input:hover {
  outline: 0;
}

.bannerTab button {
  background-color: #ff8c00;
  color: #fff;
  transition: all 0.25s ease;
  font-size: 18px;
  line-height: 1.25em;
  border-radius: 5px;
  padding: 12px;
  width: 100%;
  border: 1px solid #ff8c00;
  cursor: pointer;
  font-family: Effra-Medium;
  font-weight: 500;
}

.bannerTab button:hover {
  background-color: #006994;
  border: 1px solid #006994;
}

.bannerTab input::placeholder {
  color: #1d1d23;
  opacity: 1;
}

.bannerTab input:-ms-input-placeholder {
  color: #1d1d23;
}

.bannerTab input::-ms-input-placeholder {
  color: #1d1d23;
}

.newsletterForm .form_group:first-of-type {
  width: calc(100% - 160px);
}

@media (max-width: 767px) {
  .newsletterForm {
    flex-wrap: wrap;
  }

  .newsletterForm .form_group {
    margin-bottom: 10px;
  }

  .newsletterForm .form_group:first-of-type {
    width: 100%;
  }
}

.newsletterForm .form_group:nth-of-type(2) {
  width: 160px;
}

.newsletterForm input {
  width: 100%;
  background-color: #fff;
  border: none;
  border-radius: 5px 0 0 5px;
  font-size: 16px;
  line-height: 1.25em;
  color: #999;
  padding: 20px;
  font-weight: 300;
  font-family: Poppins, sans-serif;
  letter-spacing: 0.1px;
}

.contact-popup button,
.newsletterForm .sub-btns,
.user-login button {
  background-color: #ff8c00;
  cursor: pointer;
  transition: all 0.25s ease;
}

.contact-popup button,
.contact-popup input,
.contact-popup label,
.contact-popup textarea,
.newsletterForm .sub-btns {
  font-family: Lato, sans-serif;
}

@media (max-width: 767px) {
  .newsletterForm .form_group:nth-of-type(2) {
    width: 100%;
  }

  .newsletterForm input {
    border-radius: 5px;
  }
}

.newsletterForm input:focus,
.newsletterForm input:hover {
  outline: 0;
}

.newsletterForm .sub-btns {
  color: #fff;
  font-size: 15px;
  line-height: 1.25em;
  border-radius: 0 5px 5px 0;
  padding: 20px 40px;
  border: 1px solid #ff8c00;
  font-weight: 500;
}

.contact-popup input,
.contact-popup label,
.contact-popup textarea,
.user-login label {
  line-height: 1.7em;
  font-size: 16px;
  letter-spacing: 0.1px;
}

@media (max-width: 767px) {
  .newsletterForm .sub-btns {
    width: 100%;
    border-radius: 5px;
  }
}

.newsletterForm .sub-btns:hover {
  background-color: #006994;
  border: 1px solid #006994;
}

.newsletterForm input::placeholder {
  color: #999;
  letter-spacing: 0.1px;
  opacity: 1;
}

.newsletterForm input:-ms-input-placeholder {
  color: #999;
  letter-spacing: 0.1px;
}

.newsletterForm input::-ms-input-placeholder {
  color: #999;
  letter-spacing: 0.1px;
}

.contact-popup form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contact-popup .form_group {
  width: 100%;
  margin-bottom: 20px;
}

.contact-popup .form_group.half {
  width: 47.6%;
}

.contact-popup .form_group:last-of-type {
  margin-bottom: 0;
}

.contact-popup input,
.contact-popup textarea {
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  color: #999;
  padding: 9px 16px;
  font-weight: 400;
  border: 1px solid #818a91;
}

.contact-popup input:focus,
.contact-popup input:hover,
.contact-popup textarea:focus,
.contact-popup textarea:hover {
  outline: 0;
}

.contact-popup textarea {
  resize: none;
  height: 122px;
}

.contact-popup #subscribe {
  width: auto;
}

.contact-popup label {
  font-weight: 400;
  color: #4d5471;
}

.contact-popup button {
  color: #fff;
  font-size: 16px;
  line-height: 1.25em;
  border-radius: 4px;
  padding: 13px 30px;
  border: 1px solid #ff8c00;
  font-weight: 500;
  width: 100%;
  margin-top: 10px;
}

.user-login .col label,
.user-login input,
.user-login label {
  font-weight: 400;
  font-family: Roboto, sans-serif;
}

.contact-popup button:hover {
  background-color: #006994;
  border: 1px solid #006994;
}

.contact-popup input::placeholder {
  color: #999;
  opacity: 1;
}

.contact-popup input:-ms-input-placeholder {
  color: #999;
}

.contact-popup input::-ms-input-placeholder {
  color: #999;
}

.contact-popup textarea::placeholder {
  color: #999;
  opacity: 1;
}

.contact-popup textarea:-ms-input-placeholder {
  color: #999;
}

.contact-popup textarea::-ms-input-placeholder {
  color: #999;
}

.user-login {
  width: 100%;
  margin-top: 40px;
}

.user-login .form_group {
  width: 100%;
  margin-bottom: 20px;
}

.user-login .form_group.flex {
  justify-content: space-between;
  align-items: center;
}

.user-login .form_group:last-of-type {
  margin-bottom: 0;
}

.user-login sup {
  color: red;
  line-height: 1em;
}

.user-login label {
  width: 100%;
  margin-bottom: 6px;
  color: #4d5471;
  display: block;
}

.user-login input {
  width: 100%;
  border: 1px solid #efefef;
  padding: 12px 15px;
  font-size: 16px;
  line-height: 1.4em;
  color: #666;
  border-radius: 4px;
}

.user-login input:focus,
.user-login input:hover {
  outline: 0;
}

.user-login .col {
  display: flex;
  align-items: center;
}

.user-login .col label {
  width: auto;
  font-size: 15px;
  line-height: 1.2em;
  letter-spacing: 0.1px;
  color: #4d5471;
  display: block;
  margin-bottom: 0;
  margin-left: 7px;
}

.home-banner .banner-content h1,
.home-banner .tab li,
.user-login button {
  font-family: Effra-Bold;
}

.user-login .col input {
  width: 16px;
  height: 16px;
}

.user-login button {
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4em;
  letter-spacing: 0;
  border: 1px solid transparent;
  color: #fff;
  padding: 12px 20px;
}

.user-login button:hover {
  background-color: #006994;
}

.editProfile .form_group {
  width: 100%;
}

.editProfile .form_group:nth-of-type(1),
.editProfile .form_group:nth-of-type(2) {
  margin-bottom: 30px;
}

.editProfile .form_group.headingTop {
  border: 1px solid silver;
  padding: 0.35em 0.625em 0.75em;
  position: relative;
  margin-bottom: 20px;
}

.editProfile .form_group.headingTop label {
  width: auto;
  font-size: 14px;
  border-bottom: 2px solid #999;
  font-weight: 600;
  margin-bottom: 20px;
  color: #242429;
  display: inline-block;
  position: absolute;
  top: -10px;
  padding: 0 5px 6px;
  background-color: #fff;
}

.editProfile .form_group.headingTop p,
.editProfile label {
  font-weight: 400;
  font-family: Lato, sans-serif;
}

.editProfile .newImg .dltImg,
.editProfile button {
  background-color: #ff8c00;
  transition: all 0.25s ease;
  cursor: pointer;
}

.editProfile .form_group.headingTop p {
  color: #333;
  font-size: 11px;
  line-height: 15px;
  margin-top: 10px;
  margin-bottom: 22px;
}

.editProfile label {
  display: block;
  color: #242429;
  font-size: 14px;
  line-height: 1.2em;
}

.editProfile input {
  width: 100%;
  font-weight: 600;
  color: #222;
  font-size: 14px;
  line-height: 1.2em;
  border: none;
  outline: 0;
}

.editProfile .placeHolder {
  width: 135px;
  height: 135px;
  border: 2px dashed #ddd;
  margin-top: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.editProfile .placeHolder .filesInput {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.editProfile .newImg {
  margin-top: 33px;
  margin-left: 15px;
  height: 135px;
  width: 135px;
  position: relative;
}

.editProfile .newImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid silver;
}

.editProfile .newImg .dltImg {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  bottom: 10px;
  opacity: 0;
  visibility: hidden;
}

.editProfile .newImg .dltImg img {
  width: auto;
  height: auto;
  border: none;
  border-radius: 50%;
}

.editProfile .newImg:hover .dltImg {
  opacity: 1;
  visibility: visible;
}

.editProfile .passWrap {
  margin-top: 45px;
}

.editProfile .inputWrap {
  position: relative;
  margin-bottom: 15px;
}

.editProfile .inputWrap input {
  padding: 14px 0;
  color: #222;
  font-size: 14px;
}

.editProfile .inputWrap input:focus~span {
  top: -9px;
}

.editProfile .inputWrap span {
  position: absolute;
  left: 0;
  top: 2px;
  color: #242429;
  font-size: 14px;
  font-weight: 400;
  font-family: Lato, sans-serif;
  transition: all 0.25s ease;
}

.editProfile button {
  font-weight: 500;
  font-family: Effra-Medium;
  padding: 14px 40px;
  border-radius: 5px;
  font-size: 13px;
  line-height: 1.2em;
  color: #fff;
  border: none;
  width: 100%;
}

.editProfile button:hover {
  background-color: #006994;
}

.getInTouch-popup form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.getInTouch-popup .form_group {
  width: 100%;
  margin-bottom: 20px;
}

.getInTouch-popup .form_group.half {
  width: 47.6%;
}

.getInTouch-popup .form_group:last-of-type {
  margin-bottom: 0;
}

.getInTouch-popup input,
.getInTouch-popup textarea {
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  color: #999;
  padding: 9px 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7em;
  letter-spacing: 0.1px;
  font-family: Lato, sans-serif;
  border: 1px solid #818a91;
}

.getInTouch-popup input:focus,
.getInTouch-popup input:hover,
.getInTouch-popup textarea:focus,
.getInTouch-popup textarea:hover {
  outline: 0;
}

.getInTouch-popup textarea {
  resize: none;
  height: 122px;
}

.getInTouch-popup #subscribe {
  width: auto;
}

.getInTouch-popup label {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7em;
  letter-spacing: 0.1px;
  font-family: Lato, sans-serif;
  color: #4d5471;
}

.getInTouch-popup button {
  background-color: #ff8c00;
  color: #fff;
  transition: all 0.25s ease;
  font-size: 16px;
  line-height: 1.25em;
  border-radius: 4px;
  padding: 13px 30px;
  border: 1px solid #ff8c00;
  cursor: pointer;
  font-family: Lato, sans-serif;
  font-weight: 500;
  width: 100%;
  margin-top: 10px;
}

.getInTouch-popup button:hover {
  background-color: #006994;
  border: 1px solid #006994;
}

.getInTouch-popup input::placeholder {
  color: #999;
  opacity: 1;
}

.getInTouch-popup input:-ms-input-placeholder {
  color: #999;
}

.getInTouch-popup input::-ms-input-placeholder {
  color: #999;
}

.getInTouch-popup textarea::placeholder {
  color: #999;
  opacity: 1;
}

.getInTouch-popup textarea:-ms-input-placeholder {
  color: #999;
}

.getInTouch-popup textarea::-ms-input-placeholder {
  color: #999;
}

.home-banner {
  width: 100%;
  height: 100vh;
  position: relative;
}

.home-banner .home-slider {
  width: 100%;
  height: 100%;
}

.home-banner .slick-list,
.home-banner .slick-slide,
.home-banner .slick-track {
  height: 100%;
}

.home-banner figure {
  height: 100%;
  position: relative;
}

.home-banner figure::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(1, 1, 1, 0.26);
}

.home-banner img {
  height: 100%;
  object-fit: cover;
}

.home-banner .banner-content {
  width: 750px;
  position: absolute;
  left: 50%;
  top: 50%;
  background: 0 0;
  transform: translate(-50%, -50%);
}

@media (max-width: 767px) {
  .home-banner .banner-content {
    width: 100%;
    padding: 0 15px;
  }
}

.home-banner .banner-content h1 {
  font-weight: 700;
  line-height: 1.2em;
  color: #fff;
  display: inherit;
  margin: 0 0 1.25em;
}

.home-banner .tab {
  display: flex;
  width: 500px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.26);
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
}

.home-banner .tab-wrapper .tab-content,
header.home figure .blue-logo {
  display: none;
}

@media (max-width: 576px) {
  .home-banner .banner-content h1 {
    font-size: 30px;
  }

  .home-banner .tab {
    width: 100%;
  }
}

.home-banner .tab li {
  cursor: pointer;
  padding: 12px 30px;
  color: #fff;
  font-size: 16px;
  line-height: 1.25em;
  font-weight: 600;
}

@media (max-width: 576px) {
  .home-banner .tab li {
    padding: 10px 18px;
    font-size: 14px;
  }
}

.home-banner .tab li.active {
  color: #3a3a3a;
  background-color: #fff;
  border-radius: 5px;
}

.home-banner .tab-wrapper {
  border-radius: 7px;
  margin-top: 20px;
  background-color: #fff;
  padding: 20px;
}

.home-banner .tab-wrapper h2 {
  line-height: 1.7em;
  font-size: 28px;
  font-weight: 400;
  font-family: Roboto, sans-serif;
  color: #303030;
}

.home-banner .downBtn {
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
}

.home-banner .downBtn a {
  width: 60px;
  height: 60px;
}

.innerBanner {
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 522px;
}

.center-heading,
.loader,
footer .social-icons {
  justify-content: center;
}

.loader {
  background-color: #fff;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 9999;
  position: fixed;
}

.typewriter {
  width: 24%;
}

.typewriter h2 {
  color: #ff8c00;
  font-size: 40px;
  font-family: Effra-Bold;
  font-family: monospace;
  overflow: hidden;
  border-right: 0.15em solid orange;
  white-space: nowrap;
  margin: 0 auto;

  letter-spacing: 0.15em;
  animation: typing 3.5s steps(30, end), blink-caret 0.5s step-end infinite;
}

.heading-line h3,
.inner-heading h3 {
  font-family: Effra-Regular;
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes blink-caret {

  from,
  to {
    border-color: transparent;
  }

  50% {
    border-color: orange;
  }
}

.heading-line {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .heading-line {
    flex-direction: column;
    margin-bottom: 20px;
  }
}

.heading-line h3 {
  color: #303030;
  font-weight: 400;
  line-height: 1.25em;
  margin-bottom: 30px;
  display: inline-block;
  position: relative;
  padding-top: 17px;
}

/* .heading-line h3::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 3px;
  width: 80px;
  background-color: #ff8c00;
} */

.heading-line a {
  font-family: Lato, sans-serif;
  font-size: 15px;
  line-height: 1.5em;
  font-weight: 500;
  color: #fff;
  background-color: #ff8c00;
  transition: all 0.25s ease;
  border-radius: 3px;
  padding: 12px 24px;
  align-self: flex-start;
  margin-top: 10px;
}

.center-heading h1,
.inner-heading h1 {
  font-family: Effra-Medium;
}

@media (max-width: 767px) {
  .heading-line h3 {
    margin-bottom: 8px;
  }

  .heading-line a {
    margin: 10px 0 10px auto;
  }
}

.heading-line a:hover {
  background-color: #006994;
}

.center-heading h1::before,
.inner-heading h1:before {
  width: 80px;
  /* background-color: #ff8c00; */
  transform: translateX(-50%);
  top: 0;
  content: "";
  left: 50%;
}

.inner-heading h1 {
  font-size: 48px;
  font-weight: 600;
  color: #fff;
  line-height: 1.2em;
  padding-top: 13px;
  display: inline-block;
  position: relative;
  margin-bottom: 15px;
}

.inner-heading h1:before {
  position: absolute;
  height: 3px;
}

.inner-heading h3 {
  color: #fff;
  letter-spacing: 0.1px;
  font-weight: 400;
  line-height: 1.25em;
}

.center-heading {
  display: flex;
}

.center-heading h1 {
  color: #303030;
  font-size: 48px;
  font-weight: 600;
  line-height: 1.25em;
  margin-bottom: 30px;
  display: inline-block;
  position: relative;
  padding-top: 12px;
}

@media (max-width: 767px) {
  .center-heading h1 {
    margin-bottom: 8px;
  }
}

.center-heading h1::before {
  position: absolute;
  height: 3px;
}

header {
  background-color: #fff;
  z-index: 99;
  position: static;
  left: 0;
  top: 0;
  width: 100%;
  padding: 13px 20px 13px 23px;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 992px) {
  header {
    justify-content: unset;
  }
}

header .container_ {
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@media (min-width: 1450px) {
  header .container_ {
    max-width: 1100px;
    margin: 0 auto;
  }
}

header.home {
  background-color: transparent;
  position: absolute;
}

header figure .blue-logo,
header.home figure .white-logo {
  display: block;
}

header.home li a {
  color: #fff;
}

header.home .loginBtn {
  border: 2px solid #ff8c00;
  background-color: #ff8c00;
  color: #fff;
  margin-right: 15px;
}

header.home .loginBtn:hover {
  background-color: #006994;
  color: #fff;
  border: 2px solid #006994;
}

header figure {
  width: 187px;
  height: 31px;
}

@media (max-width: 576px) {
  header {
    padding: 20px 15px;
  }

  header figure {
    width: 130px;
    height: 28px;
  }
}

header figure .white-logo {
  display: none;
}

header li {
  margin: 0 15px;
}

header li a {
  color: #303030;
  font-family: Lato, sans-serif;
  font-weight: 700;
  font-size: 17px;
  line-height: 1.4em;
}

footer h6,
header .loginBtn {
  font-family: Effra-Medium;
}

@media (max-width: 992px) {
  header .container_ {
    justify-content: unset;
  }

  header figure {
    order: -1;
  }

  header li {
    margin: 0;
    padding: 10px 20px;
  }

  header li a {
    color: #494c4f !important;
  }
}

header nav {
  position: relative;
}

header .loginBtn {
  border: 2px solid #303030;
  background-color: transparent;
  align-self: flex-start;
  padding: 8px 8px 6px;
  font-size: 15px;
  line-height: 21px;
  border-radius: 4px;
  font-weight: 500;
  color: #303030;
  margin-right: 35px;
  transition: all 0.25s ease;
}

@media (max-width: 576px) {
  header .loginBtn {
    padding: 5px;
    font-size: 13px;
  }
}

header .loginBtn:hover {
  background-color: #ff8c00;
  color: #fff;
  border: 2px solid #ff8c00;
}

@media (max-width: 992px) {
  header .loginBtn {
    order: -1;
    margin-left: auto;
    margin-right: 0;
  }

  header .menus {
    display: none;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
    min-width: 290px;
    border: 1px solid #ddd;
    position: absolute;
    background-color: #fff;
    z-index: 99;
    right: -15px;
  }

  header .menus ul {
    flex-direction: column;
  }
}

header .toogle-btn {
  display: none;
  background: #fff !important;
  border: 1px solid #eee;
  margin-left: 28px;
  border-radius: 3px;
  padding: 0.25em;
  width: 40px;
  height: 38px;
  cursor: pointer;
  position: relative;
}

header.dashboard-header,
header.dashboard-header-white {
  padding: 22px 60px 22px 35px;
}

@media (max-width: 992px) {
  header .toogle-btn {
    display: block;
  }
}

@media (max-width: 576px) {
  header .toogle-btn {
    margin-left: 10px;
    height: 34px;
  }
}

header .toogle-btn.active span {
  transform: rotate(45deg);
  top: 19px;
}

header .toogle-btn.active span:nth-of-type(2) {
  display: none;
}

header .toogle-btn.active span:nth-of-type(3) {
  transform: rotate(-45deg);
  top: 20px;
}

header .toogle-btn span {
  position: absolute;
  top: 10px;
  width: 75%;
  height: 2px;
  background-color: #4d5471;
  left: 4px;
}

@media (max-width: 576px) {
  header .toogle-btn span {
    top: 7px;
  }
}

header .toogle-btn span:nth-of-type(2) {
  top: 18px;
}

@media (max-width: 576px) {
  header .toogle-btn span:nth-of-type(2) {
    top: 16px;
  }
}

header .toogle-btn span:nth-of-type(3) {
  top: 25px;
}

@media (max-width: 576px) {
  header .toogle-btn span:nth-of-type(3) {
    top: 24px;
  }
}

header.dashboard-header {
  background-color: navy;
}

header.dashboard-header figure .white-logo {
  display: block;
}

header.dashboard-header figure .blue-logo {
  display: none;
}

header.dashboard-header li a {
  color: #fff;
}

header.dashboard-header .loginName {
  color: #fff;
  font-size: 17px;
  font-family: Lato, sans-serif;
  letter-spacing: 0.1px;
  font-weight: 700;
  position: relative;
  padding-right: 20px;
}

footer li i,
footer li:hover a {
  color: #ff8c00;
}

footer li a,
footer li:hover i {
  color: #4d5471;
}

header.dashboard-header .loginName:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0;
  border-color: #fff transparent transparent;
  right: 0;
  top: 50%;
  left: auto;
  transform: translateY(-50%);
}

footer {
  background-color: #f9f9f9;
  padding: 50px 0 40px;
}

footer .footer-logo {
  margin: 10px 0 40px;
  width: 202px;
  height: 33.33px;
}

footer .col {
  width: 25%;
}

footer .col:nth-of-type(1) {
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  footer .col {
    width: 50%;
  }

  footer .col:nth-of-type(2) {
    margin-bottom: 30px;
  }
}

footer h6 {
  font-weight: 500;
  font-size: 19px;
  line-height: 1.25em;
  color: #303030;
  padding-bottom: 13px;
  position: relative;
}

/* footer h6:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 80px;
  height: 1px;
  background-color: #ff8c00;
} */

footer ul {
  margin-top: 17px;
}

footer li {
  margin-bottom: 8px;
}

footer li i {
  font-size: 14px !important;
  padding-right: 10px;
  transition: all 0.25s ease;
}

footer li a {
  font-size: 14px;
  line-height: 24px;
  font-family: Lato, sans-serif;
  font-weight: 400;
  transition: all 0.25s ease;
}

footer .btns,
footer .btns_member {
  font-size: 15px;
  line-height: 1.25em;
  font-family: Lato, sans-serif;
  transition: all 0.25s ease;
}

footer .btns {
  border-radius: 5px;
  border: 2px solid #ff8c00;
  padding: 12px 24px;
  color: #ff8c00;
  font-weight: 400;
  margin-top: 10px;
}

footer .btns:hover,
footer .btns_member {
  color: #fff;
  background-color: #ff8c00;
}

footer .btns_member {
  border-radius: 5px;
  border: 2px solid #ff8c00;
  padding: 8px 24px;
  font-weight: 400;
  width: 90%;
}

.contact-popup .head h3,
.home-service h3,
footer .copyright p {
  font-family: Effra-Regular;
  font-weight: 400;
  line-height: 1.25em;
}

@media (max-width: 576px) {
  footer .btns_member {
    padding: 8px 20px;
    width: 100%;
  }
}

footer .btns_member:hover {
  background-color: #fff;
  color: #ff8c00;
}

footer .wrapper {
  padding: 10px 0 50px;
}

footer .copyright {
  padding: 10px;
}

footer .copyright p {
  margin-bottom: 20px;
  color: #303030;
  font-size: 14px;
}

footer .social-icons li {
  margin: 0 7px;
}

footer .social-icons a {
  width: 42px;
  height: 42px;
  border: 2px solid #080754;
  border-radius: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}

footer .social-icons a:hover {
  border: 2px solid #ff8c00;
}

footer .social-icons a:hover i {
  color: #ff8c00;
}

footer .social-icons i {
  color: #080754;
  font-size: 21px !important;
  padding-right: 0;
}

footer .bottom-links {
  justify-content: center;
  margin-top: 30px;
}

footer .bottom-links li {
  padding: 0 6px;
  position: relative;
}

footer .bottom-links li:after {
  content: "";
  position: absolute;
  right: 0;
  top: 7px;
  width: 1px;
  height: 12px;
  background-color: #4d5471;
}

footer .bottom-links li:last-of-type:after {
  display: none;
}

footer .bottom-links li a {
  font-size: 12px;
  line-height: 1.25em;
}

.contact-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 999;
  overflow-y: scroll;
  display: none;
}

.contact-popup.active {
  display: block;
}

.contact-popup .wrapper {
  width: 500px;
  background-color: #fff;
  position: relative;
  margin: 40px auto 20px;
}

.contact-popup .close {
  background-color: #ff8c00;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -25px;
  top: -25px;
  cursor: pointer;
  transition: all 0.25s ease;
}

.contact-popup .close i {
  color: #fff;
}

.contact-popup .close:hover {
  background-color: #006994;
}

.contact-popup .head {
  background-color: #e4e4e4;
  padding: 15px 25px;
}

.contact-popup .head h3 {
  color: #4d5471;
}

.contact-popup .content {
  background-color: #fff;
  padding: 40px;
}

.home-service {
  padding: 100px 0;
}

@media (max-width: 767px) {
  .home-service {
    padding: 50px 0;
  }
}

.home-service li {
  padding: 40px 46px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  width: calc(33.33% - 13.33px);
  margin-right: 20px;
  border-radius: 5px;
}

@media (max-width: 576px) {
  .home-service li {
    padding: 40px;
  }
}

.home-service li:nth-of-type(3n) {
  margin-right: 0;
}

@media (max-width: 767px) {
  .home-service li {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .home-service li:last-of-type {
    margin-bottom: 0;
  }
}

.home-service figure {
  margin: 0 auto 15px;
  width: 72px;
  height: 72px;
}

.home-service h3 {
  color: #303030;
  margin: 1em 0;
  display: inherit;
}

.home-service p {
  color: #4d5471;
  font-size: 17px;
  line-height: 1.7em;
  font-family: Lato, sans-serif;
  font-weight: 400;
  letter-spacing: 0.1px;
  margin-bottom: 20px;
}

.who-we-are .accordian h4,
.who-we-are .tab li {
  font-family: Effra-Medium;
  font-weight: 400;
  cursor: pointer;
}

.who-we-are {
  background-color: #d2e3e6;
  padding: 100px 0;
}

.who-we-are .col:first-of-type {
  width: 20%;
}

@media (max-width: 767px) {
  .who-we-are {
    padding: 50px 0;
  }

  .who-we-are .heading-line {
    margin-bottom: 10px;
  }

  .who-we-are .col:first-of-type {
    width: 100%;
  }
}

.who-we-are .col:nth-of-type(2) {
  width: 80%;
}

.who-we-are .tab {
  display: flex;
}

@media (max-width: 767px) {
  .who-we-are .col:nth-of-type(2) {
    width: 100%;
  }

  .who-we-are .tab {
    display: none;
  }
}

.who-we-are .tab li {
  padding: 20px 25px;
  color: #303030;
  font-size: 14px;
  line-height: 1.25em;
}

@media (max-width: 1200px) {
  .who-we-are .tab li {
    padding: 20px 19px;
  }
}

@media (max-width: 1024px) {
  .who-we-are .tab li {
    padding: 20px 12px;
  }
}

.who-we-are .tab li.active {
  color: #ff8c00;
}

.who-we-are .tab-wrapper {
  padding-top: 20px;
  padding-left: 20px;
  display: block;
}

.who-we-are .accordian,
.who-we-are .tab-wrapper .tab-content {
  display: none;
}

.who-we-are .tab-wrapper h2 {
  line-height: 1.7em;
  font-size: 28px;
  font-weight: 400;
  font-family: Roboto, sans-serif;
  color: #303030;
}

@media (max-width: 1024px) {
  .who-we-are .tab-wrapper h2 {
    font-size: 24px;
  }
}

@media (max-width: 767px) {
  .who-we-are .tab-wrapper {
    display: none;
  }

  .who-we-are .accordian {
    display: block;
  }
}

.who-we-are .accordian h4 {
  padding: 10px;
  color: #303030;
  font-size: 14px;
  line-height: 1.25em;
}

.who-we-are .accordian h2 {
  line-height: 1.7em;
  font-size: 18px;
  font-weight: 400;
  font-family: Roboto, sans-serif;
  color: #303030;
  padding: 10px;
}

.who-we-are .accordian .box.active .head h4 {
  color: #ff8c00;
}

.who-we-are .accordian .content {
  display: none;
}

.explore-section {
  padding: 100px 0;
}

.explore-section li {
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  width: calc(33.33% - 13.33px);
  margin-right: 20px;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .explore-section {
    padding: 50px 0;
  }

  .explore-section li {
    width: 100%;
    margin-right: 0;
  }
}

.explore-section li:nth-child(3n) {
  margin-right: 0;
}

.explore-section li:nth-of-type(4) {
  width: calc(50% - 10px);
}

.explore-section li:nth-of-type(4) figure {
  height: 378px;
}

@media (max-width: 992px) {
  .explore-section li:nth-of-type(4) figure {
    height: 340px;
  }
}

@media (max-width: 767px) {
  .explore-section li:nth-of-type(4) {
    margin-right: 0;
    width: 100%;
  }

  .explore-section li:nth-of-type(4) figure {
    height: 324px;
  }
}

@media (max-width: 576px) {
  .explore-section li:nth-of-type(4) figure {
    height: 210px;
  }
}

.explore-section li:nth-of-type(5) {
  width: calc(50% - 10px);
  margin-right: 0;
}

.explore-section li:nth-of-type(5) figure {
  height: 378px;
}

@media (max-width: 992px) {
  .explore-section li:nth-of-type(5) figure {
    height: 340px;
  }
}

@media (max-width: 767px) {
  .explore-section li:nth-of-type(5) {
    margin-right: 0;
    width: 100%;
  }

  .explore-section li:nth-of-type(5) figure {
    height: 324px;
  }

  .explore-section .heading-line a {
    width: auto;
  }
}

@media (max-width: 576px) {
  .explore-section li:nth-of-type(5) figure {
    height: 210px;
  }
}

.explore-section a {
  border-radius: 5px;
  height: 100%;
}

.explore-section figure {
  width: 100%;
  height: 213px;
  border-radius: 5px;
  position: relative;
}

@media (max-width: 767px) {
  .explore-section a {
    width: 100%;
  }

  .explore-section figure {
    height: 324px;
  }
}

@media (max-width: 576px) {
  .explore-section figure {
    height: 210px;
  }
}

.explore-section figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.explore-section figure:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-image: linear-gradient(300deg, #00699400 0, #000 71%);
  opacity: 0.2;
  border-radius: 5px;
}

.explore-section figure .figcaption {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 2em;
}

.explore-section figure .figcaption h4 {
  padding: 0.5em 0;
  font-size: 20px;
  font-weight: 400;
  font-family: Effra-Medium;
  line-height: 1.7em;
  letter-spacing: 0.1px;
  color: #fff;
  position: relative;
}

.news-section h4,
.news-section h4 a,
.newsletter h3 {
  font-family: Effra-Regular;
  color: #303030;
  line-height: 1.25em;
  font-weight: 400;
}

@media (max-width: 576px) {
  .explore-section figure .figcaption h4 {
    padding: 0;
  }
}

.explore-section figure .figcaption h4:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 3px;
  background-color: #fff;
  transition: all 0.25s ease;
}

.explore-section figure:hover .figcaption h4:after {
  width: 100%;
}

.newsletter {
  background-color: #d2e3e6;
  padding: 100px 0;
}

.newsletter h3 {
  width: calc(100% - 642px);
  font-size: 24px;
}

@media (max-width: 992px) {
  .newsletter h3 {
    width: calc(100% - 550px);
  }
}

@media (max-width: 767px) {
  .newsletter {
    padding: 50px 0;
  }

  .newsletter .container-sm {
    padding: 0;
  }

  .newsletter h3 {
    width: 100%;
    margin-bottom: 15px;
    text-align: center;
  }
}

.newsletter .newsletterForm {
  width: 642px;
  display: flex;
}

@media (max-width: 992px) {
  .newsletter .newsletterForm {
    width: 550px;
    padding-left: 30px;
  }
}

@media (max-width: 767px) {
  .newsletter .newsletterForm {
    width: 100%;
    padding-left: 0;
  }
}

.newsletter .wrapper {
  align-items: center;
}

.news-section {
  padding: 60px 0;
}

.news-section li {
  width: calc(33.33% - 20px);
  margin-right: 30px;
  border-radius: 5px;
  border: 1px solid #e4e4e4;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  transition: all 0.35s ease;
}

.news-section li:hover {
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
}

.news-section li:last-of-type {
  margin-right: 0;
}

.news-section figure {
  width: 100%;
  height: 232px;
}

@media (max-width: 767px) {
  .news-section li {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }

  .news-section li:last-of-type {
    margin-bottom: 0;
  }

  .news-section figure {
    height: 278px;
  }
}

@media (max-width: 576px) {
  .news-section figure {
    height: 175px;
  }
}

.news-section figure a,
.news-section figure img {
  width: 100%;
  height: 100%;
}

.news-section .content {
  padding: 30px 30px 0;
}

.news-section h4 {
  margin-bottom: 25px;
}

.news-section .read_more,
.news-section p {
  font-family: Roboto, sans-serif;
}

.news-section p {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.7em;
  color: #777;
  letter-spacing: 0.1px;
}

.news-section .read_more {
  margin: 25px 0;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  color: #ff8c00;
}

.news-section .date {
  border-top: 1px solid #eaeaea;
  padding: 15px 30px;
  width: 100%;
  margin-top: auto;
}

.news-section .date p {
  font-family: Effra-Regular;
  font-weight: 400;
  line-height: 1.3em;
  font-size: 12px;
  color: #adadad;
}

.about-content p,
.blog-list .tag {
  font-family: Lato, sans-serif;
}

.about-content {
  padding: 85px 0;
}

.about-content .container-sm {
  max-width: 833px;
}

.about-content p {
  margin-bottom: 1em;
  letter-spacing: 0.1px;
  line-height: 1.7em;
  font-weight: 400;
  font-size: 16px;
  color: #4d5471;
}

.about-service h3,
.about-service li h3 {
  color: #303030;
  font-weight: 400;
  font-family: Effra-Regular;
  line-height: 1.25em;
}

.about-content p:last-of-type {
  margin-bottom: 0;
}

.about-service h3 {
  margin-bottom: 50px;
  margin-top: 10px;
}

.about-service ul {
  padding-bottom: 100px;
}

.about-service li {
  width: calc(33.33% - 13.33px);
  margin-right: 20px;
  padding: 40px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.19);
  border-radius: 6px;
}

.about-service li:nth-of-type(3n) {
  margin-right: 0;
}

.about-service li h3 {
  margin: 1em 0;
}

.about-service li figure {
  margin: 0 auto 15px;
  width: 72px;
  height: 72px;
}

.blog-list {
  padding: 80px 0;
  background-color: #f4f4f4;
}

.blog-list li {
  margin-bottom: 45px;
  background-color: #f6f6f6;
}

.blog-list .content,
.blog-list.second,
.list_box li a,
.login-section .wrapper {
  background-color: #fff;
}

.blog-list figure {
  width: 50%;
  height: 375px;
}

.blog-list figure a,
.blog-list figure img {
  height: 100%;
  width: 100%;
}

.blog-list figure img {
  object-fit: cover;
  border-radius: 10px;
}

.blog-list .content {
  width: 50%;
  padding: 30px;
  margin: 30px 30px 30px -30px;
  z-index: 1;
  border-radius: 10px;
  box-shadow: 0 1px 25px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 1px 25px rgba(0, 0, 0, 0.05);
}

.blog-list .tag {
  color: #006994;
  padding: 6px 10px;
  border-radius: 2px;
  background-color: #e4e4e4;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.25em;
  font-weight: 400;
}

.blog-list .tag:hover,
.blog-list h3 {
  color: #303030;
}

.blog-list h3,
.blog-list p {
  margin: 1em 0 20px;
  font-weight: 400;
}

.blog-list h3 {
  font-family: Effra-Regular;
  line-height: 1.25em;
  padding-bottom: 12px;
  position: relative;
}

.blog-list h3:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 25%;
  background-color: #006994;
}

.blog-list p {
  font-family: Lato, sans-serif;
  font-size: 14px;
  line-height: 1.7em;
  letter-spacing: 0.1px;
  padding-right: 10px;
}

.blog-list .date {
  line-height: 1.3em;
  font-size: 12px;
  color: #adadad;
  font-family: Effra-Medium;
  font-weight: 400;
  margin: 0;
}

.blog-list .date i {
  padding-right: 5px;
  font-weight: 500;
}

.blogBanner {
  padding: 45px 0 50px;
}

.blogBanner .container-sm {
  padding: 0;
}

.blogBanner h1 {
  margin-bottom: 20px;
  padding: 10px;
  font-weight: 600;
  color: #303030;
  font-size: 27px;
  line-height: 1em;
  font-family: Effra-Bold;
}

.blogDetails h3,
.blogDetails p {
  font-family: Lato, sans-serif;
}

.blogBanner figure,
.blogBanner img {
  border-radius: 40px;
  width: 100%;
}

.blogBanner figure {
  height: 470px;
}

.blogBanner figure img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.blogDetails {
  padding-bottom: 35px;
}

.blogDetails .container-sm {
  max-width: 870px;
  padding: 0;
}

.blogDetails .col:first-of-type {
  width: 11%;
  padding: 10px 30px 10px 10px;
}

.blogDetails .col:nth-of-type(2) {
  width: 89%;
  padding: 10px;
}

.blogDetails li {
  margin-bottom: 25px;
}

.blogDetails li svg {
  width: 22px;
  height: 22px;
}

.blogDetails li svg path {
  fill: #006994;
}

.blogDetails li a:hover svg path {
  fill: #ff8c00;
}

.blogDetails h3,
.blogDetails p {
  margin: 1em 0;
}

.blogDetails p {
  color: #4d5471;
  font-size: 16px;
  line-height: 1.7em;
  font-weight: 400;
  letter-spacing: 0.1px;
}

.blogDetails p a {
  transition: all 0.25s ease;
  color: #006994;
}

.blogDetails p a:hover {
  color: #ff8c00;
}

.blogDetails h3 {
  font-weight: 700;
  color: #242429;
  line-height: 1.25em;
}

.blog-list.second .container-sm {
  padding: 10px;
}

.blog-list.second h2 {
  margin-bottom: 50px;
  padding-top: 35px;
  border-top: solid 1px #e4e4e4;
  color: #303030;
  font-weight: 400;
  font-size: 24px;
  line-height: 1em;
  font-family: Effra-Regular;
}

.blog-list.second .readMore {
  padding: 10px;
  background-color: #006994;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  border-radius: 3px;
  transition: all 0.3s;
  color: #fff;
  font-size: 15px;
  line-height: 1em;
}

.comman-contents ol li,
.comman-contents p {
  font-size: 16px;
  line-height: 27px;
  font-family: Lato, sans-serif;
  font-weight: 400;
}

.blog-list.second .readMore:hover {
  background-color: #ff8c00;
}

.comman-contents {
  padding: 60px 0;
  background-color: #f4f4f4;
}

.comman-contents h3 {
  margin: 1em 0;
  color: #242429;
  padding-top: 60px;
  font-family: Lato, sans-serif;
}

.comman-contents p {
  color: #4d5471;
  margin: 1em 0;
}

.comman-contents p a {
  color: #000;
}

.comman-contents p a:hover {
  color: #ff8c00;
}

.comman-contents ol {
  margin: 1em 0;
  padding-left: 50px;
}

.comman-contents ol li {
  list-style: decimal;
  color: #4d5471;
}

.list_box li {
  width: calc(50% - 10px);
  margin-right: 20px;
  margin-bottom: 20px;
}

.list_box li:nth-of-type(2n) {
  margin-right: 0;
}

.list_box li a {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid #e4e4e4;
  padding: 20px;
}

.list_box li a i {
  color: #303030;
  font-size: 40px;
  transition: all 0.25s ease;
}

.list_box li a:hover h5,
.list_box li a:hover i {
  color: #ff8c00;
}

.list_box li h5,
.login-section h3 {
  color: #303030;
  font-family: Effra-Regular;
}

.list_box li h5 {
  margin-top: 15px;
  font-size: 24px;
  line-height: 1.2em;
  font-weight: 400;
  transition: all 0.25s ease;
}

.login-section {
  padding: 70px 0;
}

.login-section .container-sm {
  max-width: 600px;
}

.login-section .wrapper {
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 20px 25px 40px;
}

.login-section h3 {
  font-weight: 400;
  margin-bottom: 20px;
}

.login-section a,
.login-section p {
  color: #4d5471;
  line-height: 1.7em;
  letter-spacing: 0.1px;
  font-weight: 400;
  font-size: 16px;
}

.login-section a,
.login-section p,
.login-section.signUp p a,
.otherLogin .content p,
.user-dashboard .left-sidebar a {
  font-family: Lato, sans-serif;
}

.login-section p {
  padding-bottom: 30px;
  border-bottom: 1px solid #eee;
}

.login-section a:hover {
  color: #ff8c00;
}

.login-section.signUp p a {
  font-weight: 600;
}

.otherLogin {
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 20px;
}

.otherLogin a {
  display: flex;
  background-color: #4285f4;
  border-radius: 3px;
  padding: 1px;
  min-width: 235px;
  align-items: center;
}

.otherLogin .icon {
  width: 38px;
  height: 38px;
  background-color: #fff;
  border-radius: 3px;
  padding: 7px;
}

.otherLogin .content {
  padding-left: 12px;
}

.otherLogin .content p {
  border: none;
  padding: 0;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #fff;
}


.otherLogin li:nth-of-type(2) a svg {
  width: 100%;
  height: 100%;
}

.user-dashboard {
  position: relative;
}

.user-dashboard .left-sidebar {
  background-color: #f4f4f4;
  padding-left: 20px;
  width: 25%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.user-dashboard .left-sidebar ul {
  background-color: #f8f9fb;
  padding: 30px 20px 20px;
  height: 100%;
}

.user-dashboard .left-sidebar li {
  margin-bottom: 5px;
}

.user-dashboard .left-sidebar li:not(:last-of-type) a svg path {
  /* fill: #b2b2b2; */
}

.request-view-slider .slick-arrow svg path,
.user-dashboard .left-sidebar li.active a svg path {
  /* fill: #303030; */
}

.user-dashboard .left-sidebar li.active a {
  border-left: solid 4px #ff8c00;
}

.user-dashboard .left-sidebar a {
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 1.25em;
  color: #4d5471;
  font-weight: 400;
  padding: 10px 0 10px 10px;
}

.buying-advice li h4,
.user-dashboard .addProp-btn a {
  font-family: Effra-Regular;
  color: #fff;
}

.user-dashboard .left-sidebar a:hover {
  color: #ff8c00;
}

.user-dashboard .left-sidebar a svg {
  margin-right: 7px;
  width: 28px;
}

.user-dashboard .main-content {
  width: calc(100% - 25%);
  padding: 30px;
  margin-left: auto;
}

.user-dashboard .main-content .main-wrapper {
  display: none;
}

.user-dashboard .addProp-btn {
  text-align: right;
}

.user-dashboard .addProp-btn a {
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4em;
  letter-spacing: 0;
  background-color: #ff8c00;
  border: 1px solid transparent;
  padding: 12px 30px;
  cursor: pointer;
  transition: all 0.25s ease;
}

.user-dashboard .addProp-btn a:hover {
  background-color: #006994;
}

.available-section {
  padding: 30px 0 20px;
}

.available-section li {
  width: calc(33.33% - 20px);
  margin-right: 30px;
  border-radius: 4px;
  border: 1px solid #006994;
  padding: 25px;
  display: flex;
  align-items: center;
}

.available-section li:nth-of-type(3n) {
  margin-right: 0;
}

.available-section p {
  color: #4d5471;
  font-weight: 400;
  line-height: 1.4em;
  font-size: 16px;
  font-family: Effra-Medium;
  width: calc(100% - 90px);
}

.available-section h2 {
  width: 90px;
  text-align: right;
  font-size: 46px;
  color: #000;
  font-weight: 600;
  font-family: Lato, sans-serif;
}

.service-sec {
  padding: 10px 0 30px;
}

.service-sec li {
  background-color: #f4f4f4;
  border-radius: 4px;
  width: calc(33.33% - 20px);
  margin-right: 30px;
  padding: 35px 20px 35px 35px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.service-sec li:nth-of-type(3n) {
  margin-right: 0;
}

.service-sec svg {
  filter: drop-shadow(-40px 4px 1px #ebebeb);
}

.service-sec p {
  color: #4d5471;
  font-weight: 400;
  line-height: 1.4em;
  font-size: 16px;
  font-family: Effra-Medium;
  padding-left: 25px;
}

.availableProperty h2,
.contact-info a,
.contact-info p {
  font-family: Lato, sans-serif;
}

.contact-info {
  padding: 35px 0 10px;
}

.contact-info .col {
  width: 50%;
}

.contact-info .col:first-of-type a {
  color: #ff8c00;
}

.contact-info h2 {
  color: #000;
  font-size: 24px;
  line-height: 1.25em;
  margin-bottom: 15px;
}

.contact-info li,
.contact-info p {
  margin-bottom: 7px;
}

.contact-info a,
.contact-info p {
  color: #4d5471;
  font-weight: 400;
  line-height: 1.4em;
  font-size: 16px;
}

.contact-info i {
  margin-right: 10px;
}

.contact-info a:hover {
  color: #ff8c00;
}

.availableProperty h2 {
  font-size: 30px;
  font-weight: 800;
  letter-spacing: 1px;
  width: 100%;
  margin: 10px 0;
  color: #303030;
}

.availableProperty .tableFilter {
  justify-content: space-between;
  align-items: center;
}

.availableProperty .tableFilter .dataTables_length {
  align-items: center;
  color: #666;
  font-size: 14px;
  line-height: 1.25em;
  font-family: Lato, sans-serif;
  width: calc(100% - 410px);
}

.availableProperty .tableFilter .searchFilter input,
.availableProperty .tableFilter label,
.availableProperty .tableFilter select,
.availableProperty .tablesorter-blue .tablesorter-header,
.availableProperty .tablesorter-blue td {
  font-size: 16px;
  font-family: Lato, sans-serif;
}

.availableProperty .tableFilter label {
  color: #666;
  margin-bottom: 0;
  font-weight: 400;
}

.availableProperty .tableFilter select {
  color: #666;
  line-height: 1.25em;
  background: #fff;
  border: 1px solid #e8e8e8;
  margin: 0 5px;
  border-radius: 3px;
  padding: 4px;
  cursor: pointer;
}

.availableProperty .tableFilter select option {
  cursor: pointer;
}

.availableProperty .tableFilter .searchFilter {
  width: 410px;
}

.availableProperty .tableFilter .searchFilter input {
  width: 100%;
  outline: 0;
  padding: 10px;
  color: #666;
  background: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  line-height: 1.25em;
}

.availableProperty .tableWrap {
  background: #fff;
  padding: 15px 35px;
  border: 0;
  margin-top: 10px;
  min-width: 810px;
  width: 100%;
}

.availableProperty .tablesorter-blue {
  border: none;
}

.availableProperty .tablesorter-blue .tablesorter-header {
  color: #303030;
  font-weight: 400;
  padding: 10px 18px;
  border: none;
  border-bottom: 1px solid #e9e9e9;
  background-color: #fff;
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: center right;
}

.availableProperty .tablesorter-blue td {
  padding: 10px 16px;
  color: #666;
  border: 0;
  background: #fff;
  font-weight: 400;
}

.dataTables_info {
  padding: 15px 0;
  font-size: 14px;
  line-height: 1.25em;
  color: #666;
  font-weight: 400;
  font-family: Lato, sans-serif;
}

.filterDownload input,
.filterDownload p {
  font-size: 16px;
  font-family: Lato, sans-serif;
  color: #666;
}

.filterDownload {
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 30px;
}

.filterDownload form {
  display: flex;
}

.filterDownload .form_group:first-of-type {
  width: 410px;
}

.filterDownload .form_group:nth-of-type(2) {
  width: 55px;
  padding-left: 15px;
}

.filterDownload input {
  width: 100%;
  outline: 0;
  padding: 10px;
  background: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  line-height: 1.25em;
}

.filterDownload button {
  border-radius: 2px;
  border: none;
  background-color: #ff8c00;
  width: 100%;
  height: 40px;
  cursor: pointer;
  transition: all 0.25s ease;
}

.filterDownload button:hover {
  background-color: #006994;
}

.filterDownload button svg {
  width: 25px;
  height: 25px;
  stroke: #fff;
}

.filterDownload p {
  text-transform: capitalize;
  padding-right: 15px;
  font-weight: 400;
}

.buying-advice {
  padding: 100px 0;
}

@media (min-width: 1450px) {
  .buying-advice {
    height: 100vh;
  }
}

.buying-advice li {
  width: calc(33.33% - 13.33px);
  margin-right: 20px;
  height: 280px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  position: relative;
  border-radius: 10px;
}

.buying-advice li .content,
.buying-advice li:before {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  left: 0;
}

.buying-advice li:nth-of-type(3n) {
  margin-right: 0;
}

.buying-advice li:before {
  content: "";
  background-color: #07070761;
  border-radius: 10px;
}

.buying-advice li figure {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.buying-advice li figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.buying-advice li .content {
  padding: 35px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.buying-advice li h4 {
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.25em;
}

.buying-advice li a {
  border: 1px solid #ff8c00;
  background-color: #ff8c00;
  font-size: 15px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  padding: 12px 24px;
  border-radius: 3px;
  line-height: 1.2em;
  color: #fff;
  transition: all 0.25s ease;
}

.getInTouch h2,
.getInTouch-popup .head h3 {
  font-family: Effra-Regular;
  font-weight: 400;
}

.about_mallorca p,
.property-tab p,
.why-lists p {
  line-height: 1.7em;
  letter-spacing: 0.1px;
}

.buying-advice li a:hover {
  background-color: #006994;
  border: 1px solid #006994;
}

.why-lists .wrapper {
  padding: 80px;
}

.why-lists .wrapper:nth-of-type(odd) {
  background-color: #fff;
}

.why-lists .wrapper:nth-of-type(even) {
  background-color: #f6f6f6;
}

.why-lists .container-sm {
  align-items: center;
}

.why-lists .col {
  width: 50%;
  padding: 0 30px;
}

.why-lists figure,
.why-lists figure img {
  border-radius: 10px;
}

.why-lists p {
  margin-bottom: 1.1em;
  color: #4d5471;
  font-weight: 400;
  font-size: 16px;
}

.getInTouch {
  padding: 60px 0;
  background-color: #d2e3e6;
}

.getInTouch a,
.getInTouch-popup .close {
  background-color: #ff8c00;
  transition: all 0.25s ease;
}

.getInTouch .container-sm {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.getInTouch h2 {
  margin-bottom: 30px;
  color: #303030;
  font-size: 24px;
  line-height: 1.2em;
}

.account-edit h5,
.account-edit.lost_password h5,
.account-edit.lost_password p,
.bookmark-section li .bookmark-content .top h5:first-of-type a,
.bookmark-section li .bookmark-content .top h5:nth-of-type(2) a,
.getInTouch a {
  font-family: Lato, sans-serif;
}

.getInTouch a {
  font-weight: 500;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.25em;
  padding: 15px 30px;
  color: #fff;
}

.getInTouch a:hover {
  background-color: #006994;
}

.getInTouch-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 999;
  overflow-y: scroll;
  display: none;
}

.getInTouch-popup.active {
  display: block;
}

.getInTouch-popup .wrapper {
  width: 500px;
  background-color: #fff;
  position: relative;
  margin: 40px auto 20px;
}

.getInTouch-popup .close {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -25px;
  top: -25px;
  cursor: pointer;
}

.getInTouch-popup .close i {
  color: #fff;
}

.getInTouch-popup .close:hover {
  background-color: #006994;
}

.getInTouch-popup .head {
  background-color: #e4e4e4;
  padding: 15px 25px;
}

.getInTouch-popup .head h3 {
  color: #4d5471;
  line-height: 1.25em;
}

.getInTouch-popup .content {
  background-color: #fff;
  padding: 40px;
}

.account-edit {
  background-color: #f4f4f4;
  padding: 50px 0;
}

.account-edit .wrapper {
  max-width: 550px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  padding: 20px;
  background-color: #fff;
  margin: 0 auto;
}

.account-edit .head {
  align-items: center;
  margin-bottom: 15px;
}

.account-edit .icon {
  background-color: #ff8c00;
  border-radius: 50%;
  margin-right: 15px;
}

.account-edit h5 {
  color: #242429;
  font-size: 14px;
  line-height: 20px;
}

.account-edit.lost_password .wrapper {
  background: #fff;
  padding: 20px;
  margin-bottom: 25px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  max-width: 370px;
}

.account-edit.lost_password .head {
  margin: 30px 0 20px;
  padding-bottom: 25px;
}

.account-edit.lost_password .icon {
  margin-right: 9px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.account-edit.lost_password h5 {
  font-size: 24px;
  color: #242429;
}

.account-edit.lost_password p {
  color: #242429;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  margin-bottom: 31px;
}

.account-edit.lost_password .editProfile label {
  color: #4d5471;
}

.account-edit.lost_password .editProfile input {
  border: 1px solid #eee;
  border-radius: 3px;
  padding: 14px 10px;
  margin-top: 7px;
  font-weight: 400;
}

.account-edit.lost_password .editProfile .form_group:nth-of-type(1) {
  margin-bottom: 20px;
}

.account-edit.lost_password .editProfile button {
  padding: 14px 40px 12px;
  font-size: 18px;
}

.bookmark-section {
  padding: 50px 0;
  background-color: #f4f4f4;
}

.bookmark-section li {
  width: calc(33.3% - 15px);
  margin-right: 23px;
  margin-bottom: 25px;
  position: relative;
}

.bookmark-section li:nth-of-type(3n) {
  margin-right: 0;
}

.bookmark-section li .bookmark-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background-color: transparent;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.bookmark-section li .bookmark-content .top {
  justify-content: space-between;
}

.bookmark-section li .bookmark-content .top h5:first-of-type a {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background: #f88c03;
  padding: 10px 20px;
  border-radius: 8px;
  transition: all 0.25s ease;
}

.bookmark-section li .bookmark-content .top h5:nth-of-type(2) a {
  font-size: 14px;
  font-weight: 700;
  color: #000;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  transition: all 0.25s ease;
}

.bookmark-section li .bookmark-content .top a:hover {
  transform: translateY(-4px);
}

.bookmark-section li .bookmark-content .bottom {
  justify-content: center;
  margin-top: auto;
}

.bookmark-section li .bookmark-content .bottom a {
  margin: 0 15px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ff8c00;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all o 0.25s ease;
}

.bookmark-section li .bookmark-content .bottom a:hover {
  transform: translateY(-4px);
}

/* 
.bookmark-section li:hover .slick-arrow {
  opacity: 0.5;
}
.bookmark-section .bookmark-slider {
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
  height: 350px;
}
.bookmark-section .bookmark-slider figure {
  border-radius: 15px;
  height: 100%;
}
.bookmark-section .bookmark-slider figure img {
  border-radius: 15px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.bookmark-section .bookmark-slider .slick-list,
.bookmark-section .bookmark-slider .slick-slide,
.bookmark-section .bookmark-slider .slick-track {
  height: 100%;
}
.bookmark-section .bookmark-slider .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  width: 50px;
  z-index: 1;
  opacity: 0;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(0, 0, 0, 0)),
    to(#262637)
  );
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0), #262637);
  font-size: 0;
  border-radius: 15px 0 0 15px;
  transition: all 0.25s ease;
}
.bookmark-section .bookmark-slider .slick-arrow:before,
.listings .list-img-slider .slick-arrow:before {
  font-family: fontawesome;
  font-size: 20px;
  content: "\f104";
  transform: translateY(-50%);
}
.bookmark-section .bookmark-slider .slick-arrow:before {
  line-height: 1;
  opacity: 0.75;
  color: #000;
  cursor: pointer;
  position: absolute;
  left: 20px;
  z-index: 2;
  top: 50%;
}
.saved-searches h1,
.saved-searches p {
  color: #242429;
  font-family: Lato, sans-serif;
}
.bookmark-section .bookmark-slider .slick-arrow:after {
  content: "";
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-color: #fff;
  opacity: 0.5;
  border-radius: 50%;
  cursor: pointer;
}
.bookmark-section .bookmark-slider .slick-arrow.slick-next {
  left: auto;
  right: 0;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 0)),
    to(#262637)
  );
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0), #262637);
  border-radius: 0 15px 15px 0;
}
.bookmark-section .bookmark-slider .slick-arrow.slick-next:before {
  content: "\f105";
  left: auto;
  right: 20px;
}
.bookmark-section .bookmark-slider .slick-arrow.slick-next:after {
  left: auto;
  right: 15px;
} */
.saved-searches {
  background-color: #f4f4f4;
  padding: 0.67em 0 30px;
}

.saved-searches h1 {
  font-weight: 700;
  font-size: 27px;
  line-height: 1.25em;
}

.saved-searches p {
  font-size: 14px;
  line-height: 26px;
  margin: 0 0 20px;
  font-weight: 400;
}

.saved-searches .listing-search {
  background: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
}

.saved-searches .listing-search .table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  color: #484848;
  background: 0 0;
}

.saved-searches .listing-search #alert-fq {
  border-spacing: 0 20px;
  border-collapse: separate;
}

.saved-searches .listing-search #alert-fq thead th {
  border-bottom: 1px solid #eee;
  vertical-align: bottom;
  padding: 8px;
  line-height: 1.42857143;
  text-align: left;
  font-size: 14px;
  font-family: Lato, sans-serif;
  font-weight: 700;
}

.saved-searches .listing-search #alert-fq tbody tr {
  box-shadow: 0 5px 18px rgba(0, 0, 0, 0.08);
  border-radius: 100px;
}

.saved-searches .listing-search #alert-fq tbody td {
  border-color: transparent;
  padding: 10px 20px;
  vertical-align: middle;
  line-height: 1.42857143;
  font-size: 14px;
  font-family: Lato, sans-serif;
  font-weight: 400;
}

.saved-searches .listing-search #alert-fq tbody select {
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -webkit-transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
}

.saved-searches .listing-search #alert-fq tbody .frq-link {
  background: #ff8c00;
  padding: 4px 10px;
  border-radius: 5px;
  font-size: 13px;
  color: #fff;
  font-weight: 600;
  transition: all 0.25s ease;
}

.saved-searches .listing-search #alert-fq tbody .frq-link:hover {
  background-color: #006994;
}

.saved-searches .listing-search #alert-fq tbody .frqDeleteBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: 1px solid #ddd;
  border-radius: 50%;
  background: #eee;
  cursor: pointer;
  transition: all 0.25s ease;
}

.saved-searches .listing-search #alert-fq tbody .frqDeleteBtn:hover {
  background-color: #ff8c00;
  border: 1px solid #ff8c00;
}

.saved-searches .listing-search #alert-fq tbody .frqDeleteBtn:hover svg {
  stroke: #fff;
}

.saved-searches .create-search {
  background: #fff;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  border-radius: 10px;
  margin: 10px 0 30px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
}

.saved-searches .create-search a {
  font-weight: 600;
  text-decoration: underline;
  color: #333;
  font-family: Lato, sans-serif;
  font-size: 14px;
  line-height: 26px;
}

.fillter-section {
  padding: 20px 15px;
  background: #fff;
  border-top: 1px solid #ddd;
  position: relative;
}

.fillter-section .container_ {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.fillter-section form {
  display: flex;
  margin: 0 48px;
}

.fillter-section .form-group {
  margin-bottom: 0;
  position: relative;
}

.fillter-section .form-group:first-of-type {
  width: 536px;
  margin: 0 10px;
}

.fillter-section .form-group:nth-of-type(4),
.fillter-section .form-group:nth-of-type(5) {
  position: static;
}

.fillter-section .searchSelect {
  background-color: #f5f5f5;
  border-radius: 4px;
  border: 1px solid #4d5471;
  padding: 17px 16px;
  font-weight: 400;
  font-family: Lato, sans-serif;
  font-size: 14px;
  line-height: 28px;
  color: #999;
}

.fillter-section .beds-btn,
.fillter-section .c-select,
.fillter-section .pricesearch .price-btn,
.fillter-section .search_select .select-search-wrap .select2-item {
  color: #000;
  font-size: 14px;
  font-family: Lato, sans-serif;
  cursor: pointer;
}

.fillter-section .search_select .select2-list {
  background-color: #fff !important;
  border: 1px solid #ddd;
  max-height: 200px;
}

.fillter-section .search_select .select2-list input {
  padding: 12px 15px;
  border: none;
  border-radius: 0;
}

.fillter-section .search_select .select-search-wrap {
  border-radius: 0 0 5px 5px;
  border: 1px solid #e4e4e4;
  padding: 10px;
  background: #fff;
  box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.17);
}

.fillter-section .search_select .select-search-wrap .select2-item {
  border-radius: 8px;
  border: 0;
  padding: 6px 12px;
  background: #fff;
  line-height: 1.25em;
}

.fillter-section .search_select .select-search-wrap .select2-item:hover {
  background-color: #eaeaea;
}

.fillter-section .pricesearch .price-btn {
  border: 1px solid #4d5471;
  border-radius: 3px;
  line-height: 1.25em;
  font-weight: 400;
  padding: 8px 15px;
  background-color: #f5f5f5;
  margin: 0 5px;
}

.fillter-section .innerbeds,
.fillter-section .innerprice {
  position: absolute;
  top: calc(100% + 24px);
  display: flex;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
  padding: 12px;
  height: auto;
  width: 380px;
  right: 0;
  border-radius: 6px;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s ease;
}

.fillter-section .innerbeds.active,
.fillter-section .innerprice.active {
  opacity: 1;
  visibility: visible;
}

.fillter-section .beds-btn,
.fillter-section .c-select {
  border: 1px solid #4d5471;
  border-radius: 3px;
  line-height: 1.25em;
  font-weight: 400;
  padding: 8px 15px;
  background-color: #f5f5f5;
  margin: 0 5px;
}

.fillter-section .save-search {
  padding: 12px 32px 6px;
  background: #ff8c00;
  border-radius: 5px;
  border: none;
  font-size: 14px;
  line-height: 1.25em;
  font-weight: 500;
  font-family: Effra-Medium;
  color: #fff;
  margin-left: 10px;
  outline: 0;
}

.fillter-section #bookmark-btn {
  padding: 5px 20px;
  background: #ff8c00;
  border-radius: 5px;
  border: none;
  outline: 0;
}

.fillter-section #bookmark-btn svg {
  stroke: #fff;
  height: 20px;
}

.fillter-section .maxBedrooms select,
.fillter-section .maxprice select,
.fillter-section .minBedrooms select,
.fillter-section .minprice select {
  border: 1px solid #ddd;
  padding: 1px 10px;
  border-radius: 6px;
  height: 40px;
  font-size: 14px;
  line-height: 1.25em;
  color: #000;
  font-family: Lato, sans-serif;
  width: 160px;
  cursor: pointer;
}

.fillter-section .bookmark-btn-outer {
  position: relative;
}

.fillter-section .bookmark-btn-outer .bookmark-coumt {
  position: absolute;
  top: -10px;
  right: 6px;
  width: 20px;
  height: 20px;
  display: flex;
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 10px;
  font-family: Lato, sans-serif;
}

.fillter-section .mainfiltersection,
.fillter-section .propertytypelist {
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  background: #fff;
  z-index: 2;
  border-bottom: 1px solid #e2e2e2;
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s ease;
}

.fillter-section .mainfiltersection.active,
.fillter-section .propertytypelist.active {
  opacity: 1;
  visibility: visible;
}

.fillter-section .mainfiltersection ul,
.fillter-section .propertytypelist ul {
  display: flex;
  flex-wrap: wrap;
  padding: 30px 15px;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.fillter-section .mainfiltersection li,
.fillter-section .propertytypelist li {
  display: flex;
  flex-basis: 20%;
  max-width: 20%;
  margin: 10px 0;
  border-left: 1px dashed #bbb;
  padding: 15px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
}

.fillter-section .mainfiltersection li input,
.fillter-section .propertytypelist li input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

.fillter-section .mainfiltersection li label,
.fillter-section .propertytypelist li label {
  color: #242429;
  font-weight: 400;
  font-family: Lato, sans-serif;
  padding: 2px 10px;
  width: 100%;
  text-align: center;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 14px;
}

.fillter-section .mainfiltersection li img,
.fillter-section .propertytypelist li img {
  opacity: 0.5;
  width: 40px;
  height: 40px;
}

.fillter-section .mainfiltersection li input:checked+.customtypedata label,
.fillter-section .propertytypelist li input:checked+.customtypedata label {
  background-color: #262637;
  color: #fff;
}

.fillter-section .doneFilter {
  flex-basis: 100%;
  margin-top: 15px;
}

.fillter-section .doneFilter button {
  color: #303030;
  border: 2px solid #303030;
  background: 0 0;
  border-radius: 5px;
  padding: 12px 20px 9px;
  font-weight: 500;
  font-family: Effra-Medium;
  cursor: pointer;
  font-size: 14px;
  line-height: 1em;
  transition: all 0.25s ease;
}

.fillter-section .doneFilter button:hover {
  color: #fff;
  background: #ff8c00;
  border: 2px solid #ff8c00;
}

.fillter-section .datefilter {
  display: flex;
  padding: 0 15px 16px;
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: center;
}

.fillter-section .datefilter .addedSite__select,
.fillter-section .datefilter .search-subtype-property {
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.fillter-section .datefilter .addedSite__select label,
.fillter-section .datefilter .search-subtype-property label {
  white-space: nowrap;
  margin-right: 4px;
  color: #242429;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: -4px;
  text-align: left;
  font-family: Lato, sans-serif;
}

.fillter-section .datefilter .addedSite__select .select-outer,
.fillter-section .datefilter .search-subtype-property .select-outer {
  width: 100%;
  max-width: 220px;
  position: relative;
  border-radius: 6px;
}

.fillter-section .datefilter .addedSite__select .select-outer select,
.fillter-section .datefilter .search-subtype-property .select-outer select {
  color: #333;
  padding: 0 20px 0 10px;
  width: 100%;
  background: #fff;
  border-color: #ddd;
  border-radius: 6px;
  height: 38px;
  font-size: 14px;
  font-family: Lato, sans-serif;
}

.fillter-section .datefilter .addedSite__select #subtype-propertyy,
.fillter-section .datefilter .search-subtype-property #subtype-propertyy {
  background: #fff;
  height: 38px;
  border-color: #ddd;
  border-radius: 6px;
  width: 134px;
  font-size: 14px;
  font-family: Lato, sans-serif;
}

.fillter-section .tagsfilter-Outer {
  background: #f7f7f7;
  border-top: 1px dashed #ccc;
}

.fillter-section .tagsfilter-Outer .material-symbols-outlined,
.fillter-section .tagsfilter-Outer i {
  font-size: 40px;
  color: navy;
  opacity: 0.6;
}

.fillter-section #save-search-div {
  position: absolute;
  right: 10px;
  width: 100%;
  max-width: 320px;
  top: 100%;
  background: #fff;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 15px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s ease;
  z-index: 9;
}

.fillter-section #save-search-div.active {
  opacity: 1;
  visibility: visible;
}

.fillter-section #save-search-div h2 {
  margin: 0.83em 0;
  font-size: 16px;
  font-weight: 700;
  color: #242429;
  font-family: Lato, sans-serif;
}

.fillter-section #save-search-div form {
  margin: 0;
}

.fillter-section #save-search-div label {
  color: #242429;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.25em;
  text-align: left;
  font-family: Lato, sans-serif;
  margin: 0;
  width: 100%;
}

.fillter-section #save-search-div #alerts-frequency,
.fillter-section #save-search-div #save-search-name {
  height: 36px;
  color: #222;
  line-height: 1.25em;
  font-family: Lato, sans-serif;
  font-size: 14px;
  width: 100%;
}

.fillter-section #save-search-div #save-search-name {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 8px 0 15px;
  padding: 0 15px;
}

.fillter-section #save-search-div #alerts-frequency {
  background: #fff;
  border: 1px solid #ddd;
  margin-bottom: 15px;
  margin-top: 10px;
  border-radius: 5px;
  padding: 0 10px;
}

.fillter-section #save-search-div button {
  background: #f68c04;
  color: #fff;
  border-radius: 5px;
  border: 0;
  padding: 11px 10px 6px;
  max-width: 100px;
  font-weight: 500;
  font-family: Effra-Medium;
  font-size: 14px;
  width: 100px;
  transition: all 0.25s ease;
}

.fillter-section #save-search-div button:hover {
  background-color: #006994;
}

.fillter-section div#bookmarks {
  position: absolute;
  right: 15px;
  width: calc(100% - 30px);
  top: 100%;
  z-index: 9;
  background: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s ease;
}

.fillter-section div#bookmarks.active {
  opacity: 1;
  visibility: visible;
}

.fillter-section div#bookmarks h4 {
  display: flex;
  align-items: center;
  margin: 1.33em 0;
  font-weight: 700;
  color: #242429;
  font-size: 14px;
  font-family: Lato, sans-serif;
}

.fillter-section div#bookmarks .bookmark-btn-outer {
  margin-left: auto;
}

.fillter-section div#bookmarks .bookmark-btn-outer button {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  padding: 4px;
}

.fillter-section div#bookmarks .bookmark-btn-outer button svg {
  min-width: 30px;
  min-height: 30px;
}

.fillter-section div#bookmarks p {
  margin: 1em 0;
  color: #242429;
  font-size: 14px;
  line-height: 26px;
  font-family: Lato, sans-serif;
  font-weight: 400;
}

.fillter-section div#bookmarks table tbody tr td,
.fillter-section div#bookmarks table th {
  padding: 8px;
  color: #484848;
  line-height: 1.2em;
  font-size: 14px;
  font-family: Lato, sans-serif;
}

.fillter-section div#bookmarks table th {
  white-space: nowrap;
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
  border-top: none;
  font-weight: 700;
}

.fillter-section div#bookmarks table tbody tr:nth-of-type(odd) {
  background-color: #f3f3f3;
}

.fillter-section div#bookmarks table tbody tr td {
  vertical-align: middle;
  border-top: 1px solid #ddd;
  font-weight: 400;
}

.fillter-section div#bookmarks table tbody select {
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -webkit-transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
}

.fillter-section div#bookmarks table tbody button.delete-bookmark {
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #eee;
  position: relative;
  z-index: 999;
  font-family: Effra-Medium;
  font-weight: 500;
}

.fillter-section .select2 {
  position: relative;
}

.fillter-section .select2-list {
  display: none;
  position: absolute;
  max-height: 300px;
  overflow: overlay;
  z-index: 9;
}

.fillter-section .select2-item:hover {
  background-color: #94adff31;
  cursor: default;
}

.fillter_res {
  background-color: #f4f4f4;
  padding: 15px 0;
}

.fillter_res .container_ {
  max-width: 1275px;
  margin: 0 auto;
  padding: 0 24px;
}

.fillter_res .top_bar {
  justify-content: space-between;
  align-items: center;
}

.fillter_res .top_bar h5 {
  font-family: Lato, sans-serif;
  font-size: 18px;
  line-height: 1.25em;
  font-weight: 600;
  color: #242429;
}

.fillter_res .top_bar label,
.fillter_res .top_bar select {
  font-family: Lato, sans-serif;
  font-size: 14px;
}

.fillter_res .top_bar label {
  color: #242429;
  font-weight: 400;
  text-align: left;
}

.fillter_res .top_bar select {
  background-color: #fff;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  padding: 8px 8px 10px;
  height: 40px;
  margin-left: 10px;
  color: #222;
  line-height: 1.25em;
}

.fillter_res .top_heading,
.fillter_res h1 {
  color: #242429;
  font-family: Lato, sans-serif;
}

.fillter_res .top_heading {
  font-size: 18px;
  margin: 40px 0 28px;
  line-height: 1.25em;
  font-weight: 600;
}

.fillter_res h1 {
  font-size: 27px;
  margin: 0.67em 0;
  font-weight: 600;
  line-height: 1.25;
}

.result-slider-wrap,
.result-slider-wrap .result-slider {
  width: 100%;
}

.result-slider-wrap .slick-slide {
  margin: 0 8px;
}

.result-slider-wrap .slick-list {
  margin: 0 -8px;
}

.result-slider-wrap .listings {
  box-shadow: rgba(0, 0, 0, 0.12) 0 4px 8px;
}

.result-slider-wrap .listings .col_:first-of-type {
  padding: 0;
  width: 100%;
}

.result-slider-wrap .listings .col_:nth-of-type(2) {
  width: 100%;
}

.result-slider-wrap .listings .pricesection {
  display: flex;
}

.result-slider-wrap .listings .pricesection span {
  font-size: 14px;
  display: flex;
}

.property-tab .tab-wrapper .tab-content,
.result-slider-wrap .listings .listDataRight a {
  display: none;
}

.result-slider-wrap .listings .pricesection .price {
  font-size: 18px;
  margin-right: auto;
}

.result-slider-wrap .listings .pricesection img {
  width: 16px;
  height: 16px;
}

.result-slider-wrap .listings .pricesection .bathrooms_size {
  margin-right: 0;
}

.result-slider-wrap .listings .listDataContainer a {
  width: 100%;
}

.result-slider-wrap .listings .listingaddress {
  margin-bottom: 55px;
}

.result-slider-wrap .slick-arrow {
  top: -60px;
  width: 40px;
  height: 44px;
  position: absolute;
  font-size: 0;
  background-size: 15px !important;
  border-width: 1px;
  border-style: solid;
  border-color: #222;
  border-image: initial;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  outline: 0;
}

.listings {
  border-radius: 15px;
  box-shadow: 1px 1px 2px rgba(51, 51, 51, 0.28);
  background: #fff;
  margin-bottom: 30px;
  position: relative;
}

.listDataRight.uper_side {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 5;
}

.listings .col_ {
  padding: 14px;
}

.listings .col_:first-of-type {
  width: 43%;
  position: relative;
}

.listings .col_:first-of-type .slider_link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.listings .col_:first-of-type:hover .slick-arrow {
  opacity: 0.5;
}

.listings .col_:nth-of-type(2) {
  width: calc(100% - 43%);
  display: flex;
  flex-direction: column;
}

.listings .list-img-slider {
  width: 100%;
  height: 300px;
}

.listings .list-img-slider figure {
  border-radius: 15px;
  height: 100%;
}

.listings .list-img-slider figure img {
  border-radius: 15px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.listings .list-img-slider .slick-list,
.listings .list-img-slider .slick-slide,
.listings .list-img-slider .slick-track {
  height: 100%;
}

.listings .list-img-slider .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  width: 50px;
  z-index: 1;
  opacity: 0;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
  background-image: -webkit-gradient(linear,
      right top,
      left top,
      from(rgba(0, 0, 0, 0)),
      to(#262637));
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0), #262637);
  font-size: 0;
  border-radius: 15px 0 0 15px;
  transition: all 0.25s ease;
}

.listings .list-img-slider .slick-arrow:before {
  line-height: 1;
  opacity: 0.75;
  color: #000;
  cursor: pointer;
  position: absolute;
  left: 20px;
  z-index: 2;
  top: 50%;
}

.listings .listingtitle,
.listings .pricesection span {
  font-family: Lato, sans-serif;
}

.listings .list-img-slider .slick-arrow:after {
  content: "";
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-color: #fff;
  opacity: 0.5;
  border-radius: 50%;
  cursor: pointer;
}

.listings .list-img-slider .slick-arrow.slick-next {
  left: auto;
  right: 0;
  background-image: -webkit-gradient(linear,
      left top,
      right top,
      from(rgba(0, 0, 0, 0)),
      to(#262637));
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0), #262637);
  border-radius: 0 15px 15px 0;
}

.listings .list-img-slider .slick-arrow.slick-next:before {
  content: "\f105";
  left: auto;
  right: 20px;
}

.listings .list-img-slider .slick-arrow.slick-next:after {
  left: auto;
  right: 15px;
}

.listings .listDataContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.listings .listingtitle {
  color: #333;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
}

.listings .pricesection {
  padding: 10px 0;
}

.listings .pricesection span {
  padding-right: 10px;
  margin-right: 10px;
  font-weight: 600;
  font-size: 17px;
  line-height: 1.25em;
  color: #666;
}

.listings .pricesection .price {
  font-size: 21px;
  font-weight: 700;
  color: #333;
  margin-right: 20px;
  line-height: 1.25em;
  font-family: Lato, sans-serif;
  padding-right: 0;
  border-right: none !important;
}

.about_mallorca p,
.listings .listingaddress {
  font-weight: 400;
  font-family: Lato, sans-serif;
}

.listings .listingaddress i,
.request-view .listingaddress svg {
  margin-right: 5px;
}

.listings .pricesection .bathrooms_size {
  border-right: none;
}

.listings .pricesection img {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}

.listings .listingaddress {
  font-size: 16px;
  line-height: 1.25em;
  margin-bottom: 11px;
  color: #333;
  margin-top: 3px;
}

.listings .listDataRight a {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listings .listDataRight a i {
  font-size: 16px;
}

.listings .agentname {
  margin-bottom: 15px;
  padding-top: 10px;
  /* border-top: 1px solid #ccc; */
  margin-top: auto;
}

.listings .agentname .wrap {
  align-items: center;
  justify-content: flex-end;
}

.listings .agentname a,
.listings .agentname p {
  font-weight: 600;
  color: #39c;
  font-size: 14px;
  line-height: 19px;
  font-family: Lato, sans-serif;
}

.listings .agentname figure {
  width: 50px;
  height: 50px;
  margin-left: 10px;
}

.listings .agentname figure a,
.listings .agentname figure img {
  width: 100%;
  height: 100%;
}

.about_mallorca .col,
.property-tab .col {
  width: 50%;
}

.about_mallorca {
  padding: 50px 0;
  background-color: #f4f4f4;
}

.about_mallorca .container-sm {
  padding: 0;
}

.about_mallorca .col:first-of-type {
  padding-right: 50px;
}

.about_mallorca .inner-heading {
  text-align: left;
}

.about_mallorca .inner-heading h3 {
  padding-top: 13px;
  display: inline-block;
  position: relative;
  margin-bottom: 20px;
  color: #303030;
}

.about_mallorca .inner-heading h3:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 80px;
  height: 3px;
  /* // background-color: #ff8c00; */
}

.about_mallorca p {
  color: #4d5471;
  font-size: 16px;
  margin: 1em 0;
}

.property-tab .head h2,
.property-tab ul li {
  font-family: Effra-Regular;
  font-weight: 400;
}

.about_mallorca figure,
.about_mallorca img {
  border-radius: 10px;
  width: 100%;
}

.about_mallorca figure {
  height: 313px;
}

.about_mallorca figure img {
  height: 100%;
  object-fit: cover;
}

.property-tab:nth-of-type(odd) figure {
  padding-right: 30px;
}

.property-tab:nth-of-type(odd) .col:nth-of-type(2) {
  padding: 10px;
}

.property-tab:nth-of-type(even) figure {
  padding-left: 30px;
}

.property-tab:nth-of-type(even) .col:first-of-type {
  padding: 10px;
}

.property-tab .head {
  position: relative;
  padding: 40px 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.property-tab .head:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
}

.property-tab .head h2 {
  padding: 10px;
  color: #fff;
  text-align: center;
  font-size: 34px;
  position: relative;
}

.property-tab .wrapper {
  padding: 50px 0;
}

.property-tab .tab-wrapper {
  padding: 10px;
}

.property-tab ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.property-tab ul li {
  cursor: pointer;
  padding: 10px 19px;
  color: #4d5471;
  font-size: 18px;
  transition: all 0.25s ease;
  border-top: solid 3px transparent;
}

.property-tab ul li.active {
  border-top: solid 3px #ff8c00;
  color: #000;
}

.property-tab ul li:hover {
  color: #000;
}

.property-tab figure,
.property-tab img {
  border-radius: 10px;
  width: 100%;
}

.property-tab figure {
  height: 455px;
}

.property-tab figure img {
  height: 100%;
  object-fit: cover;
}

.property-tab p {
  margin: 1em 0;
  color: #2265ab;
  font-family: Lato, sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.buyingAdvice {
  background: #f4f4f4;
  padding: 50px 0;
}

.buyingAdvice .container-sm {
  padding: 0;
}

.buyingAdvice .ag-format-container {
  position: relative;
  padding-bottom: 20px;
}

.buyingAdvice .ag-timeline_title-box {
  padding: 0 0 30px;
  text-align: center;
}

.buyingAdvice .ag-timeline_tagline {
  font-size: 40px;
  color: #54595f;
}

.buyingAdvice .ag-timeline_item {
  margin: 0 0 35px;
  position: relative;
  display: flex;
  align-items: center;
}

.buyingAdvice .ag-timeline_item:nth-child(2n) {
  text-align: left;
  justify-content: flex-end;
}

.buyingAdvice .ag-timeline {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}

.buyingAdvice .ag-timeline_line {
  width: 2px;
  background-color: #e8e8f6;
  position: absolute;
  top: 120px !important;
  left: 50%;
  bottom: 0;
  overflow: hidden;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.buyingAdvice .ag-timeline_line-progress {
  width: 100%;
  height: 20%;
  background-color: #ff8c00;
}

.buyingAdvice .ag-timeline-card_box {
  padding: 0 0 0 55px;
}

.buyingAdvice .ag-timeline_item:nth-child(2n) .ag-timeline-card_box {
  padding: 0 55px 0 0;
}

.buyingAdvice .ag-timeline-card_point-box {
  display: inline-block;
  margin: 0 20px 0 -20px;
}

.buyingAdvice .ag-timeline_item:nth-child(2n) .ag-timeline-card_point-box {
  margin: 0 -20px 0 0;
  display: flex;
  align-items: center;
}

.buyingAdvice .ag-timeline_item:nth-child(2n) .ag-timeline-card_point-box .ag-timeline-card_point {
  margin-left: 20px;
}

.buyingAdvice .ag-timeline-card_point {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e8e8f6;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.buyingAdvice .ag-timeline-card_point svg {
  width: 18px;
  height: 16px;
}

.buyingAdvice .ag-timeline-card_point svg path {
  fill: #fff;
}

.buyingAdvice .js-ag-active .ag-timeline-card_point {
  color: #fff;
  background-color: #ff8c00;
}

.buyingAdvice .ag-timeline-card_meta-box {
  display: inline-block;
}

.buyingAdvice .ag-timeline-card_meta {
  margin: 10px 0 0;
  font-family: Effra-Medium;
  font-weight: 700;
  font-size: 20px;
  color: #a0a0a2;
  letter-spacing: 0.1px;
}

.buyingAdvice .js-ag-active .ag-timeline-card_meta {
  color: #ff8c00;
}

.buyingAdvice .ag-timeline-card_item {
  display: inline-block;
  width: 45%;
  margin: 0;
  background-color: #fff;
  opacity: 1;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 2px 2px 30px 0 rgba(156, 156, 192, 0.26);
  -moz-box-shadow: 2px 2px 30px 0 rgba(156, 156, 192, 0.26);
  -o-box-shadow: 2px 2px 30px 0 rgba(156, 156, 192, 0.26);
  box-shadow: 2px 2px 30px 0 rgba(156, 156, 192, 0.26);
  position: relative;
}

.buyingAdvice .ag-timeline-card_arrow {
  height: 20px;
  width: 20px;
  background-color: #fff;
  z-index: 1;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%) rotate(45deg);
  -moz-transform: translateY(-50%) rotate(45deg);
  -ms-transform: translateY(-50%) rotate(45deg);
  -o-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
}

.buyingAdvice .ag-timeline_item:nth-child(2n + 1) .ag-timeline-card_arrow {
  margin-left: calc(-18px / 2);
  margin-right: calc(-18px / 2);
}

.buyingAdvice .ag-timeline_item:nth-child(2n) .ag-timeline-card_arrow {
  margin-left: -10px;
  right: auto;
  left: 0;
}

.buyingAdvice .ag-timeline-card_img {
  width: 100%;
}

.buyingAdvice .ag-timeline-card_info {
  padding: 40px;
}

.buyingAdvice .ag-timeline-card_title {
  margin: 0 0 1.67em;
  font-family: Effra-Medium;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.25;
  color: #242429;
}

.buyingAdvice .ag-timeline-card_desc {
  line-height: 1.7em;
  font-size: 16px;
  font-weight: 400;
  color: #4d5471;
  letter-spacing: 0.1px;
  font-family: Lato, sans-serif;
}

.buyingAdvice .boxContent {
  padding: 50px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px 11px rgba(0, 0, 0, 0.04);
}

.buyingAdvice .boxContent h4 {
  color: #303030;
  font-weight: 400;
  font-family: Effra-Regular;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 20px;
}

.buyingAdvice .boxContent p,
.buyingAdvice .notes p {
  color: #4d5471;
  font-size: 16px;
  font-weight: 400;
  font-family: Lato, sans-serif;
}

.buyingAdvice .boxContent p,
.buyingAdvice .notes p,
.property-inner .price_map .col a,
.property-inner .price_map h3,
.property-inner .price_map p {
  line-height: 1.7em;
  letter-spacing: 0.1px;
}

.buyingAdvice .boxContent p {
  margin: 1em 0;
}

.buyingAdvice .notes {
  padding-top: 50px;
}

.buyingAdvice .notes p {
  padding: 10px;
}

.banner-gallery {
  padding: 50px 0 40px;
}

.banner-gallery .container-sm {
  padding: 0 10px;
}

#demo-test-gallery {
  display: grid;
  grid-template-columns: 50% 25% 25%;
  grid-template-rows: 1fr 1fr;
}

#demo-test-gallery .galleryImg {
  height: 170px;
  width: 100%;
  background-color: #000;
  border-radius: 10px;
  position: relative;
}

#demo-test-gallery .galleryImg:first-of-type {
  grid-row: 1/3;
  grid-column: 1/2;
  height: 340px;
}

#demo-test-gallery .galleryImg:before {
  transition: all 0.25s ease;
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  width: calc(100% - 14px);
  height: calc(100% - 14px);
  background-color: #000;
  opacity: 0;
  visibility: hidden;
}

#demo-test-gallery .galleryImg:hover:before {
  visibility: visible;
  opacity: 0.18;
}

#demo-test-gallery .galleryImg figure {
  width: 100%;
  height: 100%;
  padding: 7px;
  background-color: #fff;
}

#demo-test-gallery .galleryImg img {
  border-radius: 10px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.property-inner .container-sm {
  padding: 0;
}

.property-inner .price_map .col {
  width: 50%;
}

.property-inner .price_map .col a {
  margin-top: 5px;
  display: flex;
  align-items: center;
  width: 20%;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #ff8c00;
}

.property-inner .price_map .col a:hover {
  color: #006994;
  text-decoration: underline;
}

.property-inner .price_map .col a:hover svg path {
  fill: #006994;
}

.property-inner .price_map .col a svg path,
.property-inner .price_map .col:nth-of-type(2) a:hover svg path {
  fill: #ff8c00;
}

.property-inner .price_map .col a svg {
  width: 11px;
  height: 14px;
  margin-right: 11px;
}

.property-inner .price_map .col:nth-of-type(2) a {
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: #f4f4f4;
  margin-left: auto;
  border-radius: 50%;
}

.property-inner .price_map .col:nth-of-type(2) a svg {
  width: 20px;
  height: 20px;
  margin-right: 0;
}

.property-inner .price_map .col:nth-of-type(2) a svg path {
  fill: #000;
}

.property-inner .price_map h3 {
  font-size: 26px;
  font-weight: 600;
  font-family: Lato, sans-serif;
  color: #333;
}

.property-inner .price_map p {
  color: #333;
  font-family: Poppins, Sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.property-inner .wrap {
  padding: 20px 0 50px;
}

.property-inner .wrap .col:first-of-type {
  width: 65%;
  padding-right: 10px;
}

.property-inner .wrap .col:nth-of-type(2) {
  width: 35%;
  padding: 10px;
}

.property-inner .highlight {
  column-count: 2;
}

.property-inner .highlight li {
  margin-bottom: 20px;
  align-items: center;
}

.property-inner .highlight .icon {
  border: 2px solid #006994;
  border-radius: 50%;
  width: 46px;
  height: 46px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.property-inner .highlight .icon svg path {
  fill: #006994;
}

.property-inner .highlight h4 {
  font-weight: 700;
  margin: 5px 0 3px;
  font-size: 16px;
  line-height: 1em;
  font-family: Lato, sans-serif;
  color: #333;
}

.property-inner .highlight p,
.property-inner p {
  color: #4d5471;
  font-family: Lato, sans-serif;
  font-weight: 400;
}

.property-inner .highlight p {
  font-size: 14px;
  line-height: 26px;
  margin: 0;
}

.property-inner p {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.7em;
  letter-spacing: 0.1px;
}

.property-inner p:last-of-type {
  margin-bottom: 25px;
}

.property-inner .features-row {
  margin-bottom: 20px;
}

.property-inner .features-row .col_ {
  width: 50%;
  margin-bottom: 5px;
  padding: 10px;
}

.property-inner .features-row h4 {
  margin-bottom: 25px;
  font-weight: 700;
  font-family: Lato, sans-serif;
  color: #333;
  font-size: 18px;
  line-height: 1.1em;
}

.property-inner #location p,
.property-inner .features-row h5 {
  font-family: Lato, sans-serif;
  color: #4d5471;
}

.property-inner .features-row li {
  margin-bottom: 11px;
}

.property-inner .features-row .icon {
  width: 32px;
  height: 32px;
  border-radius: 18px;
  background-color: #006994;
  display: flex;
  align-items: center;
  justify-content: center;
}

.property-inner .features-row .icon svg path {
  fill: #fff;
}

.property-inner .features-row h5 {
  padding-left: 13px;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
}

.agent-profile li,
.property-inner #location p {
  line-height: 1.7em;
  letter-spacing: 0.1px;
  font-weight: 400;
}

.property-inner #location p {
  margin-top: 20px;
  font-size: 16px;
}

.agent-profile {
  border-radius: 5px;
  border: 1px solid #dfdfe1;
  padding: 30px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.18);
}

.agent-profile h3 {
  color: #303030;
  font-family: Effra-Regular;
  font-weight: 400;
  margin: 20px 0 10px;
}

.agent-profile .user-name p,
.agent-profile h4 {
  color: #303030;
  font-weight: 700;
  font-family: Lato, sans-serif;
  font-size: 18px;
}

.agent-profile h4 {
  margin: 10px 0;
}

.agent-profile #user-details {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.agent-profile .user-name {
  flex-basis: calc(100% - 80px);
  padding-right: 20px;
}

.agent-profile .user-name p {
  margin: 0;
}

.agent-profile .user-img {
  flex-basis: 80px;
}

.agent-profile ul {
  margin-bottom: 20px;
}

.agent-profile li {
  margin-bottom: 12px;
  font-size: 14px;
  color: #4d5471;
  font-family: Lato, sans-serif;
  display: flex;
  align-items: center;
}

.agent-profile li a {
  color: #ff8c00;
  display: flex;
  align-items: center;
}

.agent-profile li svg {
  margin-right: 8px;
}

.agent-profile li svg path {
  fill: #4d5471;
}

.agent-profile li a:hover {
  text-decoration: underline;
}

.agent-profile li a svg path {
  fill: #ff8c00;
}

.agent-profile .btn_custom {
  border: 2px solid #006994;
  border-radius: 3px;
  padding: 11px 24px 13px;
  width: 100%;
  margin-bottom: 20px;
  background-color: #fff;
  transition: all 0.25s ease;
}

.agent-profile .btn_custom:nth-of-type(2),
.author-listing .related-properties li,
.call_popup .form_group button {
  margin-bottom: 30px;
}

.agent-profile .btn_custom:nth-of-type(3) {
  padding: 9px 24px 16px;
}

.agent-profile .btn_custom svg path {
  fill: #006994;
  transition: all 0.25s ease;
}

.agent-profile .btn_custom:hover svg path,
.call_popup .closeBtn svg path,
.choose-listing .col_ .icon svg path,
.related-properties .related_pop_slider .slick-arrow svg path,
.related-properties li .content svg path,
.user-profile-cover .myBtn svg path {
  fill: #fff;
}

.agent-profile .btn_custom span {
  color: #006994;
  font-size: 15px;
  line-height: 1em;
  font-weight: 400;
  font-family: Lato, sans-serif;
  width: calc(100% - 20px);
  text-align: center;
  transition: all 0.25s ease;
}

.agent-profile .btn_custom:hover {
  background-color: #ff8c00;
  border: 2px solid #ff8c00;
}

.agent-profile .btn_custom:hover span {
  color: #fff;
}

.related-properties {
  padding: 20px 0 70px;
}

.related-properties .container-sm {
  padding: 0;
}

.related-properties .heading-line h3 {
  margin-bottom: 20px;
}

.related-properties li {
  width: calc(33.33% - 20px);
  margin-right: 30px;
  position: relative;
}

.related-properties li:last-of-type {
  margin-right: 0;
}

.related-properties .related_pop_slider {
  width: 100%;
  height: 230px;
  border-radius: 5px;
}

.related-properties .related_pop_slider .slick-arrow {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  z-index: 5;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border: none;
  background: 0 0;
  border-radius: 50%;
  font-size: 0;
  opacity: 0;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.related-properties a,
.related-properties a:before,
.related-properties figure,
.related-properties img {
  border-radius: 5px;
  width: 100%;
}

.related-properties .related_pop_slider .slick-arrow.slick-next {
  right: 15px;
  left: auto;
}

.related-properties .related_pop_slider .slick-arrow.slick-next svg {
  transform: rotate(180deg);
}

.related-properties .related_pop_slider .slick-arrow:hover {
  background-color: #ff8c00;
}

.related-properties .slick-list,
.related-properties .slick-slide,
.related-properties .slick-track,
.related-properties a,
.related-properties figure,
.related-properties img {
  height: 100%;
}

.related-properties img {
  object-fit: cover;
  object-position: center;
}

.related-properties a {
  position: relative;
}

.related-properties a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #242429;
  opacity: 0.2;
  visibility: visible;
  transition: all 0.25s ease;
  z-index: 1;
}

.related-properties li:hover a:before {
  opacity: 0.5;
  visibility: visible;
}

.related-properties li:hover .slick-arrow {
  opacity: 1;
  visibility: visible;
}

.related-properties li .content {
  position: absolute;
  left: 0;
  bottom: 20px;
  width: 100%;
  height: auto;
}

.related-properties li .content a {
  text-align: center;
}

.related-properties li .content a:before {
  display: none;
}

.related-properties li .content h5,
.related-properties li .content p {
  color: #fff;
}

.related-properties li .content h5 {
  font-size: 16px;
  font-weight: 400;
  font-family: Lato, sans-serif;
  line-height: 1.25em;
  margin-bottom: 2px;
}

.contact-agent .form_group input,
.contact-agent .form_group label,
.contact-agent .form_group textarea {
  color: #242429;
  line-height: 1.25em;
  font-weight: 700;
  font-family: Lato, sans-serif;
  width: 100%;
}

.related-properties li .content p {
  font-size: 16px;
  line-height: 1.25em;
}

.contact-agent {
  background-color: #f4f4f4;
  padding: 30px 0;
}

.contact-agent .container-sm {
  padding: 0;
}

.contact-agent .form_group {
  width: 100%;
  margin-bottom: 16px;
}

.contact-agent .form_group label {
  display: block;
  font-size: 15px;
}

.contact-agent .form_group input,
.contact-agent .form_group textarea {
  outline: 0;
  box-shadow: none;
  border: none;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  padding: 14px 0;
  border-radius: 0;
  font-size: 14px;
  background: 0 0;
}

.contact-agent .form_group input:focus,
.contact-agent .form_group textarea:focus {
  border-bottom: solid 2px #ff8c00;
}

.contact-agent .form_group textarea {
  height: 200px;
}

.contact-agent .form_group button {
  color: #fff;
  margin-bottom: 15px;
  font-size: 13px;
  font-weight: 400;
  padding: 16px 40px 14px;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  border-radius: 2px;
  line-height: 16px;
  border: none;
  background-color: #ff8c00;
  cursor: pointer;
  font-family: Effra-Medium;
}

.agentContact,
.call_popup {
  overflow-y: scroll;
  opacity: 0;
  visibility: hidden;
  top: 0;
}

.call_popup .head,
.user-profile-cover h1 {
  font-family: Effra-Regular;
}

.contact-agent .form_group button:hover {
  background-color: #006994;
}

.call_popup {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
}

.call_popup.active {
  opacity: 1;
  visibility: visible;
  z-index: 999;
}

.call_popup .closeBtn {
  cursor: pointer;
  position: absolute;
  right: 50px;
  top: 50px;
}

.call_popup .wrapper {
  width: 500px;
  background-color: #fff;
  margin: 20px auto;
}

.call_popup .head {
  padding: 15px 25px;
  background-color: #303030;
  color: #fff;
  font-weight: 400;
  font-size: 24px;
}

.call_popup .form_group input,
.call_popup .form_group label {
  width: 100%;
  color: #242429;
  font-weight: 700;
  font-family: Lato, sans-serif;
  line-height: 1.25em;
}

.call_popup .content {
  padding: 25px;
  background-color: #fff;
}

.call_popup .form_group {
  width: 100%;
  margin-bottom: 16px;
}

.call_popup .form_group label {
  display: block;
  font-size: 15px;
}

.call_popup .form_group input {
  outline: 0;
  box-shadow: none;
  border: none;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  padding: 14px 0;
  border-radius: 0;
  font-size: 14px;
  background: 0 0;
}

.call_popup .form_group input:focus {
  border-bottom: solid 2px #ff8c00;
}

.call_popup .form_group button {
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  padding: 16px 40px 14px;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  border-radius: 2px;
  line-height: 16px;
  border: none;
  background-color: #ff8c00;
  cursor: pointer;
  font-family: Effra-Medium;
}

.request-view h3,
.request-view p {
  color: #242429;
  margin: 1em 0;
  font-family: Lato, sans-serif;
}

.call_popup .form_group button:hover {
  background-color: #006994;
}

.request-view {
  background-color: #f4f4f4;
  padding: 60px 0;
}

.request-view .container-sm {
  max-width: 1170px;
  padding: 0;
}

.request-view .wrapper {
  background: #fff;
  border-radius: 15px;
  padding: 10px 20px 30px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
}

.request-view .col {
  width: 50%;
}

.request-view .col:first-of-type {
  padding-right: 10px;
}

.request-view .col:nth-of-type(2) {
  padding-left: 10px;
}

.request-view h3 {
  font-weight: 700;
  line-height: 1.25;
}

.request-view p {
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
}

.request-view input {
  width: 100%;
  border: 1px solid #ddd;
  box-shadow: none;
  border-radius: 4px;
  font-size: 15px;
  color: #333;
  padding: 11px 12px;
  background-color: #fff;
  line-height: 1.42857143;
  outline: 0;
  font-family: Lato, sans-serif;
}

.request-view .input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0 4px 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.request-view .input-date {
  display: flex;
}

.request-view .input-date input {
  border-radius: 4px 0 0 4px;
}

.request-view .form_group {
  margin-bottom: 15px;
}

.request-view label {
  font-weight: 600;
  margin-bottom: 5px;
  color: #242429;
  font-size: 14px;
  width: 100%;
  display: block;
  font-family: Lato, sans-serif;
}

.request-view button {
  color: #fff;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 400;
  padding: 18px 40px 14px;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  border-radius: 2px;
  line-height: 16px;
  border: none;
  background-color: #ff8c00;
  cursor: pointer;
  font-family: Effra-Medium;
  width: 100%;
  display: none;
}

.request-view button:hover {
  background-color: #006994;
}

.request-view .listingaddress {
  font-family: Lato, sans-serif;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #242429;
  display: flex;
  align-items: center;
}

.request-view .pricesection .price,
.request-view .pricesection span {
  font-size: 17px;
  font-family: Lato, sans-serif;
  font-weight: 400;
}

.request-view .listingaddress svg path {
  fill: #242429;
}

.request-view .pricesection {
  padding: 10px 0;
}

.request-view .pricesection span {
  padding-right: 10px;
  margin-right: 10px;
  line-height: 1.25em;
  color: #666;
  border-right: 1px solid #ccc;
}

.request-view .pricesection .price {
  color: #333;
  margin-right: 20px;
  line-height: 1.25em;
  padding-right: 0;
  border-right: none;
}

.request-view .pricesection .bathrooms_size {
  border-right: none;
}

.request-view .pricesection img {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  vertical-align: middle;
}

.request-view-slider {
  width: 100%;
  height: 333px;
  border-radius: 15px;
}

.request-view-slider .slick-arrow {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  z-index: 5;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: none;
  background: #fff;
  opacity: 0.75;
  border-radius: 50%;
  font-size: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.request-view-slider .slick-arrow.slick-next {
  right: 12px;
  left: auto;
}

.request-view-slider .slick-arrow.slick-next svg {
  transform: rotate(180deg);
}

.request-view-slider .slick-arrow:hover {
  background: #fff;
  opacity: 0.75;
}

.request-view-slider .slick-list,
.request-view-slider .slick-slide,
.request-view-slider .slick-track,
.request-view-slider figure,
.request-view-slider img {
  height: 100%;
}

.request-view-slider figure,
.request-view-slider img {
  width: 100%;
  border-radius: 15px;
}

.request-view-slider img {
  object-fit: cover;
  aspect-ratio: 5/3;
}

.request-confirmation p {
  width: 100%;
  padding: 10px 15px;
  border-radius: 10px;
  margin: 0 0 10px;
  color: #242429;
  font-size: 14px;
  line-height: 26px;
  font-family: Lato, sans-serif;
  font-weight: 400;
}

.request-confirmation p:first-of-type {
  font-size: 18px;
}

.request-confirmation p:not(:first-of-type) strong {
  width: 106px;
  display: inline-block;
  text-align: left;
}

.user-profile-cover {
  background: #fff;
  border-bottom: 1px solid #ebebeb;
}

.user-profile-cover .listing-main-info {
  position: static;
  -webkit-transform: none;
  transform: none;
  background: #fff;
  padding: 25px 25px 0;
}

.user-profile-cover .container {
  max-width: 100%;
}

@media (min-width: 1450px) {
  .user-profile-cover .container {
    max-width: 1140px;
  }
}

.user-profile-cover .col-md-6 {
  width: 50%;
  padding: 0 10px;
}

.user-profile-cover .profile-name {
  min-height: 80px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding-left: 90px;
  position: relative;
}

.user-profile-cover h1 {
  color: #242429;
  font-size: 34px;
  font-weight: 400;
}

.user-profile-cover .lmb-label,
.user-profile-cover .myBtn,
.user-profile-cover .value {
  font-family: Lato, sans-serif;
}

.user-profile-cover ul {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  flex-wrap: nowrap;
  padding-left: 40px;
  margin-top: 1em;
}

.user-profile-cover ul li {
  margin-left: 35px;
}

.user-profile-cover .lmb-label {
  font-size: 14px;
  opacity: 0.7;
  white-space: nowrap;
  color: #242429;
  font-weight: 400;
}

.user-profile-cover .value {
  font-size: 20px;
  font-weight: 600;
  color: #242429;
}

.user-profile-cover .myBtn {
  background-color: #ff8c00;
  padding: 15px 17px;
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
  transition: all 0.25s ease;
}

.user-profile-cover .myBtn svg {
  margin-right: 7px;
}

.user-profile-cover .myBtn:hover {
  background-color: #006994;
}

.user-profile-cover .heading_center {
  margin-top: 25px;
  width: 100%;
  text-align: center;
}

.user-profile-cover .heading_center p {
  position: relative;
  color: #242429;
  font-weight: 600;
  font-family: Effra-Bold;
  font-size: 14px;
  line-height: 1em;
  padding-bottom: 7px;
}

.user-profile-cover .heading_center p:before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -20px;
  transform: translateX(-50%);
  width: 170px;
  height: 2px;
  /* background-color: #ff8c00; */
}

.author-listing {
  background-color: #f4f4f4;
  padding: 40px 0 50px;
}

.author-listing .container-sm {
  max-width: 1160px;
  padding: 0;
}

.author-listing .related-properties {
  padding: 20px 0;
}

.author-listing .related-properties li:nth-of-type(4n) {
  margin-right: 0;
}

.agentContact {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
}

.agentContact.active {
  opacity: 1;
  visibility: visible;
  z-index: 999;
}

.agentContact .wrapper {
  width: 600px;
  background-color: #fefefe;
  margin: 50px auto;
  border: 1px solid #888;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.14);
  -webkit-box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
}

.agentContact .head {
  padding: 20px 30px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
}

.agentContact h2 {
  font-size: 20px;
  line-height: 1.25em;
  color: #242429;
  font-family: Lato, sans-serif;
}

.agentContact .close {
  color: #000;
  opacity: 0.2;
  text-shadow: 0 1px 0 #fff;
  line-height: 1;
  font-size: 28px;
  font-weight: 700;
  margin-top: -5px;
  margin-right: -10px;
  cursor: pointer;
}

.agentContact .close:hover {
  opacity: 1;
}

.agentContact .content {
  padding: 15px;
}

.agentContact form {
  display: flex;
  flex-wrap: wrap;
}

.agentContact .form_group {
  flex-basis: 50%;
  padding: 10px;
}

.agentContact .form_group.full {
  flex-basis: 100%;
}

.agentContact input,
.agentContact textarea {
  width: 100%;
  padding: 13px 16px;
  border: 1px solid #818a91;
  border-radius: 4px;
  margin-bottom: 5px;
  font-size: 16px;
  color: #222;
  outline: 0;
  font-family: Lato, sans-serif;
  line-height: 1.25em;
  font-weight: 400;
}

.agentContact textarea {
  height: 195px;
}

.agentContact button {
  color: #fff;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 400;
  padding: 16px 40px 14px;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  border-radius: 2px;
  line-height: 16px;
  border: none;
  background-color: #ff8c00;
  cursor: pointer;
  font-family: Effra-Medium;
}

.choose-listing .col_ p,
.choose-listing h3 {
  line-height: 1;
  text-align: center;
  font-weight: 400;
}

.agentContact button:hover {
  background-color: #006994;
}

.add_listing {
  background-color: #f4f4f4;
  padding: 60px 0;
}

.choose-listing {
  height: 70vh;
}

.choose-listing h3 {
  width: 100%;
  color: #000;
  font-family: Effra-Regular;
  margin-bottom: 60px;
}

.choose-listing .col_ h6,
.choose-listing .col_ p,
.multisteps .input-wrap label,
.multisteps .tablist .number,
.multisteps .tablist li {
  font-family: Lato, sans-serif;
}

.choose-listing .col_ {
  margin: 0 10px 20px;
  height: 120px;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: all 1s linear;
  transition: all 1s linear;
  position: relative;
  width: calc(50% - 20px);
}

.choose-listing .col_ .face {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: 0.35s;
  transition: 0.35s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.choose-listing .col_ .front {
  background: #fff;
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
  border-radius: 5px;
  border: 1px solid #ebebeb;
  z-index: 2;
}

.choose-listing .col_ .back {
  box-sizing: border-box;
  background-color: #ff8c00;
  -webkit-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
  border-radius: 5px;
  color: #fff;
  text-align: center;
  z-index: 1;
}

.choose-listing .col_:hover .front {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
  z-index: 1;
}

.choose-listing .col_:hover .back {
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
  z-index: 2;
}

.choose-listing .col_ .icon {
  width: 40px;
  height: 40px;
  background-color: #ff8c00;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.choose-listing .col_ p {
  color: #242429;
  font-size: 14px;
  margin-top: 10px;
}

.choose-listing .col_ h6 {
  color: #fff;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1em;
  font-weight: 400;
}

.multisteps .multistep-form {
  display: none;
  padding: 60px;
  background-color: #fff;
}

.multisteps h2 {
  color: #303030;
  font-size: 36px;
  line-height: 1.2em;
  font-family: Effra-Regular;
  font-weight: 400;
  margin-bottom: 40px;
}

.multisteps .steps {
  display: block !important;
}

.multisteps .tablist {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.multisteps .tablist:before {
  content: "";
  position: absolute;
  height: 1px;
  background: #ededed;
  width: 100%;
  top: 40px;
  right: 0;
  left: 0;
  bottom: auto;
}

.multisteps .tablist li {
  width: auto;
  max-width: unset;
  position: relative;
  z-index: 2;
  background: 0 0;
  text-align: center;
  color: #303030;
  font-size: 16px;
  line-height: 1.4;
  flex-flow: column;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  cursor: pointer;
}

.multisteps .tablist li.active .number {
  background-color: #ff8c00;
  color: #fff;
}

.multisteps .tablist .number {
  height: 66px;
  width: 66px;
  display: inline-block;
  margin: 10px 10px 16px;
  padding: 0;
  background: #f4f4f4;
  text-align: center;
  border-radius: 100%;
  color: #c7c7c7;
  font-size: 24px;
  line-height: 60px;
  font-weight: 400;
  border: 3px solid #fff;
  position: relative;
}

.multisteps .tablist .number:before {
  content: "";
  position: absolute;
  left: -5px;
  right: -5px;
  top: -5px;
  bottom: -5px;
  z-index: 1;
  border-radius: 100%;
  border: 2px solid #f4f4f4;
  background: 0 0;
}

.multisteps .tablist .number:after {
  position: absolute;
  left: -20%;
  width: 140%;
  height: 100%;
  background: #fff;
  content: "";
  z-index: -1;
}

.multisteps .formWrappers {
  display: none;
  padding-top: 45px;
}

.multisteps .input-wrap label {
  margin-bottom: 15px;
  font-size: 16px;
  color: #303030;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  width: 100%;
}

.multisteps .input-wrap label span {
  font-size: 13px;
  opacity: 0.8;
  font-weight: 400;
}

.multisteps .input-wrap .form_group {
  width: 100%;
  margin-bottom: 20px;
}

.multisteps .input-wrap .form_group.half {
  width: 50%;
  padding-right: 20px;
}

.multisteps .input-wrap .form_group.pdrt0 {
  padding-right: 0;
}

.multisteps .input-wrap input,
.multisteps .input-wrap textarea {
  width: 100%;
  outline: 0;
  border: 1px solid #efefef;
  background-color: #fff;
  border-radius: 5px;
  color: #8e8e91;
  font-size: 16px;
  padding: 13px 15px;
  line-height: 1.25em;
  font-family: Lato, sans-serif;
}

.multisteps .input-wrap span,
.multisteps .optional label {
  font-size: 13px;
  opacity: 0.8;
  color: #242429;
  font-family: Lato, sans-serif;
}

.multisteps .input-wrap textarea {
  height: 120px;
}

.multisteps .optional {
  margin-bottom: 40px;
}

.multisteps .optional label {
  margin-bottom: 0;
  font-weight: 600;
  line-height: 1em;
}

.multisteps .optional .field {
  display: flex;
  flex-wrap: wrap;
}

.multisteps .optional .field .md-checkbox {
  margin: 0 25px 0 0;
  color: #303030;
  font-size: 15px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
}

.multisteps .optional .field .md-checkbox input {
  width: 20px;
  margin: 0 10px 0 0;
  height: 20px;
  border: 2px solid #ccc !important;
}

.multisteps .button-wrapper {
  display: flex !important;
  justify-content: space-between;
  margin-top: 10px;
}

.multisteps .button-wrapper button {
  position: relative;
  cursor: pointer;
  padding: 14px 15px;
  border-radius: 5px;
  line-height: 1.25em;
  font-size: 16px;
  font-weight: 600;
  font-family: Lato, sans-serif;
  border: none;
  overflow: hidden;
  z-index: 0;
}

.multisteps .button-wrapper button:after {
  position: absolute;
  content: "";
  width: 100%;
  visibility: hidden;
  opacity: 0;
  left: -100%;
  top: 0;
  height: 100%;
  -webkit-transition: linear 0.25s;
  -o-transition: linear 0.25s;
  transition: linear 0.25s;
  background: #333;
  z-index: -1;
}

.multisteps .button-wrapper button:hover:after {
  left: 0;
  visibility: visible;
  opacity: 1;
}

.multisteps .button-wrapper .prev {
  background: #eee;
  color: #aaa;
}

.multisteps .button-wrapper .save {
  background: #ff8c00;
  color: #fff;
}

.multisteps .upload__btn {
  width: 100%;
  height: 100%;
  border: 2px dashed #ddd;
  background-color: #fff;
  border-radius: 0;
}

.multisteps .upload__box {
  padding: 0;
  width: 100%;
  position: relative;
  display: flex;
}

.multisteps .upload__box input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.multisteps .upload__btn-box {
  width: 280px;
  height: 120px;
}

.multisteps .upload__btn-box p {
  font-size: 13px;
  opacity: 0.8;
  font-weight: 400;
  font-family: Lato, sans-serif;
  line-height: 1.2em;
  color: #242429;
}

.multisteps label.upload__btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.multisteps .upload__img-wrap {
  width: calc(100% - 248px);
}

.multisteps .input-wrap input::placeholder,
.multisteps .input-wrap textarea::placeholder {
  color: #8e8e91;
  opacity: 1;
}

.multisteps .input-wrap input:-ms-input-placeholder,
.multisteps .input-wrap textarea:-ms-input-placeholder {
  color: #8e8e91;
}

.multisteps .input-wrap input::-ms-input-placeholder,
.multisteps .input-wrap textarea::-ms-input-placeholder {
  color: #8e8e91;
}

div#stepsFours {
  margin-bottom: 40px;
}