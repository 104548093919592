.dropbtn {
    background-color: transparent !important;
    color: #4d5471;
    padding: 14px;
    font-size: 17px;
    font-weight: 700;
    border: none;
    display: flex;
    align-items: center;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}


/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    z-index: 12345432;
    position: absolute;
    right: 2rem;
    background-color: #f1f1f1;
    width: 200px !important;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-top: 2px solid #ff8c00;
    padding: 12px;
}

/* Links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 11px 15px;
    text-decoration: none;
    display: block;
    display: flex;
    font-size: 15px;
    align-items: center;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #ddd;
    border-left: 3px solid #ff8c00 !important;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
    z-index: 11111;
}

@media only screen and (max-width : 700px) {
    .dropbtn {
        font-size: 10px;
    }
}